import React, { useEffect, useRef, useState } from "react";
import CategoryNavBar from "./CategoryNavBar";
import SideBar from "./SideBar";
import axios from "axios";
import LoginModal from "../Items/LoginModal";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const baseUrl = process.env.REACT_APP_BASE_URL;

interface Item {
  batchId: any;
  itemId: any;
  id: any;
  sellingPrice: number;
  quantity: number;
  itemName: string | undefined;
  imgURL: string | undefined;
  companyId: number;
  companyName: string;
}

export default function CartView() {
  const [items, setItems] = useState<Item[]>([]);
  const [filteredItems, setFilteredItems] = useState<Item[]>([]);
  const [selectedItems, setSelectedItems] = useState<boolean[]>([]);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isSidebarVisible, setSidebarVisible] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [cartId, setCartId] = useState<number>(0);
  const { companyId } = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentCompanyId, setCurrentCompanyId] = useState<number>(0);
  const [active, setActive] = useState<{ [key: number]: boolean }>({ 0: true });
  const [uniqueItems, setUniqueItems] = useState<Item[]>([]);
  const [listHeight, setListHeight] = useState("auto");
  const listRef = useRef<HTMLUListElement>(null);
  const navigate = useNavigate();

  // Get auth and token
  const setStoreUser = (): { userId: string | null; auth: string | null } => {
    const storeUser = sessionStorage.getItem("user");
    if (storeUser) {
      const user = JSON.parse(storeUser);
      const userId = user.id;
      const auth = user.token;
      return { userId, auth };
    }
    return { userId: null, auth: null };
  };

  const { userId, auth } = setStoreUser();

  const handleCloseModal = () => {
    setShowModal(false);
  };

  // Save changes to the cart
  const setChanges = () => {
    let newItems = items.map((item) => ({
      id: item.id,
      quantity: item.quantity,
      itemId: item.itemId,
      batchId: item.batchId,
    }));

    if (!auth || !userId) {
      setShowModal(true);
    } else {
      setShowModal(false);
      const payload = { id: cartId, items: newItems };
      axios
        .post(`${baseUrl}/cart/${userId}`, payload, {
          headers: {
            Authorization: `Bearer ${auth}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log("Items saved successfully:", response);
        })
        .catch((error) => {
          console.error("Error saving cart data:", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  // Filter unique companies for the list
  useEffect(() => {
    const uniqueArray = items.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.companyId === item.companyId)
    );
    setUniqueItems(uniqueArray);
  }, [items]);

  // Adjust the height of the list based on the number of brands
  useEffect(() => {
    if (listRef.current) {
      const height = listRef.current.scrollHeight;
      setListHeight(height > 420 ? "420px" : `${height}px`);
    }
  }, [uniqueItems]);

  // Fetch cart items from the server
  useEffect(() => {
    setIsLoading(true);
    if (!auth || !userId) {
      setShowModal(true);
      setIsLoading(false);
      return;
    }
    setShowModal(false);
    axios
      .get(`${baseUrl}/cart/${userId}`, {
        headers: {
          Authorization: `Bearer ${auth}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200 && response.data.data) {
          const fetchedItems = response.data.data.cart || [];
          setItems(fetchedItems);
          setCartId(response.data.data.cartId);
          setSelectedItems(fetchedItems.map(() => false));
        } else {
          console.error("Unexpected response status:", response.status);
        }
      })
      .catch((error) => {
        console.error("Error fetching cart data:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  // Save changes when items update
  useEffect(() => {
    setChanges();
    filterItemsByCompanyId(currentCompanyId);
  }, [items]);

  // Handle quantity changes
  const handleQuantityChange = (id: number, change: number) => {
    setItems(
      items.map((item) => {
        if (item.id === id) {
          return { ...item, quantity: item.quantity + change };
        }
        return item;
      })
    );
  };

  // Handle item deletion
  const handleDelete = (id: number) => {
    const updatedItems = items.filter((item) => item.id !== id);
    setItems(updatedItems);
  };

  // Handle checkbox changes for items
  const handleCheckboxChangeItem = (index: number) => {
    setSelectedItems((prevSelectedItems) => {
      const newSelectedItems = [...prevSelectedItems];
      newSelectedItems[index] = !newSelectedItems[index];
      return newSelectedItems;
    });
  };

  // Calculate subtotal for selected items
  const calculateSubtotal = () => {
    return filteredItems.reduce((total, item, index) => {
      if (selectedItems[index]) {
        return total + item.sellingPrice * item.quantity;
      }
      return total;
    }, 0);
  };

  // Toggle dropdown visibility
  const handleDropdownToggle = () => {
    setDropdownOpen(!isDropdownOpen);
    setSidebarVisible(!isSidebarVisible);
  };

  // Handle checkbox changes for companies
  const handleCheckboxChange = (index: number, companyId: number) => {
    if (!active[index]) {
      setActive({ [index]: true });
      setCurrentCompanyId(companyId);
      filterItemsByCompanyId(companyId);
    } else {
      setActive({ 0: true });
      filterItemsByCompanyId(0);
    }
  };

  // Filter items by company ID
  const filterItemsByCompanyId = (companyId: number) => {
    if (companyId > 0) {
      setFilteredItems(items.filter((item) => item.companyId === companyId));
      setSelectedItems(
        items.filter((item) => item.companyId === companyId).map(() => false)
      );
    } else {
      setFilteredItems(items);
      setSelectedItems(items.map(() => false));
    }
  };

  // Proceed to checkout
  const proceedToCheckOut = () => {
    if (!auth || !userId) {
      setShowModal(true);
    } else {
      setShowModal(false);
      const selectedCartItems = filteredItems.filter((_, index) => selectedItems[index]);
      navigate(`/shoppingCartItem/${companyId}/checkout`, { state: { selectedCartItems } });
      window.scrollTo(0, 0);

    }
  };

  if (isLoading) {
    return (
      <div>
        {/* <CategoryNavBar isDropdownOpen={isDropdownOpen} handleDropdownToggle={handleDropdownToggle} /> */}
        <div className="h-screen flex items-center justify-center">
          <div className="border border-blue-300 shadow rounded-md p-4 max-w-sm w-full mx-auto">
            <div className="animate-pulse flex space-x-4">
              <div className="rounded-full bg-slate-700 h-10 w-10"></div>
              <div className="flex-1 space-y-6 py-1">
                <div className="h-2 bg-slate-700 rounded"></div>
                <div className="space-y-3">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-700 rounded col-span-2"></div>
                    <div className="h-2 bg-slate-700 rounded col-span-1"></div>
                  </div>
                  <div className="h-2 bg-slate-700 rounded"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {/* <Helmet>
        <title>Pet Medi Web - Cart View</title>
        <meta
          name="description"
          content="Learn more about Pet Medi, your trusted pet health partner."
        />
        <meta
          name="keywords"
          content="pet, health, care, dogs, cats, veterinary,Pet clinic in Sri Lanka,Veterinary services Sri Lanka,Pet hospital,Affordable pet care Sri Lanka, 
      Veterinarian near me,Animal clinic Sri Lanka,Pet surgery ,Pet vaccinations Sri Lanka,24/7 emergency vet Sri Lanka, Pet health checkup Sri Lanka, Dog clinic Sri Lanka, 
      Cat veterinary care Sri Lanka, Exotic pet care Sri Lanka, Pet dental care Sri Lanka, Pet grooming, Pet boarding services Sri Lanka, Best vet for pet Sri Lanka, 
      Cataract surgery for pets Sri Lanka, Animal rescue services Sri Lanka ,Veterinary clinic , Sri Lanka , Pet Medi, pet, P , Sri Lanka Pets, Pet Care, Animal Clinic, 
      Vet, Pet Health, Pet Health Checkup, veterinary,Pet dental care, Grooming, Best Animal Clinic, Pet health monitoring app, Veterinary telemedicine Sri Lanka,Pet prescription management,
      Pet weight management Sri Lanka,Pet nutrition advice Sri Lanka,Digital veterinary solutions,Pet clinic appointment system,Senior pet care services,Specialized pet care Sri Lanka,
      Online vet consultation Sri Lanka,Pet illness prevention,Remote vet consultations,Pet healthcare software,Preventive vet care Sri Lanka,Pet emergency hotline Sri Lanka,
      Advanced pet diagnostics,Pet care plans Sri Lanka,In-home veterinary services,Pet surgery aftercare Sri Lanka,Pet grooming and spa Sri Lanka,Veterinary lab testing Sri Lanka,
      Personalized pet healthcare plans,Vet treatment packages Sri Lanka,Animal health solutions Sri Lanka,Vet treatment plans Sri Lanka,Pet physiotherapy Sri Lanka, 
      Vets near me, cat clinic near me, dog clinic near me, rabbit consultant doctors, dog consultant doctors, cat consultant doctors, online pet app, pet app online, 
      app for pets, app for animals, animal doctor, pet doctor, pet consultant, Pet wellness programs Sri Lanka, Pet wellness programs, Veterinary nutrition consultation, 
      Pet behavior training Sri Lanka, Pet behavior training, Veterinary dermatology Sri Lanka, Veterinary dermatology, Puppy vaccinations, Puppy vaccinations Sri Lanka, 
      Kitten vaccinations Sri Lanka, Kitten vaccinations, Flea and tick prevention Sri Lanka, Flea and tick prevention, Microchipping services Sri Lanka, Pet allergy treatment Sri Lanka, Pet allergy treatment,
      Veterinary orthopedic surgery Sri Lanka,Veterinary orthopedic surgery, 24-hour vet service Sri Lanka, 24-hour vet service , Emergency pet care Sri Lanka, Emergency pet care,
      Animal eye care Sri Lanka,Animal eye care,Pet dental cleaning Sri Lanka,Pet dental cleaning,Dog vaccinations Sri Lanka, Dog vaccinations,Cat vaccinations Sri Lanka,Cat vaccinations,Animal diagnostic imaging,
      Vet appointment booking online Sri Lanka,Vet appointment booking online,Pet Surgery Sri Lanka, Pet Surgery,Specialist vet services Sri Lanka,Specialist vet services,Pet cardiologist Sri Lanka, Pet cardiologist,
      Best dog vet Sri Lanka,Best dog vet, Best cat vet Sri Lanka,Best cat vet,Pet health checkup Sri Lanka, Pet health checkup, Puppy health checkup Sri Lanka,Puppy health checkup, Kitten health checkup Sri Lanka,Kitten health checkup,
      Emergency pet clinic near me,Pet health and wellness Sri Lanka,Pet health and wellness , pet treatment,Pet Health, Pet Services,Pet Services near me,Pet health diagnosis,Veterinary diagnosis,Common pet diseases,Animal health conditions,
      Dog illness diagnosis,Cat health problem, Dog health problem, Pet health problem, Pet disease symptoms, Veterinary medical conditions, Signs of illness in pets, Pet diagnostic tests,
      Dog allergy diagnosis, Cat urinary tract infection symptoms, Dog urinary tract infection symptoms, Pet urinary tract infection symptoms, Veterinary blood tests for pets, Pet arthritis treatment,
      Dog diabetes diagnosis, Cat diabetes diagnosis, Pet diabetes diagnosis, Pet cancer signs, Common pet skin diseases, Animal ear infections, Dog digestive problems diagnosis,
      Cat digestive problems diagnosis, Pet digestive problems diagnosis, Pet liver disease symptoms, Kidney disease in cats, Kidney disease in dogs, Kidney disease in pets,
      Pet obesity treatment, Dog heart disease symptoms, Cat heart disease symptoms, Pet heart disease symptoms, Cat thyroid disease, Dog thyroid disease, Pet thyroid disease,
      Pet parasite infections, Veterinary checkup for pets, Dog hip dysplasia diagnosis, Cat respiratory infections, Dog respiratory infections, Pet respiratory infections, Pancreatitis in pet symptoms, Dog Food, Cat Food"
    />
      </Helmet> */}

      <div>
        <CategoryNavBar
          isDropdownOpen={isDropdownOpen}
          handleDropdownToggle={handleDropdownToggle}
        />
        <div className="px-6 sm:px-12 lg:px-16 bg-gray-100 min-h-svh pt-10 pb-10">
          <div className="flex flex-col sm:flex-row gap-4 md:gap-8">
            <div className="block">
              {isSidebarVisible && <SideBar height="420px" />}
              {items.length !== 0 && (
                <div>
                  <div className="pt-6 flex justify-center">
                    <div className="overflow-hidden rounded-2xl shadow-md w-full md:w-52">
                      <ul
                        ref={listRef}
                        className="bg-white p-2 w-full overflow-y-auto scrollbar-thin scrollbar-thumb-blue-700 scrollbar-track-white items-center"
                        style={{ maxHeight: listHeight }}
                      >
                        <h2 className="text-sm text-center font-semibold mb-3">
                          Select By Company
                        </h2>
                        <hr />
                        <li className="py-1 flex items-center">
                          <button
                            type="button"
                            className="flex items-center p-2 hover:bg-blue-100 rounded-md w-full text-left"
                            onClick={() => handleCheckboxChange(0, 0)}
                          >
                            <input
                              type="checkbox"
                              checked={!!active[0]}
                              onChange={() => handleCheckboxChange(0, 0)}
                              className="mr-2"
                            />
                            <span className="ml-2 text-sm">All Companies</span>
                          </button>
                        </li>
                        {uniqueItems.map((item, index) => (
                          <li
                            key={index + 1}
                            className="py-1 flex items-center"
                          >
                            <button
                              type="button"
                              className="flex items-center p-2 hover:bg-blue-100 rounded-md w-full text-left"
                              onClick={() =>
                                handleCheckboxChange(index + 1, item.companyId)
                              }
                            >
                              <input
                                type="checkbox"
                                checked={!!active[index + 1]}
                                onChange={() =>
                                  handleCheckboxChange(
                                    index + 1,
                                    item.companyId
                                  )
                                }
                                className="mr-2"
                              />
                              <span className="ml-2 text-sm">
                                {item.companyName}
                              </span>
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="flex-1 pt-6">
              {items.length === 0 ? (
                <div className="text-center flex flex-col items-center justify-center h-96">
                  <h2 className="text-2xl font-semibold text-black">
                    Your Cart Is Empty
                  </h2>
                  <p className="text-gray-600 mt-2">
                    Add some delightful items to your cart, and watch the magic
                    happen!
                  </p>
                  <p className="text-gray-600 mt-2">
                    Feel free to explore our categories for shopping
                    inspiration.
                  </p>
                </div>
              ) : (
                <div className="flex flex-col xl:flex-row gap-8">
                  <div className="hover-box1 flex-1 overflow-y-auto max-h-100 grid grid-cols-1">
                    {filteredItems.map((item, index) => (
                      <div
                        key={index}
                        className="flex shadow-slate-300 flex-col md:flex-row items-left justify-between p-4 border-b bg-white rounded-md shadow-sm"
                      >
                        <div className="flex items-center">
                          {active[0] ? (
                            <div></div>
                          ) : (
                            <input
                              type="checkbox"
                              className="mr-2"
                              checked={selectedItems[index]}
                              onChange={() => handleCheckboxChangeItem(index)}
                            />
                          )}
                          <img
                            src={item.imgURL}
                            alt={item.itemName}
                            className="w-16 h-16 mr-4"
                          />
                          <div>
                            <h2 className="text-black font-semibold">
                              {item.itemName}
                            </h2>
                            <p className="text-blue-600">
                              {"Rs. " + item.sellingPrice.toFixed(2)}
                            </p>
                          </div>
                        </div>
                        <div className="flex items-center mt-4 md:mt-0">
                          <button
                            onClick={() => handleQuantityChange(item.id, -1)}
                            className="border border-gray-400 text-gray-600 px-2"
                          >
                            -
                          </button>
                          <span className="px-4 text-black">
                            {item.quantity}
                          </span>
                          <button
                            onClick={() => handleQuantityChange(item.id, 1)}
                            className="border border-gray-400 text-gray-600 px-2"
                          >
                            +
                          </button>
                          <button
                            className="ml-4 text-gray-600"
                            onClick={() => handleDelete(item.id)}
                          >
                            <i className="bi bi-trash"></i>
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                  {active[0] ? (
                    <div></div>
                  ) : (
                    <div className="flex justify-end items-center">
                      <div className="hover-box2 w-full md:max-w-md max-h-60 md:max-h-56 bg-white p-6 border border-gray-300 rounded-md shadow-lg">
                        <h3 className="text-xl font-semibold text-black mb-1">
                          Order Summary
                        </h3>
                        <hr className="mb-4" />
                        <p className="text-black mb-2">
                          Subtotal (
                          {
                            items.filter((_, index) => selectedItems[index])
                              .length
                          }{" "}
                          items): Rs. {calculateSubtotal().toFixed(2)}
                        </p>
                        <p className="text-2xl font-semibold text-black mb-4">
                          Total: Rs. {calculateSubtotal().toFixed(2)}
                        </p>
                        <button
                          className="bg-blue-600 text-white px-6 py-3 w-full rounded"
                          onClick={proceedToCheckOut}
                          disabled={
                            items.filter((_, index) => selectedItems[index])
                              .length === 0
                          }
                        >
                          PROCEED TO CHECKOUT (
                          {
                            items.filter((_, index) => selectedItems[index])
                              .length
                          }
                          )
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {/* login model */}
        <LoginModal
          show={showModal}
          handleClose={handleCloseModal}
          navigatePath={`/shoppingCartItem/${companyId}/cart`}
        />
      </div>
    </>
  );
}
