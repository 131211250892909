import React, { Component } from 'react';
//@ts-ignore
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import axios from 'axios';
import './home.css';
import { Link } from 'react-router-dom';
//@ts-ignore
import AOS from 'aos';
import 'aos/dist/aos.css';
const baseUrl = process.env.REACT_APP_BASE_URL;

// Default image URL
const defaultLogoUrl = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAO0AAADVCAMAAACMuod9AAAAVFBMVEXu7u5mZmbz8/NZWVlcXFy8vLzU1NSXl5eioqKysrL19fV/f3/x8fFjY2NeXl7Nzc3j4+NqamrKysqMjIx3d3eGhobp6ene3t5TU1Nubm6wsLCPj49E04CSAAACxUlEQVR4nO3b63KiQBRFYWwIJoSbYnQyef/3HPCCIt1OMraTOjtr/VSk+vMolypNkh+U++4F/NfQ6oZWN7S6odUNrW5odUOrG1rd0OqGVje0uqHVDa1uaHVDqxta3dDqhlY3tLqh1Q2tbmh1Q6sbWt3Q6oZWN7S6odUNrW5odUOrG1rd0OqGVje0uqHVDa1uaHVDq9sDtKWLVfSlxd9j2TzFqo29tge8f89plsao/pWXsdcWeX+Dtn5dxug9M6FNX4oYvaZGtDH2ivbe0N65R7T/ENp7+25tf5kZIOlpS5e//F4nXpScttxt0rrOVo1vezVt2dbVoq9KGw9LTVu87bE9d+F5gZq2zRbHsvXcJaYtm1Fbe16hps1/kjbZjlqfy7bWtdePFsv6eJTaFPPtTWtdk65nD7/vuVW1EzsD9dgqW1+NsEyWWZalb61PZVjr9sff+XSLbdO0/itlu9p+ssfz6uwLWoZEZrVuPLN6vruhrGrPWO90x6bHKqPaS2zP9d7wDJt11eRoZVM7xQan67r+huiSa1J7jQ1Mt8cO90IXXIvaOXbgzqa7x065BrU+rGe6RXe6hjxz7Wn92BnXnbA9d3XimtOGsFfc4oy9mK41rWvqAHbCdd1ks9N0jWnDk51wi+7qPTlybWlvTfaC6z5mmx24prS3JztyZ5MduZa0Lr892QO38Ez2xDWkdfnfJnvg+rF7rh1t8YnJDtVV6JlqlVjRPn1qsrerNhsb2vojOLKvcBc2fkEU/nx+LSPaOFi0964t8v7QokVrU5tmcbLw++Rkl8dqG3tpj/gXRRmr6CvjPyO6odUNrW5odUOrG1rd0OqGVje0uqHVDa1uaHVDqxta3dDqhlY3tLqh1Q2tbmh1Q6sbWt3Q6oZWN7S6odUNrW5odUOrG1rd0OqGVje0uqHVDa1uaHVDq9sDtKWLVfSlxd9j2TzFqo29tge8f89plsao/pWXsdcWeX+Dtn5dxug9M6FNX4oYvaZGtDH2ivbe0N65R7T/ENp7+25tf5kZIOlpS5e//F4nXpScttxt0rrOVo1vezVt2dbVoq9KGw9LTVu87bE9d+F5gZq2zRbHsvXcJaYtm1Fbe16hps1/kjbZjlqfy7bWtdePFsv6eJTaFPPtTWtdk65nD7/vuVW1EzsD9dgqW1+NsEyWWZalb61PZVjr9sff+XSLbdO0/itlu9p+ssfz6uwLWoZEZrVuPLN6vruhrGrPWO90x6bHKqPaS2zP9d7wDJt11eRoZVM7xQan67r+huiSa1J7jQ1Mt8cO90IXXIvaOXbgzqa7x065BrU+rGe6RXe6hjxz7Wn92BnXnbA9d3XimtOGsFfc4oy9mK41rWvqAHbCdd1ks9N0jWnDk51wi+7qPTlybWlvTfaC6z5mmx24prS3JztyZ5MduZa0Lr892QO38Ez2xDWkdfnfJnvg+rF7rh1t8YnJDtVV6JlqlVjRPn1qsrerNhsb2vojOLKvcBc2fkEU/nx+LSPaOFi0964t8v7QokVrU5tmcbLw++Rkl8dqG3tpj/gXRRmr6CvjPyO6odUNrW5odUOrG1rd0OqGVje0uqHVDa1uaHVDqxta3dDqhlY3tLqh1Q2tbmh1Q6sbWt3Q6oZWN7S6odUNrW5odUOrG1rd0OqGVje0uqHVDa1uaHVDqxta3dDqhlY39wcAlyz4wLPLJwAAAABJRU5ErkJggg==';


interface Company {
  logo: string;
  name: string;
  contact: string;
  address: string;
}

interface CarouselProps { }

interface CarouselState {
  companies: Company[];
  page: number;
  rowsPerPage: number;
}

class Carousel extends Component<CarouselProps, CarouselState> {
  constructor(props: CarouselProps) {
    super(props);
    this.state = {
      companies: [],
      page: 0,
      rowsPerPage: 10,
    };
  }

  componentDidMount() {
    this.fetchCompanyLogos();
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
    });
  }

  componentDidUpdate() {
    // Reinitialize AOS when the component updates
    AOS.refresh();
  }
  fetchCompanyLogos = async () => {
    try {
      const data = {
        offset: 0,
        limit: 10,
        columnName: "company_id",
        branchIds: []
      };
      const response = await axios.post(`${baseUrl}/web-company-save/getAll`, data);
      if (response.status === 200 && response.data.data.companies) {
        this.setState({ companies: response.data.data.companies });
      } else {
        console.error('No companies found in response');
      }
    } catch (error) {
      console.error('There was an error fetching the companies!', error);
    }
  };



  render() {

    const NextArrow = (props: any) => {
      const { className, style, onClick } = props;
      return (
        <div
          className={`${className} custom-next-arrow`}
          style={{
            ...style,
            right: '-35px',
            top: '50%',
            transform: 'translateY(-50%)',
            width: '50px',
            height: '50px',
            zIndex: 1,
            cursor: 'pointer',
            color: 'blue',
            fontSize: '30px'
          }}
          onClick={onClick}
        >
          <i className="bi bi-chevron-right"></i>
        </div>
      );
    };


    const PrevArrow = (props: any) => {
      const { className, style, onClick } = props;
      return (
        <div
          className={`${className} custom-prev-arrow`}
          style={{
            ...style,
            left: '-50px',
            top: '50%',
            transform: 'translateY(-50%)',
            width: '50px',
            height: '50px',
            zIndex: 1,
            cursor: 'pointer',
            color: 'blue',
            fontSize: '30px'
          }}
          onClick={onClick}
        >
          <i className="bi bi-chevron-left"></i>
        </div>
      );
    };


    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      arrows: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
            arrows: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: true,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
          },
        },
      ],
    };

    return (
      <div className="container px-8 mt-10 overflow-hidden scroll-smooth ">

        <div className="row home-services-topic">
          <div className="mb-12 text-center">
            <h4 data-aos="fade-up" className="text-2xl font-bold text-[#1868db]">
              Our Customers
            </h4>
          </div>
        </div>

        <Slider {...settings}>
          {this.state.companies.map((company: any, index) => (
            <a href={`#/shoppingCartItem/${company.id}`} key={index} >
              <div className="flex flex-col items-center justify-center p-2 m-3 overflow-hidden border h-96 rounded-3xl gap-x-3 hover:border-blue-500">
                <div className="w-full h-1/3">
                  <img
                    src={company.logo || defaultLogoUrl}
                    alt={`Customer ${index + 1}`}
                    className="object-contain w-full h-full"
                    onError={(e) => {
                      e.currentTarget.src = defaultLogoUrl;
                    }}
                  />
                </div>
                <div className='flex flex-col justify-center w-full p-4 overflow-hidden text-center h-2/3'>
                  <div className="mb-2 overflow-hidden font-semibold text-ellipsis whitespace-wrap">
                    <i className="bi bi-building"></i> {company.name}
                  </div>
                  <div className="mb-2 overflow-hidden font-semibold text-ellipsis whitespace-wrap">
                    <i className="bi bi-telephone"></i> {company.contact}
                  </div>
                  <div className="mb-2 overflow-hidden font-semibold text-ellipsis whitespace-wrap">
                    <i className="bi bi-geo-alt"></i> {company.address}
                  </div>
                </div>
              </div>
            </a>
          ))}
        </Slider>
        <div className="flex justify-center md:justify-end lg:justify-end pt-10">
          <Link to="/customer">
            <button className='px-4 py-2 font-medium text-white rounded-lg shadow-lg bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800'>
              View All Customers
            </button>
          </Link>
        </div>
      </div>
    );
  }
}

export default Carousel;
