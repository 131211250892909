import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import CategoryNavBar from "./CategoryNavBar";
import LoginModal from "../Items/LoginModal";
import AddNewAddressForm from "./AddNewAddressForm";
import AddressSection from "../../component/card/addressSection";
import CartItemsSection from "../../component/card/cartItemsSection";
import ShippingSection from "../../component/card/shippingSection";
import Swal from "sweetalert2";
import { log } from "console";
import Lottie from "lottie-react";
import loadingAnimation from "../../../Assets/loader2.gif";
import { Helmet } from "react-helmet";

const baseUrl = process.env.REACT_APP_BASE_URL;

interface Address {
  id: number | null;
  fullName: string;
  mobileNumber: string;
  address: string;
  city: string;
  district: string;
  defaultAddress: boolean;
  webUserId: number;
}

interface DeliveryInfo {
  id: number;
  companyId: number;
  district: string;
  city: string;
  isSupported: boolean;
  deliveryCharge: number | null;
}

type CartItem = {
  id: number;
  quantity: number;
  cartId: number;
  itemId: number;
  itemName: string;
  imgURL: string;
  companyId: number;
  batchId: number;
  sellingPrice: number;
  isSupported?: boolean;
  deliveryCharge?: number | null;
};

interface DeliveryOptions {
  id: number;
  deliveryMode: string;
  description: string;
}

const CheckoutPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addressData, setDeliveryAddress] = useState<Address[]>([]);
  const location = useLocation();
  const [selectedCartItems, setSelectedCartItems] = useState<CartItem[]>([]);
  const [selectedItem, setSelectedItem] = useState<CartItem | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isChangeAddress, setChangeAddress] = useState<boolean>(false);
  const [isNewAddress, setNewAddress] = useState<boolean>(false);
  const [isEditAddress, setEditAddress] = useState<boolean>(false);
  const [deliveryCharges, setDeliveryCharge] = useState<DeliveryInfo[]>([]);
  const [updatedCartItems, setUpdatedCartItems] = useState<CartItem[]>([]);
  const [totalDelivery, setTotalDelivery] = useState<number>(0);
  const [deliveryFee, setDeliveryFee] = useState<number>(0);
  const [deliveryModeId, setDeliveryModeId] = useState<number>(0);
  const [isDeliveryId, setIsDeliveryId] = useState<boolean>(false);
  const [isPlaceOrderDisabled, setIsPlaceOrderDisabled] =
    useState<boolean>(false);
  const [isStorePickup, setIsStorePickup] = useState<boolean>(false);
  const [isAddressMassage, setAddressMassage] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [defaultAddress, setDefaultAddress] = useState<Address>();
  //const [orderNo, setOrderNo] = useState<string>();
  const [deliveryOptions, setDeliveryOptions] = useState<DeliveryOptions[]>([]);
  const [selectedDeliveryChargeId, setSelectedDeliveryChargeId] = useState(0);
  const [customerDeliveryDetailId, setCustomerDeliveryDetailId] =
    useState<number>(0);
  const navigate = useNavigate();
  const { companyId } = useParams();

  const [isLoading, setIsLoading] = useState(false);

  const uniqueCompanyIds = Array.from(
    new Set(selectedCartItems.map((item: CartItem) => item.companyId))
  );
  const companyIdsPayload = { companyIds: uniqueCompanyIds };
  const netTotal = total + totalDelivery;

  //get auth and token
  const setStoreUser = (): { userId: string | null; auth: string | null } => {
    const storeUser = sessionStorage.getItem("user");

    if (storeUser) {
      const user = JSON.parse(storeUser);
      const userId = user.id;
      const auth = user.token;

      return { userId, auth };
    }
    return { userId: null, auth: null };
  };

  const { userId, auth } = setStoreUser();

  useEffect(() => {
    if (addressData && addressData.length !== 0) {
      const defaultAddress = addressData.find(
        (address) => address.defaultAddress === true
      );
      setDefaultAddress(defaultAddress);
      if (defaultAddress) {
        setCustomerDeliveryDetailId(defaultAddress?.id ?? 0); //?? 0 is filter null or undifined then get 0
      }
    }
  }, [addressData]);

  //get items details in location and set it
  useEffect(() => {
    if (location.state && location.state.selectedCartItems) {
      setSelectedCartItems(location.state.selectedCartItems);
    }
    if (location.state && location.state.selectedItem) {
      setSelectedItem(location.state.selectedItem);
    }
  }, [location]);

  //select one item by use this
  useEffect(() => {
    if (selectedItem) {
      setSelectedCartItems((prevItems) => [...prevItems, selectedItem]);
    }
  }, [selectedItem]);

  useEffect(() => {
    total === 0 ? setTotalDelivery(0) : setTotalDelivery(totalDelivery);
  }, [updatedCartItems]);

  //for get company delivery options
  useEffect(() => {
    if (!auth || !userId) {
      setShowModal(true);
      return;
    }
    if (uniqueCompanyIds[0] !== undefined) {
      axios
        .get(`${baseUrl}/webDeliveryOptions/${uniqueCompanyIds[0]}`, {
          headers: {
            Authorization: `Bearer ${auth}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            setDeliveryOptions(response.data.data.deliveryOptions);
          } else {
            console.error("Unexpected response status:", response.status);
          }
        })
        .catch((error) => {
          console.error("Error fetching cart data:", error);
        });
    }
  }, [updatedCartItems]);

  //get delevery charge for customer location
  useEffect(() => {
    if (!defaultAddress) {
      return;
    }
    const fetchDeliveryCharge = async () => {
      try {
        const response = await axios.post(
          `${baseUrl}/webDeliveryCharge/getCompanyDeliveryCharge/${defaultAddress.city}/${defaultAddress.district}`,
          companyIdsPayload,
          {
            headers: {
              Authorization: `Bearer ${auth}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.status === 200 && response.data.data) {
          setDeliveryCharge(response.data.data.deliveryCharges);
          setDeliveryFee(response.data.data.totalCharge);
        }
      } catch (error) {
        console.error("Error fetching delivery charge:", error);
      }
    };

    fetchDeliveryCharge();
  }, [defaultAddress]);

  //get use delivery address
  useEffect(() => {
    if (!auth || !userId) {
      setShowModal(true);
      return;
    }

    setShowModal(false);

    axios
      .get(`${baseUrl}/delivery_detail/getByUser/${userId}`, {
        headers: {
          Authorization: `Bearer ${auth}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200 && response.data.data) {
          setDeliveryAddress(response.data.data.deliveryDetails);
        } else {
          console.error("Unexpected response status:", response.status);
        }
      })
      .catch((error) => {
        console.error("Error fetching cart data:", error);
      });
  }, [auth, userId]);

  //set delivery charge id
  useEffect(() => {
    getDeliveryChargeId();
  }, [deliveryCharges]);

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isModalOpen]);

  // Update cartItems based on deliveryCharges
  useEffect(() => {
    const updatedItems: any = selectedCartItems.map((item: CartItem) => {
      const deliveryCharge = deliveryCharges.find(
        (charge: DeliveryInfo) => charge.companyId === item.companyId
      );
      return {
        ...item,
        isSupported: deliveryCharge?.isSupported,
        deliveryCharge: deliveryCharge?.deliveryCharge,
      };
    });
    setUpdatedCartItems(updatedItems);

    const hasUnsupportedItems = updatedItems.some(
      (item: CartItem) => item.isSupported === false
    );
    setIsPlaceOrderDisabled(hasUnsupportedItems);

    const newTotal = updatedItems.reduce(
      (sum: number, item: CartItem) => sum + item.quantity * item.sellingPrice,
      0
    );
    setTotal(newTotal);
  }, [selectedCartItems, deliveryCharges]);

  //handle delete
  const handleDelete = (itemId: number) => {
    const updatedItems = updatedCartItems.filter((item) => item.id !== itemId);
    setUpdatedCartItems(updatedItems);

    const hasUnsupportedItems = updatedItems.some(
      (item) => item.isSupported === false
    );
    setIsPlaceOrderDisabled(hasUnsupportedItems);

    const newTotal = updatedItems.reduce(
      (sum: number, item: CartItem) => sum + item.quantity * item.sellingPrice,
      0
    );
    setTotal(newTotal);
  };

  //get delivery Charge id
  const getDeliveryChargeId = async () => {
    if (deliveryCharges && deliveryCharges.length > 0) {
      setSelectedDeliveryChargeId(deliveryCharges[0].id);
    }
  };

  //get Order No
  const getOrderNo = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}/order/code/${uniqueCompanyIds[0]}`,
        {
          headers: {
            Authorization: `Bearer ${auth}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200 && response.data.data) {
        //setOrderNo(response.data.data.orderCode);
        return response.data.data.orderCode;
      } else {
        console.error("Unexpected response status:", response.status);
      }
    } catch (error) {
      console.error("Error fetching order number:", error);
    }
    return null;
  };

  // handle placeOrder
  const handlePlaceOrder = async () => {
    if (deliveryModeId === 0) {
      setIsDeliveryId(true);
      return;
    }
    setAddressMassage(false);
    if (isStorePickup === false && defaultAddress === undefined) {
      setAddressMassage(true);
      return;
    }

    setIsLoading(true); // Start the loader

    // Call order No and wait for it to complete
    const orderNo = await getOrderNo();

    if (!orderNo) {
      return;
    }

    // console.log(orderNo);

    const filteredArray = updatedCartItems.map((item) => ({
      id: null,
      itemId: item.itemId,
      batchId: item.batchId,
      orderId: null,
      qty: item.quantity,
      amount: item.sellingPrice,
      discount: 0,
    }));

    const data = {
      webUserId: userId,
      placeOrderDTOList: [
        {
          companyId: uniqueCompanyIds[0],
          id: null,
          orderNo: orderNo,
          discount: 0,
          amount: netTotal,
          deliveryChargeId: selectedDeliveryChargeId,
          deliveryCharge: isStorePickup ? 0 : totalDelivery,
          deliveryNote: "",
          deliveryModId: deliveryModeId,
          orderDetails: filteredArray,
        },
      ],
    };

    axios
      .post(`${baseUrl}/order`, data, {
        headers: {
          Authorization: `Bearer ${auth}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Order placed successfully!",
            showConfirmButton: true,
          }).then(() => {
            navigate(`/shoppingCartItem/${companyId}`);
          });
        }
      })
      .catch((error) => {
        // Error alert using SweetAlert2
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong! Could not add item to cart.",
        });
        setIsLoading(false);
        console.error("Error adding item to cart:", error);
      });
  };

  //handle shipping methods
  const handleShippingMethodSelect = (method: DeliveryOptions) => {
    if (method.deliveryMode.toLowerCase() === "store pickup") {
      setTotalDelivery(0);
      setIsStorePickup(true);
      setDeliveryModeId(method.id);
      setIsDeliveryId(false);
      setSelectedDeliveryChargeId(0);
      setCustomerDeliveryDetailId(0);
    } else {
      setDeliveryModeId(method.id);
      setTotalDelivery(deliveryFee);
      setIsStorePickup(false);
      setIsDeliveryId(false);
      getDeliveryChargeId();

      if (addressData && addressData.length !== 0) {
        const defaultAddress = addressData.find(
          (address) => address.defaultAddress === true
        );
        setDefaultAddress(defaultAddress);
        if (defaultAddress) {
          setCustomerDeliveryDetailId(defaultAddress?.id ?? 0); //?? 0 is filter null or undifined then get 0
        }
      }
    }
  };

  //login model close
  const handleCloseModal = () => {
    setShowModal(false);
  };

  //address dialog open
  const openModal = () => {
    setIsModalOpen(true);
  };

  //address dialog close
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {/* <Helmet>
        <title>Pet Medi Web - Checkout Page</title>
        <meta
          name="description"
          content="Learn more about Pet Medi, your trusted pet health partner."
        />
        <meta
          name="keywords"
          content="pet, health, care, dogs, cats, veterinary,Pet clinic in Sri Lanka,Veterinary services Sri Lanka,Pet hospital,Affordable pet care Sri Lanka, 
      Veterinarian near me,Animal clinic Sri Lanka,Pet surgery ,Pet vaccinations Sri Lanka,24/7 emergency vet Sri Lanka, Pet health checkup Sri Lanka, Dog clinic Sri Lanka, 
      Cat veterinary care Sri Lanka, Exotic pet care Sri Lanka, Pet dental care Sri Lanka, Pet grooming, Pet boarding services Sri Lanka, Best vet for pet Sri Lanka, 
      Cataract surgery for pets Sri Lanka, Animal rescue services Sri Lanka ,Veterinary clinic , Sri Lanka , Pet Medi, pet, P , Sri Lanka Pets, Pet Care, Animal Clinic, 
      Vet, Pet Health, Pet Health Checkup, veterinary,Pet dental care, Grooming, Best Animal Clinic, Pet health monitoring app, Veterinary telemedicine Sri Lanka,Pet prescription management,
      Pet weight management Sri Lanka,Pet nutrition advice Sri Lanka,Digital veterinary solutions,Pet clinic appointment system,Senior pet care services,Specialized pet care Sri Lanka,
      Online vet consultation Sri Lanka,Pet illness prevention,Remote vet consultations,Pet healthcare software,Preventive vet care Sri Lanka,Pet emergency hotline Sri Lanka,
      Advanced pet diagnostics,Pet care plans Sri Lanka,In-home veterinary services,Pet surgery aftercare Sri Lanka,Pet grooming and spa Sri Lanka,Veterinary lab testing Sri Lanka,
      Personalized pet healthcare plans,Vet treatment packages Sri Lanka,Animal health solutions Sri Lanka,Vet treatment plans Sri Lanka,Pet physiotherapy Sri Lanka, 
      Vets near me, cat clinic near me, dog clinic near me, rabbit consultant doctors, dog consultant doctors, cat consultant doctors, online pet app, pet app online, 
      app for pets, app for animals, animal doctor, pet doctor, pet consultant, Pet wellness programs Sri Lanka, Pet wellness programs, Veterinary nutrition consultation, 
      Pet behavior training Sri Lanka, Pet behavior training, Veterinary dermatology Sri Lanka, Veterinary dermatology, Puppy vaccinations, Puppy vaccinations Sri Lanka, 
      Kitten vaccinations Sri Lanka, Kitten vaccinations, Flea and tick prevention Sri Lanka, Flea and tick prevention, Microchipping services Sri Lanka, Pet allergy treatment Sri Lanka, Pet allergy treatment,
      Veterinary orthopedic surgery Sri Lanka,Veterinary orthopedic surgery, 24-hour vet service Sri Lanka, 24-hour vet service , Emergency pet care Sri Lanka, Emergency pet care,
      Animal eye care Sri Lanka,Animal eye care,Pet dental cleaning Sri Lanka,Pet dental cleaning,Dog vaccinations Sri Lanka, Dog vaccinations,Cat vaccinations Sri Lanka,Cat vaccinations,Animal diagnostic imaging,
      Vet appointment booking online Sri Lanka,Vet appointment booking online,Pet Surgery Sri Lanka, Pet Surgery,Specialist vet services Sri Lanka,Specialist vet services,Pet cardiologist Sri Lanka, Pet cardiologist,
      Best dog vet Sri Lanka,Best dog vet, Best cat vet Sri Lanka, Best cat vet,Pet health checkup Sri Lanka, Pet health checkup, Puppy health checkup Sri Lanka,Puppy health checkup, Kitten health checkup Sri Lanka,Kitten health checkup,
      Emergency pet clinic near me,Pet health and wellness Sri Lanka,Pet health and wellness , pet treatment,Pet Health, Pet Services,Pet Services near me,Pet health diagnosis,Veterinary diagnosis,Common pet diseases,Animal health conditions,
      Dog illness diagnosis,Cat health problem, Dog health problem, Pet health problem, Pet disease symptoms, Veterinary medical conditions, Signs of illness in pets, Pet diagnostic tests,
      Dog allergy diagnosis, Cat urinary tract infection symptoms, Dog urinary tract infection symptoms, Pet urinary tract infection symptoms, Veterinary blood tests for pets, Pet arthritis treatment,
      Dog diabetes diagnosis, Cat diabetes diagnosis, Pet diabetes diagnosis, Pet cancer signs, Common pet skin diseases, Animal ear infections, Dog digestive problems diagnosis,
      Cat digestive problems diagnosis, Pet digestive problems diagnosis, Pet liver disease symptoms, Kidney disease in cats, Kidney disease in dogs, Kidney disease in pets,
      Pet obesity treatment, Dog heart disease symptoms, Cat heart disease symptoms, Pet heart disease symptoms, Cat thyroid disease, Dog thyroid disease, Pet thyroid disease,
      Pet parasite infections, Veterinary checkup for pets, Dog hip dysplasia diagnosis, Cat respiratory infections, Dog respiratory infections, Pet respiratory infections, Pancreatitis in pet symptoms, Dog Food, Cat Food"
    />
      </Helmet> */}

      <div>
        {/* nav bar section */}
        <CategoryNavBar
          isDropdownOpen={false}
          handleDropdownToggle={() => { }}
        />
        {isLoading ? (
          <div className="flex justify-center items-center h-screen">
            <img src={loadingAnimation} alt="" />
          </div>
        ) : (
          <div className="px-6 sm:px-12 lg:px-16 bg-gray-100 min-h-svh pt-10 pb-10">
            <div className="w-full grid grid-cols-1 justify-evenly md:grid-cols-3 md:gap-4">
              {/* Left Column */}
              <div className="col-span-2">
                {/*shipping address section */}
                <AddressSection
                  addressData={addressData}
                  defaultAddress={defaultAddress}
                  openModal={openModal}
                  setNewAddress={setNewAddress}
                  setEditAddress={setEditAddress}
                  setChangeAddress={setChangeAddress}
                />

                {/* shipping method section */}
                <ShippingSection
                  shippingMethods={deliveryOptions}
                  onShippingMethodSelect={handleShippingMethodSelect}
                />

                {/* selected item section */}
                <CartItemsSection
                  updatedCartItems={updatedCartItems}
                  handleDelete={handleDelete}
                  isVisibleMassage={isStorePickup}
                />
              </div>

              {/* Discount and Payment Column */}
              <div>
                <div className="bg-white p-4 rounded-lg shadow mt-2 md:mt-0">
                  <h2 className="text-lg font-semibold mb-4">
                    Discount and Payment
                  </h2>

                  <div className="border-t pt-4">
                    <h2 className="text-lg font-semibold mb-4">
                      Order Summary
                    </h2>
                    <div className="flex justify-between items-center mb-2">
                      <span>Items Total</span>
                      <span>Rs. {total.toFixed(2)}</span>
                    </div>
                    <div className="flex justify-between items-center mb-2">
                      <span>Delivery Fee</span>
                      <span>Rs. {totalDelivery.toFixed(2)}</span>
                    </div>
                    <div className="flex justify-between items-center mb-2">
                      <span>Discount</span>
                      <span>Rs. 0.00</span>
                    </div>
                    <div className="flex justify-between items-center mb-2">
                      <span>Total Payment</span>
                      <span className="font-semibold">
                        Rs. {netTotal.toFixed(2)}
                      </span>
                    </div>
                    <button
                      onClick={handlePlaceOrder}
                      disabled={
                        (isPlaceOrderDisabled && !isStorePickup) || total === 0
                      }
                      className={`w-full py-2 rounded-lg mt-4 text-white ${(isPlaceOrderDisabled && !isStorePickup) || total === 0
                          ? "bg-gray-400 cursor-not-allowed"
                          : "bg-blue-600 hover:bg-blue-700"
                        }`}
                    >
                      Place Order
                    </button>
                    {isPlaceOrderDisabled && !isStorePickup && (
                      <p className="w-full text-rose-600 mt-2 text-center">
                        Unavailable Delivery This Area!
                      </p>
                    )}
                    {isDeliveryId && (
                      <p className="w-full text-rose-600 mt-2 text-center">
                        Please select a shipping method!
                      </p>
                    )}
                    {isAddressMassage === true ? (
                      <p className="w-full text-rose-600 mt-2 text-center">
                        Please add delivery address!
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/*Pop Up Delivery Address Modal */}
        {isModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center mt-20 z-10">
            <div className="bg-white rounded-lg relative w-full max-w-2xl m-6 overflow-hidden">
              <button
                onClick={closeModal}
                className="absolute top-4 right-4 text-gray-500 text-3xl"
              >
                &times;
              </button>

              {/* address dialog */}
              <AddNewAddressForm
                isnewAddress={isNewAddress}
                isChangeAddress={isChangeAddress}
                isEditAddress={isEditAddress}
                closeModal={closeModal}
              />
            </div>
          </div>
        )}

        {/* login model */}
        <LoginModal
          show={showModal}
          handleClose={handleCloseModal}
          navigatePath={`/shoppingCartItem/${companyId}/cart`}
        />
      </div>
    </>
  );
};

export default CheckoutPage;
