import React, { useEffect, useState } from 'react';
import UploadedImage from '../../../Assets/Customer/keren_70.jpg';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Pagination } from '@mui/material';
import UploadedImage2 from '../../../Assets/handshak.jpg';
import { FaAngry, FaHandHoldingHeart, FaHeart, FaSadTear, FaSmile, FaThumbsUp } from 'react-icons/fa';
import LoginModal from '../Items/LoginModal';
import { toHtml } from "@fortawesome/fontawesome-svg-core";
import { Helmet } from 'react-helmet';

const baseUrl = process.env.REACT_APP_BASE_URL;


interface Comment {
  id: number;
  text: string;
  replies: Comment[];
}

interface Post {
  id: number;
  image: string;
  title: string;
  href: string;
  description: string;
  date: string;
  datetime: string;
  category: {
    title: string;
    href: string;
  };
  author: {
    name: string;
    role: string;
    href: string;
    imageUrl: string;
  };
}

interface blogs {
  id: number;
  title: string;
  content: string;
  author: string;
  position: string;
  modifyDate: string;
  blogImgList: {
    id: number;
    imageUrl: string;
    blogId: null;
  }[];
}

interface BlogReact {
  id: number;
  reactNo: number;
  blogId: number;
  webUseId:number;
}

interface Reactions {
  one: number;
  two: number;
  three: number;
  four: number;
  five: number;
}

export default function BlogPage() {


  const navigate = useNavigate();

  const [blogs, setBlogs] = useState<blogs[]>([]);
  const [rowCount, setRowCount] = useState(0);
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(9);

  const [selectedEmojis, setSelectedEmojis] = useState<{ [key: number]: number }>({});
  const [showReactions, setShowReactions] = useState<{ [key: number]: boolean }>({});
  const [loggedInUser, setLoggedInUser] = useState<any>(null);
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);
  const [reactions, setReactions] = useState<{ [key: number]: Reactions }>({});
  const [blogReact, setBlogReact] = useState<BlogReact>();
  const [emojiVisible, setEmojiVisible] = useState<number | null>(null);
  const [userReactions, setUserReactions] = useState<any>(null); // Adjust the type as needed

  useEffect(() => {
    const user = sessionStorage.getItem("user");
    if (user) {
      setLoggedInUser(JSON.parse(user));
    }
  }, []);

  //get user reaction
  const getUserReactions = async (blogId: number, webUsrId: number) => {
    if (!loggedInUser || !loggedInUser.id) return;
    const { token } = setStoreUser();
    try {
      const response = await axios.get(`${baseUrl}/blog_react/get/${blogId}/${webUsrId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200 && response.data) {
        return response.data.data;
      }
    } catch (error) {
      console.error('Error fetching user reactions:', error);
    }
  };
  const fetchAndSetUserReactions = async (blogId: number, webUsrId: number) => {
    const userReactions = await getUserReactions(blogId, webUsrId);
    console.log('userReactions', userReactions);
    setUserReactions(userReactions);
    const reactionNo = userReactions?.react?.reactNo !== undefined
      ? userReactions.react.reactNo
      : null;
    setSelectedEmojis(prevEmojis => ({
      ...prevEmojis,
      [blogId]: reactionNo,
    }));
  };

  useEffect(() => {
    fetchBlogs();
  }, [page, rowsPerPage])


  useEffect(() => {
    if (loggedInUser && blogs) {
      blogs.forEach(blog => {
        fetchAndSetUserReactions(blog.id, loggedInUser.id);
      });
    }
  }, [loggedInUser, blogs]);


  useEffect(() => {
    blogs.forEach(blog => {
      fetchAllReactions(blog.id);
    });
  }, [blogs]);

  useEffect(() => {
    fetchBlogs().then(() => {
      blogs.forEach(blog => {
        fetchAllReactions(blog.id);
      });
    });
  }, [page, rowsPerPage]);

  type Reactions = {
    one: number;
    two: number;
    three: number;
    four: number;
    five: number;
  };
  const setStoreUser = (): { userId: string | null; token: string | null } => {
    const storeUser = sessionStorage.getItem("user");
    if (storeUser) {
      const user = JSON.parse(storeUser);
      const userId = user.id;
      const token = user.token;
      return { userId, token };
    }
    return { userId: null, token: null };
  };
  const { userId, token } = setStoreUser();
  const fetchBlogs = async () => {
    try {
      const data ={
        offset: page - 1,
        limit: rowsPerPage,
        columnName: "id",
      }
      axios.post(`${baseUrl}/web_blog/get_all`, data)
      .then(response =>{
        if(response.status === 200 && response.data.data){
          setBlogs(response.data.data.blogs);
          setRowCount(response.data.data.rowCount || 0);
        }
      })
    } catch (error) {
      console.error("There was an error fetching the blogs!", error);
    }
  }

  const fetchAllReactions = async (blogId: number) => {
    const { token } = setStoreUser();
    try {
      const response = await axios.get(`${baseUrl}/blog_react/get/${blogId}`, {
        // headers: {
        //   Authorization: `Bearer ${token}`,
        // },
      });
      if (response.status === 200 && response.data) {
        const reactionsData = response.data.data.react;
        setReactions((prev) => ({
          ...prev,
          [blogId]: reactionsData,
        }));
        console.log("reactionsData response", response);
      }
    } catch (error) {
      console.error('Error fetching blog reactions:', error);
    }
  };


  const countReactions = (blogId: number) => {
    const reaction = reactions[blogId];
    if (!reaction) return 0; // Return 0 if no reactions found
    return (
      (reaction.one || 0) +
      (reaction.two || 0) +
      (reaction.three || 0) +
      (reaction.four || 0) +
      (reaction.five || 0)
    );
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const navigateBlogView = (id: number) => {
    navigate(`/blogView/${id}`)
  }
  const handleMouseEnter = (blogId: number) => {
    // fetchAllReactions(blogId);
    setShowReactions((prev) => ({
      ...prev,
      [blogId]: true,
    }));
  };
  const handleMouseLeave = (blogId: number) => {
    setShowReactions((prev) => ({
      ...prev,
      [blogId]: false,
    }));
  };
  const renderSelectedEmoji = (emojiId: number) => {
    switch (emojiId) {
      case 1:
        return <FaThumbsUp className="text-blue-600" />;
      case 2:
        return <FaHeart className="text-red-600" />;
      case 3:
        return <FaSmile className="text-yellow-600" />;
      case 4:
        return <FaAngry className="text-orange-600" />;
      case 5:
        return <FaSadTear className="text-purple-600" />;
      default:
        return <FaThumbsUp className="text-gray-400" />;
    }
  };
  const handleReaction = async (blogId: number, reactNo: number) => {
    if (!loggedInUser) {
      setShowLoginModal(true);
      return;
    }

    const currentReaction = selectedEmojis[blogId];

    const userReactions = await getUserReactions(blogId, loggedInUser.id);
    const existingReactionId = userReactions?.react?.id !== undefined
      ? userReactions.react.id
      : null;

    const data = {
      id: existingReactionId,
      reactNo: reactNo,
      blogId: blogId,
      webUserId: loggedInUser.id,
    };
    try {
      if (currentReaction && currentReaction === reactNo) {
        await handleDeleteReaction(blogId);
        setSelectedEmojis((prevReactions) => ({
          ...prevReactions,
          [blogId]: 0,
        }));
      } else {
        const response = await axios.post(`${baseUrl}/blog_react`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (response.status === 200) {
          setSelectedEmojis((prevReactions) => ({
            ...prevReactions,
            [blogId]: reactNo,
          }));
          fetchAllReactions(blogId);
        }
      }
    } catch (error) {
      console.error('Failed to send reaction:', error);
    }
  };
  const handleDeleteReaction = async (blogId: number) => {
    if (!loggedInUser) {
      setShowLoginModal(true);
      return;
    }
    const data = {
      id: blogReact?.id,
      reactNo: null,
      blogId: blogId,
      webUserId: userId,
    };
    try {
      const response = await axios.post(`${baseUrl}/blog_react/remove`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 200) {
        setSelectedEmojis((prevReactions) => ({
          ...prevReactions,
          [blogId]: 0,
        }));
        fetchAllReactions(blogId);
      }
    } catch (error) {
      console.error("Failed to send reaction:", error);
    }
  };
  const renderReactionsCount = (blogId: number) => {
    const reaction = reactions[blogId];
    if (!reaction) return null;
    return (
      <div className="mt-2 flex space-x-4">
        <div className="flex items-center">
          <FaThumbsUp className="text-blue-600" />
          <span className="ml-1">{reaction.one || 0}</span>
        </div>
        <div className="flex items-center">
          <FaHeart className="text-red-600" />
          <span className="ml-1">{reaction.two || 0}</span>
        </div>
        <div className="flex items-center">
          <FaSmile className="text-yellow-600" />
          <span className="ml-1">{reaction.three || 0}</span>
        </div>
        <div className="flex items-center">
          <FaAngry className="text-orange-600" />
          <span className="ml-1">{reaction.four || 0}</span>
        </div>
        <div className="flex items-center">
          <FaSadTear className="text-purple-600" />
          <span className="ml-1">{reaction.five || 0}</span>
        </div>
      </div>
    );
  };
  return (

    <>
      {/* <Helmet>
        <title>Pet Medi - Blog</title>
        <meta
          name="description"
          content="Learn more about Pet Medi, your trusted pet health partner."
        />
        <meta
          name="keywords"
          content="pet, health, care, dogs, cats, veterinary,Pet clinic in Sri Lanka,Veterinary services Sri Lanka,Pet hospital,Affordable pet care Sri Lanka, 
      Veterinarian near me,Animal clinic Sri Lanka,Pet surgery ,Pet vaccinations Sri Lanka,24/7 emergency vet Sri Lanka, Pet health checkup Sri Lanka, Dog clinic Sri Lanka, 
      Cat veterinary care Sri Lanka, Exotic pet care Sri Lanka, Pet dental care Sri Lanka, Pet grooming, Pet boarding services Sri Lanka, Best vet for pet Sri Lanka, 
      Cataract surgery for pets Sri Lanka, Animal rescue services Sri Lanka ,Veterinary clinic , Sri Lanka , Pet Medi, pet, P , Sri Lanka Pets, Pet Care, Animal Clinic, 
      Vet, Pet Health, Pet Health Checkup, veterinary,Pet dental care, Grooming, Best Animal Clinic, Pet health monitoring app, Veterinary telemedicine Sri Lanka,Pet prescription management,
      Pet weight management Sri Lanka,Pet nutrition advice Sri Lanka,Digital veterinary solutions,Pet clinic appointment system,Senior pet care services,Specialized pet care Sri Lanka,
      Online vet consultation Sri Lanka,Pet illness prevention,Remote vet consultations,Pet healthcare software,Preventive vet care Sri Lanka,Pet emergency hotline Sri Lanka,
      Advanced pet diagnostics,Pet care plans Sri Lanka,In-home veterinary services,Pet surgery aftercare Sri Lanka,Pet grooming and spa Sri Lanka,Veterinary lab testing Sri Lanka,
      Personalized pet healthcare plans,Vet treatment packages Sri Lanka,Animal health solutions Sri Lanka,Vet treatment plans Sri Lanka,Pet physiotherapy Sri Lanka, 
      Vets near me, cat clinic near me, dog clinic near me, rabbit consultant doctors, dog consultant doctors, cat consultant doctors, online pet app, pet app online, 
      app for pets, app for animals, animal doctor, pet doctor, pet consultant, Pet wellness programs Sri Lanka, Pet wellness programs, Veterinary nutrition consultation, 
      Pet behavior training Sri Lanka, Pet behavior training, Veterinary dermatology Sri Lanka, Veterinary dermatology, Puppy vaccinations, Puppy vaccinations Sri Lanka, 
      Kitten vaccinations Sri Lanka, Kitten vaccinations, Flea and tick prevention Sri Lanka, Flea and tick prevention, Microchipping services Sri Lanka, Pet allergy treatment Sri Lanka, Pet allergy treatment,
      Veterinary orthopedic surgery Sri Lanka,Veterinary orthopedic surgery, 24-hour vet service Sri Lanka, 24-hour vet service , Emergency pet care Sri Lanka, Emergency pet care,
      Animal eye care Sri Lanka,Animal eye care,Pet dental cleaning Sri Lanka,Pet dental cleaning,Dog vaccinations Sri Lanka, Dog vaccinations,Cat vaccinations Sri Lanka,Cat vaccinations,Animal diagnostic imaging,
      Vet appointment booking online Sri Lanka,Vet appointment booking online,Pet Surgery Sri Lanka, Pet Surgery,Specialist vet services Sri Lanka,Specialist vet services,Pet cardiologist Sri Lanka, Pet cardiologist,
      Best dog vet Sri Lanka,Best dog vet, Best cat vet Sri Lanka, Best cat vet,Pet health checkup Sri Lanka, Pet health checkup, Puppy health checkup Sri Lanka,Puppy health checkup, Kitten health checkup Sri Lanka,Kitten health checkup,
      Emergency pet clinic near me,Pet health and wellness Sri Lanka,Pet health and wellness, pet treatment,Pet Health, Pet Services,Pet Services near me,Pet health diagnosis,Veterinary diagnosis,Common pet diseases,Animal health conditions,
      Dog illness diagnosis,Cat health problem, Dog health problem, Pet health problem, Pet disease symptoms, Veterinary medical conditions, Signs of illness in pets, Pet diagnostic tests,
      Dog allergy diagnosis, Cat urinary tract infection symptoms, Dog urinary tract infection symptoms, Pet urinary tract infection symptoms, Veterinary blood tests for pets, Pet arthritis treatment,
      Dog diabetes diagnosis, Cat diabetes diagnosis, Pet diabetes diagnosis, Pet cancer signs, Common pet skin diseases, Animal ear infections, Dog digestive problems diagnosis,
      Cat digestive problems diagnosis, Pet digestive problems diagnosis, Pet liver disease symptoms, Kidney disease in cats, Kidney disease in dogs, Kidney disease in pets,
      Pet obesity treatment, Dog heart disease symptoms, Cat heart disease symptoms, Pet heart disease symptoms, Cat thyroid disease, Dog thyroid disease, Pet thyroid disease,
      Pet parasite infections, Veterinary checkup for pets, Dog hip dysplasia diagnosis, Cat respiratory infections, Dog respiratory infections, Pet respiratory infections, Pancreatitis in pet symptoms, Dog Food, Cat Food "
    />
      </Helmet> */}

      <div>
        <div className="grid grid-cols-1 h-auto pt-20">
          <div
              className="order-2 md:order-1 flex items-center justify-center lg:justify-center lg:items-center md:items-start md:mt-20 p-4 lg:p-5">
            <div className="pr-4 lg:pr-12 xl:pr-20">
              <div className="p-4 lg:p-8 xl:p-12">
                <div className="lg:pl-16 text-center">
                  <h1 className="text-xl lg:text-3xl xl:text-4xl mb-4 font-semibold">
                    <span className="text-[#1868db] lg:text-[4rem] md:text-[2rem] text-[2.2rem] block">Blogs</span>
                    <span
                      className="pt-6 text-xl text-gray-600 block">The Perfect Way To Know Our Newest Information</span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white ">
          <div className="mx-auto max-w-7xl px-6 pb-14">
            {/* <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="font-bold tracking-tight text-gray-900 text-xl md:text-2xl lg:text-3xl">From the blog</h2>
            <p className="mt-2 md-text-lg lg:text-lg leading-8 text-gray-600">
              Learn how to grow your business with our expert advice.
            </p>
          </div> */}
            <div
              className="mx-auto mt-10 grid max-w-2xl grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-16 border-t border-gray-200 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none h-auto">
              {blogs.map((post) => (
                <article key={post.id}
                  className="flex flex-col items-start justify-between border rounded-lg p-4 shadow-lg ">
                  <div className="flex items-center gap-x-4 text-xs mb-4">
                    <time dateTime={post.modifyDate} className="text-gray-500">
                      {post.modifyDate}
                    </time>
                    <a
                      onClick={() => navigateBlogView(post.id)}
                      className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100 cursor-pointer"
                    >
                      {post.title}
                    </a>
                  </div>
                  <a onClick={() => navigateBlogView(post.id)} className='cursor-pointer'>
                    <div className='h-3/4'>
                      {/* <img src={post.blogImgList.imageUrl} alt="Blog post image" className="img-fluid mb-4 rounded-md" /> */}

                      {post.blogImgList.length > 0 ? (
                        <img src={post.blogImgList[0].imageUrl} alt={post.title}
                          className="img-fluid mb-4 rounded-md" />
                      ) : (
                        <img src={UploadedImage2} alt="Default" className="img-fluid mb-4 rounded-md" />
                      )}

                    </div>
                  </a>
                  <div className="group relative">
                    <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600 cursor-pointer">
                      <a onClick={() => navigateBlogView(post.id)}>
                        <span className="absolute inset-0" />
                        {post.title}
                      </a>
                    </h3>
                    <div className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600"
                      dangerouslySetInnerHTML={{ __html: post.content }}></div>
                  </div>
                  <div className="relative mt-8 flex items-center gap-x-4">
                    <img src={UploadedImage} alt="" className="h-10 w-10 rounded-full bg-gray-50" />
                    <div className="text-sm leading-6">
                      <p className="font-semibold text-gray-900 cursor-pointer">
                        <a onClick={() => navigateBlogView(post.id)}>
                          <span className="absolute inset-0" />
                          {post.author}
                        </a>
                      </p>
                      <p className="text-gray-600">{post.position}</p>
                    </div>
                  </div>

                  <button className='mt-2 text-sm text-blue-600' onClick={() => navigateBlogView(post.id)}> Read More
                    ...
                  </button>

                  {/* Reaction System */}
                  <div className="mt-4 relative flex space-x-4 justify-start">
                    {/* Selected or default emoji */}
                    <div
                      onMouseEnter={() => handleMouseEnter(post.id)}
                      onMouseLeave={() => handleMouseLeave(post.id)}
                      className="cursor-pointer text-xl flex items-center space-x-2 relative"
                    >
                      {selectedEmojis[post.id] ? (
                        renderSelectedEmoji(selectedEmojis[post.id])
                      ) : (
                        <FaThumbsUp className="text-gray-400" />
                      )}
                      <span style={{
                        fontSize: '16px',
                        fontWeight: '600',
                        color: '#4a4a4a',
                        fontFamily: 'Roboto, Arial, sans-serif',
                        padding: '5px 10px',
                        borderRadius: '8px',
                        backgroundColor: '#f0f0f0',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                      }}>
                        {countReactions(post.id)}
                      </span>
                      {showReactions[post.id] && (
                        <div
                          className="absolute flex space-x-2 bg-white border rounded-lg p-2 shadow-lg"
                          style={{ top: '-40px', left: '0px' }}
                        >
                          <div className="flex flex-col items-center">
                            <FaThumbsUp
                              className="text-blue-600 cursor-pointer text-2xl"
                              onClick={() => handleReaction(post.id, 1)}
                            />
                            <span
                              className="text-xs">{reactions[post.id]?.one || 0}</span> {/* Count for thumbs up */}
                          </div>
                          <div className="flex flex-col items-center">
                            <FaHeart
                              className="text-red-600 cursor-pointer text-2xl"
                              onClick={() => handleReaction(post.id, 2)}
                            />
                            <span className="text-xs">{reactions[post.id]?.two || 0}</span> {/* Count for heart */}
                          </div>
                          <div className="flex flex-col items-center">
                            <FaSmile
                              className="text-yellow-600 cursor-pointer text-2xl"
                              onClick={() => handleReaction(post.id, 3)}
                            />
                            <span className="text-xs">{reactions[post.id]?.three || 0}</span> {/* Count for smile */}
                          </div>
                          <div className="flex flex-col items-center">
                            <FaAngry
                              className="text-orange-600 cursor-pointer text-2xl"
                              onClick={() => handleReaction(post.id, 4)}
                            />
                            <span className="text-xs">{reactions[post.id]?.four || 0}</span> {/* Count for angry */}
                          </div>
                          <div className="flex flex-col items-center">
                            <FaSadTear
                              className="text-purple-600 cursor-pointer text-2xl"
                              onClick={() => handleReaction(post.id, 5)}
                            />
                            <span className="text-xs">{reactions[post.id]?.five || 0}</span> {/* Count for sad */}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </article>
              ))}
            </div>
          </div>

          <div className="flex flex-wrap justify-center items-center pb-2 md:pb-4 mt-2 md:mt-4 space-y-2 md:space-y-0">
            <span className="text-blue-600 text-sm md:text-lg mr-0 md:mr-4">Page: {page}</span>
            <div className="flex flex-col md:flex-row items-center space-y-2  md:space-y-0 md:space-x-4">
              <Pagination
                count={Math.ceil(rowCount / rowsPerPage)}
                page={page}
                onChange={handleChangePage}
                color="primary"
                showFirstButton
                showLastButton
              />
            </div>
          </div>

        </div>
        <LoginModal
          show={showLoginModal}
          handleClose={() => setShowLoginModal(false)}
        />
      </div>
      </>
  );
}