import React, { Component } from "react";
import axios from "axios";
// import contact from "../../../assets/about3.png";
import "./contact.css";
import { Helmet } from "react-helmet";
//@ts-ignore
import AOS from 'aos';
import 'aos/dist/aos.css';
const baseUrl = process.env.REACT_APP_BASE_URL;

interface ContactState {
  name: any;
  email: any;
  subject: any;
  message: any;
}

export default class Contact extends Component<{}, ContactState> {
  constructor() {
    //@ts-ignore
    super();
    this.state = {
      name: "",
      email: "",
      subject: "",
      message: "",
    };
  }

  //@ts-ignore
  // handleChange = (e) => {
  //   this.setState({ [e.target.name]: e.target.value });
  // }

  handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    //@ts-ignore
    this.setState({ [e.target.name]: e.target.value });
  };

  //@ts-ignore
  handleSubmit = (e) => {
    e.preventDefault();

    //@ts-ignore
    const { name, email, subject, message } = this.state;

    axios
      .post(`${baseUrl}/web-mail/send`, {
        name,
        email,
        subject,
        message,
      })
      .then((response) => {
        console.log(response.data);
        alert("Message sent successfully!");
      })
      .catch((error) => {
        console.error("There was an error sending the message!", error);
        alert("Failed to send the message, please try again later.");
      });

    this.setState({
      name: "",
      email: "",
      subject: "",
      message: "",
    });
  };

  componentDidMount(): void {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
    });
  }

  componentDidUpdate() {
    // Reinitialize AOS when the component updates
    AOS.refresh();
  }

  render() {
    //@ts-ignore
    return (
      <>
        {/* <Helmet>
          <title>Pet Medi - Contact</title>
          <meta
            name="description"
            content="Learn more about Pet Medi, your trusted pet health partner."
          />
          <meta
            name="keywords"
            content="pet, health, care, dogs, cats, veterinary,Pet clinic in Sri Lanka,Veterinary services Sri Lanka,Pet hospital,Affordable pet care Sri Lanka, 
      Veterinarian near me,Animal clinic Sri Lanka,Pet surgery ,Pet vaccinations Sri Lanka,24/7 emergency vet Sri Lanka, Pet health checkup Sri Lanka, Dog clinic Sri Lanka, 
      Cat veterinary care Sri Lanka, Exotic pet care Sri Lanka, Pet dental care Sri Lanka, Pet grooming, Pet boarding services Sri Lanka, Best vet for pet Sri Lanka, 
      Cataract surgery for pets Sri Lanka, Animal rescue services Sri Lanka ,Veterinary clinic , Sri Lanka , Pet Medi, pet, P , Sri Lanka Pets, Pet Care, Animal Clinic, 
      Vet, Pet Health, Pet Health Checkup, veterinary,Pet dental care, Grooming, Best Animal Clinic, Pet health monitoring app, Veterinary telemedicine Sri Lanka,Pet prescription management,
      Pet weight management Sri Lanka,Pet nutrition advice Sri Lanka,Digital veterinary solutions,Pet clinic appointment system,Senior pet care services,Specialized pet care Sri Lanka,
      Online vet consultation Sri Lanka,Pet illness prevention,Remote vet consultations,Pet healthcare software,Preventive vet care Sri Lanka,Pet emergency hotline Sri Lanka,
      Advanced pet diagnostics,Pet care plans Sri Lanka,In-home veterinary services,Pet surgery aftercare Sri Lanka,Pet grooming and spa Sri Lanka,Veterinary lab testing Sri Lanka,
      Personalized pet healthcare plans,Vet treatment packages Sri Lanka,Animal health solutions Sri Lanka,Vet treatment plans Sri Lanka,Pet physiotherapy Sri Lanka, 
      Vets near me, cat clinic near me, dog clinic near me, rabbit consultant doctors, dog consultant doctors, cat consultant doctors, online pet app, pet app online, 
      app for pets, app for animals, animal doctor, pet doctor, pet consultant, Pet wellness programs Sri Lanka, Pet wellness programs, Veterinary nutrition consultation, 
      Pet behavior training Sri Lanka, Pet behavior training, Veterinary dermatology Sri Lanka, Veterinary dermatology, Puppy vaccinations, Puppy vaccinations Sri Lanka, 
      Kitten vaccinations Sri Lanka, Kitten vaccinations, Flea and tick prevention Sri Lanka, Flea and tick prevention, Microchipping services Sri Lanka, Pet allergy treatment Sri Lanka, Pet allergy treatment,
      Veterinary orthopedic surgery Sri Lanka,Veterinary orthopedic surgery, 24-hour vet service Sri Lanka, 24-hour vet service , Emergency pet care Sri Lanka, Emergency pet care,
      Animal eye care Sri Lanka,Animal eye care,Pet dental cleaning Sri Lanka,Pet dental cleaning,Dog vaccinations Sri Lanka, Dog vaccinations,Cat vaccinations Sri Lanka,Cat vaccinations,Animal diagnostic imaging,
      Vet appointment booking online Sri Lanka,Vet appointment booking online,Pet Surgery Sri Lanka, Pet Surgery,Specialist vet services Sri Lanka,Specialist vet services,Pet cardiologist Sri Lanka, Pet cardiologist,
      Best dog vet Sri Lanka,Best dog vet, Best cat vet Sri Lanka, Best cat vet,Pet health checkup Sri Lanka, Pet health checkup, Puppy health checkup Sri Lanka,Puppy health checkup, Kitten health checkup Sri Lanka,Kitten health checkup,
      Emergency pet clinic near me,Pet health and wellness Sri Lanka,Pet health and wellness , pet treatment,Pet Health, Pet Services,Pet Services near me,Pet health diagnosis,Veterinary diagnosis,Common pet diseases,Animal health conditions,
      Dog illness diagnosis,Cat health problem, Dog health problem, Pet health problem, Pet disease symptoms, Veterinary medical conditions, Signs of illness in pets, Pet diagnostic tests,
      Dog allergy diagnosis, Cat urinary tract infection symptoms, Dog urinary tract infection symptoms, Pet urinary tract infection symptoms, Veterinary blood tests for pets, Pet arthritis treatment,
      Dog diabetes diagnosis, Cat diabetes diagnosis, Pet diabetes diagnosis, Pet cancer signs, Common pet skin diseases, Animal ear infections, Dog digestive problems diagnosis,
      Cat digestive problems diagnosis, Pet digestive problems diagnosis, Pet liver disease symptoms, Kidney disease in cats, Kidney disease in dogs, Kidney disease in pets,
      Pet obesity treatment, Dog heart disease symptoms, Cat heart disease symptoms, Pet heart disease symptoms, Cat thyroid disease, Dog thyroid disease, Pet thyroid disease,
      Pet parasite infections, Veterinary checkup for pets, Dog hip dysplasia diagnosis, Cat respiratory infections, Dog respiratory infections, Pet respiratory infections, Pancreatitis in pet symptoms, Dog Food, Cat Food"
    />
        </Helmet> */}

        <div className="pb-20">
          <div className="flex flex-col md:flex-row lg:flex-row h-screen contactSection">
            <div className="flex-1 flex items-center justify-start p-4 lg:p-5">
              <div className="px-4 lg:pr-12 xl:pr-20">
                <div className="p-4 lg:p-8 xl:p-12">
                  <div className="border-0 lg:border-l-2 border-[#1868db] lg:pl-12 text-center md:text-left lg:text-left mt-48 md:mt-0 lg:mt-0">
                    <h1 className="text-2xl lg:text-4xl xl:text-5xl mb-4 font-semibold text-white md:text-black lg:text-black">
                      <span>We Are Hear To </span>
                      <span className="text-[#1868db] lg:text-[4.2rem] md:text-[3.2rem] text-[2.2rem]">
                        Support
                      </span>
                      <br />
                      <span> You</span>
                    </h1>
                    <p className="mb-4 text-lg lg:text-xl xl:text-xl text-white md:text-gray-500 lg:text-gray-500">
                      At Pet Medi, we are dedicated to providing you with the
                      best options for your pets' healthcare. Find details about
                      the nearest veterinary clinics and learn about their
                      services by visiting their websites.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex-1 items-center justify-end p-4 lg:p-0 lx  lg:flex md:flex">
              <img
                src={
                  "https://storage.googleapis.com/pet-medi-staging/web-assets/about3.png"
                }
                alt="Hero"
                className="object-cover w-full lg:h-auto max-h-80 lg:max-h-full"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
          </div>

          <div className="container px-6 md:px-10 lg:px-14 grid mx-auto max-w-[1440px] mt-10 md:mt-5 lg:mt-8">
            <div className="flex items-center justify-center ">
              <div className="flex flex-col md:flex-row w-full ">
                {/* Left side */}
                <div className="md:w-1/2 lg:w:1/2 xl:w-1/2 grid  pb-10 justify-start items-start">
                  <h1 data-aos="fade-right" className="text-xl lg:text-3xl md:text-2xl text-center lg:text-start md:text-start font-bold mb-4">
                    Let's get in touch with us.
                  </h1>
                  <p data-aos="fade-left" className="mb-8 text-gray-500">
                    If you would like to work with us or just want to get in
                    touch, we'd love to hear from you!
                  </p>
                  <div data-aos="fade-right" className="mb-8">
                    <i className="bi bi-phone mr-2 text-blue-600 lg:text-2xl"></i>
                    <a
                      href="tel:+94 77 714 0372"
                      className="lg:text-2xl md:text-2xl text-xl"
                    >
                      +94 77 764 8785
                    </a>
                  </div>

                  <div data-aos="fade-left" className="flex space-x-4 text-2xl">
                  <a href="https://www.facebook.com/profile.php?id=61567756161199&mibextid=LQQJ4d" target='_blank'><i className="text-blue-600 bi bi-facebook"></i></a>
                <a href="https://instagram.com/petmedi.lk/" target="_blank"><i className="text-blue-600 bi bi-instagram"></i></a>
                <a href="https://www.tiktok.com/@petmedi.lk" target="_blank"><i className="text-blue-600 bi bi-tiktok"></i></a>
                <a href="https://www.youtube.com/@Petmedi-n8b" target="_blank"><i className="text-blue-600 bi bi-youtube"></i></a>
                  </div>
                </div>
                {/* Right side */}
                <div
                data-aos="fade-left"
                
                className="md:w-1/2 lg:w:1/2 xl:w-1/2 grid justify-end items-end">
                  <form className="space-y-4" onSubmit={this.handleSubmit}>
                    <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
                      <input
                        type="text"
                        name="name"
                        placeholder="Name"
                        className="w-full md:w-1/2 p-2 rounded border-2 border-blue-300 focus:outline-none"
                        value={this.state.name}
                        onChange={this.handleChange}
                        required
                      />
                      <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        className="w-full md:w-1/2 p-2 rounded border-2 border-blue-300 focus:outline-none"
                        value={this.state.email}
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                    <input
                      type="text"
                      name="subject"
                      placeholder="Subject"
                      className="w-full p-2 rounded border-2 border-blue-300 focus:outline-none"
                      value={this.state.subject}
                      onChange={this.handleChange}
                      required
                    />
                    <textarea
                      name="message"
                      placeholder="Message"
                      className="w-full p-2 rounded border-2 border-blue-300 focus:outline-none"
                      rows={4}
                      value={this.state.message}
                      onChange={this.handleChange}
                      required
                    />
                    <button
                      type="submit"
                      className="p-2 rounded bg-blue-600 hover:bg-blue-700 text-white w-full"
                    >
                      Let's Talk
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
