import { Box, Button, Modal, TextField } from "@mui/material";
import React, { useState } from "react";

interface PopupProps {
  open: boolean;
  handleClose: () => void;
  onAdd: (count: number) => void;
}

const Branches: React.FC<PopupProps> = ({ open, handleClose, onAdd }) => {
  const [input, setInput] = useState("");
  const [error, setError] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    // Check if the value is a valid number or empty
    if (/^\d*$/.test(value)) {
      setInput(value);
      setError(false);
    } else {
      setError(true);
    }
  };

const handleSave = () => {
    if (!error) {
      const count = parseInt(input, 10); // Parse the input value to a number
      onAdd(count); // Emit the event with the input value
      console.log("Count :", count)
      handleClose(); // Close the popup
    }
  };

  return (
    <div className="">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="popup-title"
        aria-describedby="popup-description"
      >
        <Box className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white px-4  rounded-md shadow-lg w-3/4">
          <h2 className="text-[15px] md:text-lg lg-text-xl text-center">
            Multiple Branch Count
          </h2>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-1 lg:grid-cols-1">
            <div className="flex flex-col gap-4">
              <TextField
                id="numeric-input"
                label="Add Branches Count"
                variant="outlined"
                value={input}
                onChange={handleChange}
                error={error}
                helperText={error ? "Please enter digits only." : ""}
                fullWidth
                inputProps={{ pattern: "[0-9]*", inputMode: "numeric" }}
              />
              <Button
                variant="contained"
                onClick={handleSave}
                sx={{ mt: 2 }}
                disabled={error}
              >
                Add
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Branches;
function onAdd(count: number) {
    throw new Error("Function not implemented.");
}

