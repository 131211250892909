import React, { Component } from 'react'
import 'bootstrap-icons/font/bootstrap-icons.css';
import { HashRouter } from 'react-router-dom';
// import logo from "../../../assets/logo/petmedi-tran.png"
// import cLogo from "../../../assets/logo/HCODE-LOGO.jpg"

export default class Footer extends Component {
  render() {
    return (

      <footer className="py-10 text-white bg-gray-900 ">
        <div className="container flex flex-col items-start justify-between px-16 mx-auto space-y-8 md:flex-row md:space-y-0 xl:px-16">
          <div className="flex flex-col items-center w-full md:w-1/3 md:items-start text-start md:text-left">
            <img src={"https://storage.googleapis.com/pet-medi-staging/web-assets/logo/petmedi-tran.png"} alt="logo" className="order-1 mb-4 lg:w-1/2 md:w-1/2 w-44 md:mt-6 lg:mt-14" />
            <p className="order-2 mb-2 text-white">
              <i className="mr-2 text-blue-600 bi bi-phone"></i>
              <a href="tel:+94 77 714 0372">+94 77 714 0372</a>
            </p>
            <p className="order-3 text-white">
              <i className="mr-2 text-blue-600 bi bi-geo-alt"></i>
              Colombo
            </p>
            <div className='order-10 hidden mt-4 text-center '>
              <small className="text-white opacity-35 ">
                Copyright &copy; 2024 HCode Solutions Pvt. Ltd. All rights reserved.
              </small>
            </div>
          </div>
          <div className="flex flex-col items-center w-full md:w-1/3 md:items-center text-start md:text-left">
            <h2 className="lg:text-xl md:text-[16px] font-bold mb-4 mt-6 lg:mt-16 order-4">Important Links</h2>

            <ul className="order-5 space-y-2 text-center list-none">
              <li>
                <a href="/" className="hover:underline ">
                  Home
                </a>
              </li>
              <li>
                <a href="#/about" className="hover:underline ">
                  About Us
                </a>
              </li>
              {/* <li>
                <a href="pricing" className="hover:underline">
                  Pricing
                </a>
              </li> */}
              <li>
                <a href="#/contact" className="hover:underline ">
                  Contact Us
                </a>
              </li>
              <li>
                <a href="#/quickGuide" className="hover:underline ">
                  Guide
                </a>
              </li>
              <li>
                <a href="#/customer" className="hover:underline ">
                  Our-Customers
                </a>
              </li>
              <li>
                <a href="#/policy" className="hover:underline ">
                  Privacy-Policy
                </a>
              </li>
              <li>
                <a href="#/terms" className="hover:underline ">
                  Terms & Conditions
                </a>
              </li>
              <li>
                <a href="#/blog" className="hover:underline ">
                  Blog
                </a>
              </li>
            </ul>

          </div>

          <div className="flex flex-col items-center w-full text-center md:w-1/3 md:items-end md:text-left">
            <img src={"https://storage.googleapis.com/pet-medi-staging/web-assets/logo/HCODE-LOGO.jpg"} alt="logo" className="order-6 mt-5 mb-4 lg:w-1/2 md:w-1/2 w-44 md:mt-6 lg:mt-14" />
            <p className="flex items-center justify-center order-7 mb-2 text-white md:justify-end">
              <i className="mr-2 text-blue-600 bi bi-phone "></i>
              <a href="tel:+94 77 714 0372">+94 77 764 8785</a>
            </p>
            <p className="flex items-center justify-center order-8 text-white md:justify-end">
              <i className="mr-2 text-blue-600 bi bi-geo-alt"></i>
              Colombo
            </p>
            <div className="flex justify-center order-9 w-full mt-3 md:justify-end">
              <div className="flex space-x-6">
                <a href="https://www.facebook.com/profile.php?id=61567756161199&mibextid=LQQJ4d" target='_blank'><i className="text-blue-600 bi bi-facebook"></i></a>
                <a href="https://instagram.com/petmedi.lk/" target="_blank"><i className="text-blue-600 bi bi-instagram"></i></a>
                <a href="https://www.tiktok.com/@petmedi.lk" target="_blank"><i className="text-blue-600 bi bi-tiktok"></i></a>
                <a href="https://www.youtube.com/@Petmedi-n8b" target="_blank"><i className="text-blue-600 bi bi-youtube"></i></a>

              </div>
            </div>
          </div>


        </div>

        <div className=' md:block lg:block mt-3 text-center md:text-start  w-full md:w-[30%] flex flex-col items-center md:ml-16 lg:ml-16 xl:ml-16'>
          <small className="mt-4 text-white opacity-35">
            Copyright &copy; 2024 HCode Solutions Pvt. Ltd. <br /> All rights reserved.
          </small>
        </div>

      </footer>



    )
  }
}
