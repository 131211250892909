import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
//@ts-ignore
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate, useParams } from "react-router-dom";

import pricingImage from "../../../Assets/pricing/petmedi.jpg"

const baseUrl = process.env.REACT_APP_BASE_URL;

export default function Pricing() {
  const [sriLankanPlans, setSriLankanPlans] = useState([]);
  const [internationalPlans, setInternationalPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedMainTab, setSelectedMainTab] = useState("SRI-LANKA");
  const { id } = useParams<{ id: any }>(); // Capture the URL id
  const planRefs = useRef<(HTMLDivElement | null)[]>([]); // Array of refs for each plan
  const [shouldScroll, setShouldScroll] = useState(false);

   useEffect(() => {
    // Scroll to the top of the page on initial load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  
  useEffect(() => {
    AOS.init({ duration: 1000 });
    AOS.refresh();

    if (id) {
      const planId = parseInt(id, 10);
      const targetTab = planId >= 9 && planId <= 12 ? "SRI-LANKA" : "OTHERS";
      setSelectedMainTab(targetTab);
      fetchPlans(planId); // Fetch plans and set shouldScroll
      setShouldScroll(true); // Set scroll flag to true
    } else {
      fetchPlans();
    }
  }, [id]);

  useEffect(() => {
    AOS.refresh();
  }, [sriLankanPlans, internationalPlans]);

  // Scroll to the right plan card after plans load and `id` is provided
  useEffect(() => {
    if (shouldScroll && id) {
      const planId = parseInt(id, 10);
      const targetTab = planId >= 9 && planId <= 12 ? "SRI-LANKA" : "OTHERS";
      const plansToScroll = targetTab === "SRI-LANKA" ? sriLankanPlans : internationalPlans;
      const planIndex = plansToScroll.findIndex((plan: any) => plan.id === planId);

      if (planIndex !== -1 && planRefs.current[planIndex]) {
        planRefs.current[planIndex]?.scrollIntoView({ behavior: "smooth", block: "center" });
      }

      setShouldScroll(false); // Reset scroll flag to prevent re-scrolling
    }
  }, [sriLankanPlans, internationalPlans, shouldScroll, id]);

  const fetchPlans = async (targetPlanId?: number) => {
    setLoading(true);
    try {
      // Check if plans exist in local storage
      const storedSriLankanPlans = sessionStorage.getItem("sriLankanPlans");
      const storedInternationalPlans = sessionStorage.getItem("internationalPlans");
  
      let sriLankanPlansData;
      let internationalPlansData;
  
      if (storedSriLankanPlans && storedInternationalPlans) {
        // Use data from local storage if available
        sriLankanPlansData = JSON.parse(storedSriLankanPlans);
        internationalPlansData = JSON.parse(storedInternationalPlans);
      } else {
        // Fetch data from the backend if not available in local storage
        const response = await axios.get(`${baseUrl}/webSubPlan`);
    
        sriLankanPlansData = response.data.data["sri-lankan"].map((plan: any) => ({
          ...plan,
          features: plan.selectedFeaturesNameId.map((feature: any) => feature.name),
        }));
    
        internationalPlansData = response.data.data["international"].map((plan: any) => ({
          ...plan,
          features: plan.selectedFeaturesNameId.map((feature: any) => feature.name),
        }));
    
        // Store the plans in local storage
        sessionStorage.setItem("sriLankanPlans", JSON.stringify(sriLankanPlansData));
        sessionStorage.setItem("internationalPlans", JSON.stringify(internationalPlansData));
      }
  
      // Set the state with the data
      setSriLankanPlans(sriLankanPlansData);
      setInternationalPlans(internationalPlansData);
      setLoading(false);
  
      // Check if a plan ID is passed and handle scrolling to the correct card
      if (targetPlanId) {
        const targetTab = targetPlanId >= 9 && targetPlanId <= 12 ? "SRI-LANKA" : "OTHERS";
        setSelectedMainTab(targetTab);
    
        const plansToScroll = targetTab === "SRI-LANKA" ? sriLankanPlansData : internationalPlansData;
        const planIndex = plansToScroll.findIndex((plan: any) => plan.id === targetPlanId);
        if (planIndex !== -1 && planRefs.current[planIndex]) {
          planRefs.current[planIndex]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    } catch (error) {
      //@ts-ignore
      setError(error.message);
      setLoading(false);
    }
  };
  
  

  const handleMainTabClick = (country: string) => {
    setSelectedMainTab(country);
    fetchPlans();
  };

  return (
    <div className="">
      <div className="grid grid-cols-1 md:grid-cols-2 md:h-[70vh] lg:h-screen ">
        <div className="pt-20 md:pt-0 flex items-center justify-center lg:justify-center lg:items-center md:items-start md:mt-20 p-4 lg:p-5">
          <div className="pr-4 lg:pr-12 xl:pr-20">
            <div className="p-4 lg:p-8 xl:p-12">
              <div className="border-0 lg:border-l-2 border-[#1868db] lg:pl-16 text-center lg:text-left md:text-left">
                <h1 className="text-xl lg:text-3xl xl:text-4xl mb-4 font-semibold">
                  <span className="py-4 block">Pet-Medi</span>
                  <span className="text-[#1868db] lg:text-[2.6rem] md:text-[2rem] text-[2.2rem] block ">
                    Pricing
                  </span>
                </h1>
                <p className="mb-4 text-lg lg:text-xl xl:text-2xl">
                  Pet Medi can give details for you about your nearest clinic and you can know their services by accessing their websites.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden items-center justify-center p-4 lg:mr-12 md:mr-8 md:items-start lg:flex md:flex lg:justify-center lg:items-center">
          <img
            src={pricingImage}
            alt="Hero"
            className="object-cover w-full lg:h-auto max-h-100 mt-24 lg:max-h-full"
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
        </div>
      </div>

      <div className="container priceContainer px-4 md:px-12 lg:px-14 xl:px-2 grid max-w-[1440px] mx-auto xl:ml-16">
        {loading ? (
          <div className="flex items-center justify-center">
            <img
              src={"https://storage.googleapis.com/pet-medi-staging/web-assets/loderC.gif"}
              alt="Loader"
            />
          </div>
        ) : error ? (
          <div>Error: {error}</div>
        ) : (
          <div className="mt-12 mb-5">
            <h2 data-aos="fade-up" className="text-2xl font-bold text-center mb-4 text-[#1868db]">
              Pet-Medi Starts In Free
            </h2>
            <div className="flex items-center justify-center mt-1 underline-container"></div>
            <p data-aos="fade-up" className="mb-10 text-center">
              See what's included in PetMedi's pricing packages
            </p>

            {/* Main Tabs */}
            <div data-aos="fade-up" className="flex justify-center mb-4 gap-4">
              <button
                onClick={() => handleMainTabClick("SRI-LANKA")}
                className={`p-2 font-bold ${selectedMainTab === "SRI-LANKA" ? "text-blue-600 border-b-2 border-blue-600" : ""}`}
              >
                SRI LANKA
              </button>
              <div className="border-r-2 border-gray-300 px-2"></div>
              <button
                onClick={() => handleMainTabClick("OTHERS")}
                className={`p-2 font-bold ${selectedMainTab === "OTHERS" ? "text-blue-600 border-b-2 border-blue-600" : ""}`}
              >
                OTHER - REGIONS
              </button>
            </div>

            {/* Pricing Cards */}
            <div>
              <div className="grid justify-center grid-cols gap-2 max-w-6xl mx-auto">
              {(selectedMainTab === "SRI-LANKA" ? sriLankanPlans : internationalPlans).length === 0 ? (
                  <div data-aos="fade-up" className="text-center text-gray-500 mt-4">
                    No subscription plans available
                  </div>
                ) : (
                  (selectedMainTab === "SRI-LANKA" ? sriLankanPlans : internationalPlans).map((plan: any, index: any) => (
                    <div
                      ref={(el) => (planRefs.current[index] = el)}
                      data-aos="zoom-in"
                      data-aos-duration="1200"
                      key={index}
                      id={plan.id}
                      className="flex flex-col justify-between w-full h-full p-4 text-center bg-white border-2 border-gray-200 rounded-3xl gap-y-2 hover:border-blue-500"
                    >
                      <div className="grid grid-col-1 md:grid-cols-3 gap-8">
                        <div className="md:col-span-1">
                          <div className="flex items-center justify-center mb-4">
                            <i className={`bi bi-${plan.icon} text-[#1868db] text-2xl`}></i>
                          </div>
                          <h3 className="uppercase text-3xl font-semibold mb-2 hover:text-[#1868db] text-start">
                            {plan.name}
                          </h3>
                          <p className="mb-2 text-gray-500 text-start line-clamp-3 h-[4.5rem] overflow-hidden">
                            {plan.description}
                          </p>
                          <div className="mb-4 text-gray-600 text-start">
                            <span className="text-4xl font-bold text-gray-800">
                              {plan.price} {plan.currency}
                            </span>
                          </div>
                          <a href="https://petmedi.lk/#/signUp" target="_blank" rel="noreferrer">
                            <button className="capitalize border border-[#0B66E4] text-[#0B66E4] hover:bg-blue-700 hover:text-white w-full font-bold py-2 mt-4 mb-4 rounded mx-auto justify-center items-center">
                            Try {" "}  {plan.name} {" "} Plan
                            </button>
                          </a>
                        </div>

                        {/* Subscription Details */}
                        <div className="md:col-span-2 bg-gray-50 rounded-3xl py-3">
                          {plan.subscriptionDetails.length === 0 ? (
                            <div data-aos="fade-up" className="text-center text-gray-500">
                              No menus available for this subscription.
                            </div>
                          ) : (
                            <ul 
                              // data-aos="fade-up"
                            className="list-disc px-4 space-y-2 mx-7 overflow-y-hidden">
                              {plan.subscriptionDetails.map((menu: any, menuIndex: any) => (
                                <li
                                  data-aos="fade-up"
                                  key={menuIndex}
                                  className="text-[#5E6C84] text-center md:text-left list-none"
                                >
                                  + <span className="font-bold">{menu.value.split("-")[0]}</span>
                                  {menu.value.split("-")[1] && `-${menu.value.split("-")[1]}`}
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
