import React, { Component, createRef } from "react";
// import quick from '../../../assets/guide/PetMediNewNew.jpg'
// import upArrow from '../../../assets/logo/up-arrow .png'
// import logIn from '../../../assets/guide/LogIn.jpg'
// import registration from '../../../assets/guide/Registration.jpg'
// import subPalne from '../../../assets/guide/subPlans.jpg'
// import user from '../../../assets/guide/new user.jpg'
// import addUser from '../../../assets/guide/add user.jpg'
// import GMail from '../../../assets/guide/petmedi-password.jpg'
// import password from '../../../assets/guide/password.jpg'
// import medicine from '../../../assets/guide/t-medicine.jpg'
// import addMedicine from '../../../assets/guide/addMedicine.jpg'
// import Surgery from '../../../assets/guide/t-surgery.jpg'
// import addSurgery from '../../../assets/guide/addSurgery.jpg'
// import Vaccine from '../../../assets/guide/t-vaccine.jpg'
// import addVaccine from '../../../assets/guide/add vaccine.jpg'
// import schedule from '../../../assets/guide/Doctor-shedule.jpg'
import newSchedule from "../../../Assets/guide/doc-she.gif";
// import viewAppointment from '../../../assets/guide/view Appointment.jpg'
// import breed from '../../../assets/guide/P-Breed.png'
// import newBreed from '../../../assets/guide/p-newBreed.png'
// import species from '../../../assets/guide/P-Spacies.png'
// import newSpecies from '../../../assets/guide/p-NewSpacies.png'
// import invoice from '../../../assets/guide/VIewInvoice.png'
// import brand from '../../../assets/guide/stockManagement-Branf.jpg'
// import newBrand from '../../../assets/guide/sm-newBrand.jpg'
// import supplier from '../../../assets/guide/stockManagement-supplier.jpg'
// import newSupplier from '../../../assets/guide/sm-newsupplier.png'
// import item from '../../../assets/guide/stockManagment-item.jpg'
// import newItem from '../../../assets/guide/sm-newItem.jpg'
// import category from '../../../assets/guide/stockManagement-Category.jpg'
// import newCategory from '../../../assets/guide/sm-new catogory.png'
// import batch from '../../../assets/guide/StockManagement-Batch.png'
// import sale from '../../../assets/guide/QuickSale.png'
// import labTest from '../../../assets/guide/t-labTest.jpg'
// import newLabTest from '../../../assets/guide/addNew labTest.jpg'
// import addDoctor from '../../../assets/guide/AddDoctorNew.gif'
import AppintmentPlacement from "../../../Assets/guide/AppointmentPlacement.gif";
// import petRegi from '../../../assets/guide/Pet-Regi.gif'
import placeInvoice from "../../../Assets/guide/invoice.gif";
import DayEnd from "../../../Assets/guide/DayEnd.gif";
import DileveryOption from "../../../Assets/guide/DileviryOptions.png";
import NewDileveryOption from "../../../Assets/guide/NewDileveryOption.png";

import "./guide.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";
//@ts-ignore
import AOS from 'aos';
import 'aos/dist/aos.css';

interface State {
  activeTab: string;
}

export default class QuickGuide extends Component<{}, State> {
  private timer: NodeJS.Timeout | null = null;
  private carouselRef = createRef<HTMLDivElement>();

  state: State = {
    activeTab: "home",
  };

  componentDidMount() {
    this.setState({ activeTab: "Login" });
    this.startAutoChange();
    AOS.init({
      duration: 2000, // Animation duration in milliseconds
    });
  }
  componentDidUpdate() {
    // Reinitialize AOS when the component updates
    AOS.refresh();
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  handleTabClick = (tab: string) => {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.setState({ activeTab: tab });
    this.startAutoChange();
  };

  startAutoChange() {
    this.timer = setInterval(() => {
      const { activeTab } = this.state;
      const tabs = [
        "Login",
        "Registration",
        "User",
        "Treatment",
        "Doctor",
        "Appoinment",
        "Pet",
        "Invoice",
        "Stock",
        "Sale",
        "DayEnd",
        "Delivery",
      ];
      const currentIndex = tabs.indexOf(activeTab);
      const nextIndex = (currentIndex + 1) % tabs.length;
      this.setState({ activeTab: tabs[nextIndex] }, () => {
        this.scrollToActiveTab();
      });
    }, 60000); // 1 minute
  }

  scrollLeft = () => {
    const { activeTab } = this.state;
    const tabs = [
      "Login",
      "Registration",
      "User",
      "Treatment",
      "Doctor",
      "Appoinment",
      "Pet",
      "Invoice",
      "Stock",
      "Sale",
      "DayEnd",
      "Delivery",
    ];
    const currentIndex = tabs.indexOf(activeTab);
    const prevIndex = (currentIndex - 1 + tabs.length) % tabs.length;
    this.setState({ activeTab: tabs[prevIndex] }, () => {
      this.scrollToActiveTab();
    });
  };

  scrollRight = () => {
    const { activeTab } = this.state;
    const tabs = [
      "Login",
      "Registration",
      "User",
      "Treatment",
      "Doctor",
      "Appoinment",
      "Pet",
      "Invoice",
      "Stock",
      "Sale",
      "DayEnd",
      "Delivery",
    ];
    const currentIndex = tabs.indexOf(activeTab);
    const nextIndex = (currentIndex + 1) % tabs.length;
    this.setState({ activeTab: tabs[nextIndex] }, () => {
      this.scrollToActiveTab();
    });
  };

  scrollToActiveTab = () => {
    if (this.carouselRef.current) {
      const { activeTab } = this.state;
      const tabIndex = [
        "Login",
        "Registration",
        "User",
        "Treatment",
        "Doctor",
        "Appoinment",
        "Pet",
        "Invoice",
        "Stock",
        "Sale",
        "DayEnd",
        "Delivery",
      ].indexOf(activeTab);
      const tabWidth = this.carouselRef.current.scrollWidth / 10;
      const scrollAmount = tabIndex * tabWidth;
      this.carouselRef.current.scrollTo({
        left: scrollAmount,
        behavior: "smooth",
      });
    }
  };

  render() {
    const { activeTab } = this.state;

    return (
      <>
        {/* <Helmet>
          <title>Pet Medi Web - Quick-Guide</title>
          <meta
            name="description"
            content="Learn more about Pet Medi, your trusted pet health partner."
          />
          <meta
            name="keywords"
            content="pet, health, care, dogs, cats, veterinary,Pet clinic in Sri Lanka,Veterinary services Sri Lanka,Pet hospital,Affordable pet care Sri Lanka, 
      Veterinarian near me,Animal clinic Sri Lanka,Pet surgery ,Pet vaccinations Sri Lanka,24/7 emergency vet Sri Lanka, Pet health checkup Sri Lanka, Dog clinic Sri Lanka, 
      Cat veterinary care Sri Lanka, Exotic pet care Sri Lanka, Pet dental care Sri Lanka, Pet grooming, Pet boarding services Sri Lanka, Best vet for pet Sri Lanka, 
      Cataract surgery for pets Sri Lanka, Animal rescue services Sri Lanka ,Veterinary clinic , Sri Lanka , Pet Medi, pet, P , Sri Lanka Pets, Pet Care, Animal Clinic, 
      Vet, Pet Health, Pet Health Checkup, veterinary,Pet dental care, Grooming, Best Animal Clinic, Pet health monitoring app, Veterinary telemedicine Sri Lanka,Pet prescription management,
      Pet weight management Sri Lanka,Pet nutrition advice Sri Lanka,Digital veterinary solutions,Pet clinic appointment system,Senior pet care services,Specialized pet care Sri Lanka,
      Online vet consultation Sri Lanka,Pet illness prevention,Remote vet consultations,Pet healthcare software,Preventive vet care Sri Lanka,Pet emergency hotline Sri Lanka,
      Advanced pet diagnostics,Pet care plans Sri Lanka,In-home veterinary services,Pet surgery aftercare Sri Lanka,Pet grooming & spa Sri Lanka,Veterinary lab testing Sri Lanka,
      Personalized pet healthcare plans,Vet treatment packages Sri Lanka,Animal health solutions Sri Lanka,Vet treatment plans Sri Lanka,Pet physiotherapy Sri Lanka, 
      Vets near me, cat clinic near me, dog clinic near me, rabbit consultant doctors, dog consultant doctors, cat consultant doctors, online pet app, pet app online, 
      app for pets, app for animals, animal doctor, pet doctor, pet consultant, Pet wellness programs Sri Lanka, Pet wellness programs, Veterinary nutrition consultation, 
      Pet behavior training Sri Lanka, Pet behavior training, Veterinary dermatology Sri Lanka, Veterinary dermatology, Puppy vaccinations, Puppy vaccinations Sri Lanka, 
      Kitten vaccinations Sri Lanka, Kitten vaccinations, Flea & tick prevention Sri Lanka, Flea & tick prevention, Microchipping services Sri Lanka, Pet allergy treatment Sri Lanka, Pet allergy treatment,
      Veterinary orthopedic surgery Sri Lanka,Veterinary orthopedic surgery, 24-hour vet service Sri Lanka, 24-hour vet service , Emergency pet care Sri Lanka, Emergency pet care,
      Animal eye care Sri Lanka,Animal eye care,Pet dental cleaning Sri Lanka,Pet dental cleaning,Dog vaccinations Sri Lanka, Dog vaccinations,Cat vaccinations Sri Lanka,Cat vaccinations,Animal diagnostic imaging,
      Vet appointment booking online Sri Lanka,Vet appointment booking online,Pet Surgery Sri Lanka, Pet Surgery,Specialist vet services Sri Lanka,Specialist vet services,Pet cardiologist Sri Lanka, Pet cardiologist,
      Best dog vet Sri Lanka,Best dog vet, Best cta vet Sri LankaBest cat vet,Pet health checkup Sri Lanka, Pet health checkup, Puppy health checkup Sri Lanka,Puppy health checkup, Kitten health checkup Sri Lanka,Kitten health checkup,
      Emergency pet clinic near me,Pet health & wellness Sri Lanka,Pet health & wellness "
    />
        </Helmet> */}

        <div>
          <div className="grid grid-cols-1 md:grid-cols-2 md:h-[70vh] lg:h-screen ">
            <div className="order-2 md:order-1 flex items-center justify-center lg:justify-center lg:items-center md:items-start md:mt-20 p-4 lg:p-5">
              <div className="pr-4 lg:pr-12 xl:pr-20">
                <div className="p-4 lg:p-8 xl:p-12">
                  <div className="border-0 lg:border-l-2 border-[#1868db] lg:pl-16 text-center lg:text-left md:text-left">
                    <h1 className="text-xl lg:text-3xl xl:text-4xl mb-4 font-semibold">
                      <span className="py-4 block">If You Need Help</span>
                      <span className="text-[#1868db] lg:text-[2.6rem] md:text-[2rem] text-[2.2rem] block ">
                        Please Follow the
                      </span>
                      <span className="pt-4 block">following Instructions</span>
                    </h1>
                    <button
                      className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-lg transition duration-200 ease-in-out"
                      onClick={() => window.open("https://cms.petmedi.lk", "_blank")}
                    >
                      Try Pet-Medi Free
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="order-1 md:order-2 items-center justify-center p-4 lg:mr-12 md:mr-8 md:items-start lg:flex md:flex lg:justify-center lg:items-center">
              <img
                src={
                  "https://storage.googleapis.com/pet-medi-staging/web-assets/guide/PetMediNewNew.jpg"
                }
                alt="Hero"
                className="object-cover w-full lg:h-auto max-h-100 mt-24 lg:max-h-full"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
              {/* <img
              src={upArrow}
              alt="Up Arrow"
              className="absolute top-0   w-12 h-12 mt-16 right-8 md:right-10 lg:right-12 md:mt-56 md:w-16 md:h-16 lg:mt-36 lg:w-24 lg:h-24"
            /> */}
            </div>
          </div>

          <div className="container lg:px-14 md:px-14 mx-auto ">
            <div className="p-4">
              {/* buttons */}
              <div className="relative">
                <button
                  onClick={this.scrollLeft}
                  className="absolute left-0 top-1/2 transform -translate-y-1/2 p-2 bg-gray-800 bg-opacity-75 rounded-full focus:outline-none hover:bg-opacity-100 text-white shadow-lg lg:hidden"
                >
                  <FontAwesomeIcon icon={faChevronLeft} size="lg" />{" "}
                  {/* Left arrow */}
                </button>
                <div
                  ref={this.carouselRef}
                  className="overflow-x-auto custom-scrollbar py-2"
                >
                  <div className="flex border-none text-end px-8">
                    {[
                      "Login",
                      "Registration",
                      "User",
                      "Treatment",
                      "Doctor",
                      "Appoinment",
                      "Pet",
                      "Invoice",
                      "Stock",
                      "Sale",
                      "DayEnd",
                      "Delivery",
                    ].map((tab) => (
                      <button
                        key={tab}
                        className={`px-4 py-2 focus:outline-none ${activeTab === tab
                            ? "border-b-2 border-blue-500 text-blue-500"
                            : "text-gray-500"
                          }`}
                        onClick={() => this.handleTabClick(tab)}
                      >
                        {tab}
                      </button>
                    ))}
                  </div>
                </div>
                <button
                  onClick={this.scrollRight}
                  className="absolute right-0 top-1/2 transform -translate-y-1/2 p-2 bg-gray-800 bg-opacity-75 rounded-full focus:outline-none hover:bg-opacity-100 text-white shadow-lg lg:hidden"
                >
                  <FontAwesomeIcon icon={faChevronRight} size="lg" />{" "}
                  {/* Right arrow */}
                </button>
              </div>

              {/* tabs */}

              <div className="mt-4 overflow-hidden">
                {/* login */}
                {activeTab === "Login" && (
                  <div>
                    <div className="grid grid-cols-1 md:grid-cols-2 h-screen">
                      <div className="order-2 md:order-1 flex items-start justify-start ">
                        <div className="px-4 lg:pr-12 xl:pr-20">
                          <div className="p-4 lg:p-8 xl:p-12">
                            <div className="lg:text-left md:text-left">
                              <h1 data-aos="fade-right" className="text-xl lg:text-2xl xl:text-3xl mb-4 font-semibold text-center lg:text-start md:text-start">
                                <span className="py-4 ">
                                  Use Your Username & Password To Login
                                </span>
                                <br />
                              </h1>

                              <div className="space-y-4 aboutDetails">
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>Enter E-Mail & Password</span>
                                </div>
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>Click Continue To Login</span>
                                </div>
                                {/* <div className="flex items-center">
                                        <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                        <span>If You Forgot password clik Forgot Pasword & Creat new one</span>
                                      </div> */}
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>
                                    If You Don't have an Account, Click To Create An Account{" "}
                                  </span>
                                </div>
                              </div>
                              <button
                              data-aos="fade-up"
                                className="text-[#1868db] font-bold py-6 px-2 rounded-lg transition duration-200 ease-in-out underline"
                                onClick={() =>
                                  window.open(
                                    "https://cms.petmedi.lk",
                                    "_blank"
                                  )
                                }
                              >
                                Try Pet-Medi Free
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div data-aos="fade-left" className="order-1 md:order-2 items-start justify-start px-4 lg:p-0 lg:flex md:flex">
                        <img
                          src={
                            "https://storage.googleapis.com/pet-medi-staging/web-assets/guide/LogIn.jpg"
                          }
                          alt="Hero"
                          className="object-cover w-full lg:h-auto max-h-80 mt-16 lg:max-h-full drop-shadow-lg"
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {/* Registration */}
                {activeTab === "Registration" && (
                  <div>
                    {/* 1 */}
                    <div className="grid grid-cols-1 md:grid-cols-2 h-screen lg:mb-[-25vh] md:mb-[-40vh] mb-[-10vh]">
                      <div className="order-2 md:order-1 flex items-start justify-start mb-20">
                        <div className="px-4 lg:pr-12 xl:pr-20">
                          <div className="p-4 lg:p-8 xl:p-12">
                            <div className="lg:text-left md:text-left">
                              <h1 data-aos="fade-right" className="text-xl lg:text-2xl xl:text-3xl mb-4 font-semibold">
                                <span className="py-4">
                                  Company Registration
                                </span>
                                <br />
                              </h1>

                              <div className="space-y-4 aboutDetails">
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>Fill The Required Details</span>
                                </div>
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>
                                    Click The Registration Button To Continue
                                  </span>
                                </div>
                                {/* <div className="flex items-center">
                                        <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                        <span>Using E-mail You can Change Your Password</span>
                                      </div>
                                      <div className="flex items-center">
                                        <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                        <span>Then You Can Login Using Your UserName & Password</span>
                                      </div> */}
                              </div>
                              <button
                              data-aos="fade-up"
                                className="text-[#1868db] font-bold py-6 px-2 rounded-lg transition duration-200 ease-in-out underline"
                                onClick={() => window.open("/#/signUp", "_blank")}
                              >
                                Register Here
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div data-aos="fade-left" className="order-1 md:order-2 items-start justify-start px-4 lg:p-0 lg:flex md:flex ">
                        <img
                          src={
                            "https://storage.googleapis.com/pet-medi-staging/web-assets/guide/Registration.jpg"
                          }
                          alt="Hero"
                          className="object-cover w-full lg:h-auto max-h-80 mt-16 lg:max-h-full drop-shadow-lg"
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                      </div>
                    </div>

                    {/* 2 */}
                    <div data-aos="fade-right" className="grid grid-cols-1 md:grid-cols-2 h-screen lg:mb-[-28vh] md:mb-[-50vh] mb-[-22vh] mx-auto">
                      <div className="order-1 md:order-1 items-start justify-start px-4 lg:p-0 lg:flex md:flex mb-[-50vh]">
                        <img
                          src={
                            "https://storage.googleapis.com/pet-medi-staging/web-assets/guide/petmedi-password.jpg"
                          }
                          alt="Hero"
                          className="object-cover w-full lg:h-auto max-h-80 lg:max-h-full drop-shadow-lg"
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                      </div>

                      <div className="order-2 md:order-2 flex items-start justify-start ">
                        <div className="px-4 lg:pr-12 xl:pr-20">
                          <div className="p-4 lg:p-8 xl:p-12">
                            <div className=" lg:text-left md:text-left">
                              {/* <h1 className="text-xl lg:text-2xl xl:text-3xl mb-4 font-semibold">
                                    <span className='py-4'>Registration Your Company</span><br/>
                                    
                                  </h1> */}

                              <div className="space-y-4 aboutDetails">
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>Check your Mail inbox </span>
                                </div>
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>
                                    Use The Mail & Create a Password
                                  </span>
                                </div>
                                {/* <div className="flex items-center">
                                        <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                        <span>Then You Can Navigate to Registraton Page</span>
                                      </div> */}
                                {/* <div className="flex items-center">
                                        <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                        <span>Then You Can Login Using Your UserName & Password</span>
                                      </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* 3 */}
                    <div className="grid grid-cols-1 md:grid-cols-2 h-screen lg:mb-[-28vh] md:mb-[-50vh]  mb-[-22vh]">
                      <div className="order-2 md:order-1 flex items-start justify-start ">
                        <div className="px-4 lg:pr-12 xl:pr-20">
                          <div className="p-4 lg:p-8 xl:p-12">
                            <div className="lg:text-left md:text-left">
                              {/* <h1 className="text-xl lg:text-2xl xl:text-3xl mb-4 font-semibold">
                                    <span className='py-4'>Registration Your Company</span><br/>
                                    
                                  </h1> */}

                              <div className="space-y-4 aboutDetails">
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>Add Your New Password</span>
                                </div>
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>Confirm Your New Password</span>
                                </div>
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>Click Continue</span>
                                </div>
                                {/* <div className="flex items-center">
                                        <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                        <span>Then You Can Login Using Your UserName & Password</span>
                                      </div> */}
                              </div>
                              {/* <button
                                    className="text-[#1868db] font-bold py-6 px-2 rounded-lg transition duration-200 ease-in-out underline"
                                    onClick={() => window.open("/signUp", "_blank")}
                                  >
                                    Registresion Here
                                  </button> */}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div data-aos="fade-left" className="order-1 mb-[-30vh] md:order-2 items-start justify-start px-4 lg:p-0 lg:flex md:flex ">
                        <img
                          src={
                            "https://storage.googleapis.com/pet-medi-staging/web-assets/guide/password.jpg"
                          }
                          alt="Hero"
                          className="object-cover w-full lg:h-auto max-h-80 lg:max-h-full drop-shadow-lg"
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                      </div>
                    </div>

                    {/* 4 */}
                    <div data-aos="fade-right" className="grid grid-cols-1 md:grid-cols-2 h-screen ">
                      <div className="order-1 mb-[-30vh] md:order-1 items-start justify-start px-4 lg:p-0 lg:flex md:flex">
                        <img
                          src={
                            "https://storage.googleapis.com/pet-medi-staging/web-assets/guide/subPlans.jpg"
                          }
                          alt="Hero"
                          className="object-cover w-full lg:h-auto max-h-80 lg:max-h-full drop-shadow-lg"
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                      </div>

                      <div className="order-2 md:order-2 flex items-start justify-start ">
                        <div className="px-4 lg:pr-12 xl:pr-20">
                          <div className="p-4 lg:p-8 xl:p-12">
                            <div className="lg:text-left md:text-left">
                              {/* <h1 className="text-xl lg:text-2xl xl:text-3xl mb-4 font-semibold">
                                    <span className='py-4'>Registration Your Company</span><br/>
                                    
                                  </h1> */}

                              <div className="space-y-4 aboutDetails">
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>Choose Your Plan</span>
                                </div>
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>Click the Button On Your Subscription Plan</span>
                                </div>
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>Then You Can Registration your Company</span>
                                </div>
                                {/* <div className="flex items-center">
                                        <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                        <span>Then You Can Login Using Your UserName & Password</span>
                                      </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* user */}
                {activeTab === "User" && (
                  <div>
                    {/* 1 */}
                    <div className="grid grid-cols-1 md:grid-cols-2 h-screen lg:mb-[-28vh] md:mb-[-50vh] mb-[-10vh]">
                      <div className="order-2 md:order-1 flex items-start justify-start  ">
                        <div className="px-4 lg:pr-12 xl:pr-20">
                          <div className="p-4 lg:p-8 xl:p-12">
                            <div className="lg:text-left md:text-left">
                              <h1 data-aos="fade-right" className="text-xl lg:text-2xl xl:text-3xl mb-4 font-semibold">
                                <span className="py-4">Add User</span>
                                <br />
                              </h1>

                              <div className="space-y-4 aboutDetails">
                                {/* <div className="flex items-center">
                                        <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                        <span>If You want to find Some One You can search Advance way & normal way </span>
                                      </div> */}
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>
                                    Click on New User To Add New User
                                  </span>
                                </div>
                                {/* <div className="flex items-center">
                                        <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                        <span>Using E-mail You can Change Your Password</span>
                                      </div>
                                      <div className="flex items-center">
                                        <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                        <span>Then You Can Login Using Your UserName & Password</span>
                                      </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div data-aos="fade-left" className="order-1 md:order-2 items-start justify-start px-4 lg:p-0 lg:flex md:flex mb-[-20vh]">
                        <img
                          src={
                            "https://storage.googleapis.com/pet-medi-staging/web-assets/guide/add%20user.jpg"
                          }
                          alt="Hero"
                          className="object-cover w-full lg:h-auto max-h-80 mt-16 lg:max-h-full drop-shadow-lg"
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                      </div>
                    </div>

                    {/* 2 */}
                    <div data-aos="fade-right" className="grid grid-cols-1 md:grid-cols-2 h-screen lg:mb-[-28vh] md:mb-[-50vh]">
                      <div className="order-1  md:order-1 items-start justify-start px-4 lg:p-0 lg:flex md:flex  ">
                        <img
                          src={
                            "https://storage.googleapis.com/pet-medi-staging/web-assets/guide/new%20user.jpg"
                          }
                          alt="Hero"
                          className="object-cover w-full lg:h-auto max-h-80 lg:max-h-full drop-shadow-lg "
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                      </div>

                      <div className="order-2 mt-[-30vh] md:mt-0 lg:mt-[-5vh] md:order-2 flex items-start justify-start ">
                        <div className="px-4 lg:pr-12 xl:pr-20">
                          <div className="p-4 lg:p-8 xl:p-12">
                            <div className="lg:text-left md:text-left">
                              <h1 data-aos="fade-left" className="text-xl lg:text-2xl xl:text-3xl mb-4 font-semibold">
                                <span className="py-4">Add New User</span>
                                <br />
                              </h1>

                              <div className="space-y-4 aboutDetails">
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>Fill The Required Details</span>
                                </div>
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>Click on Save To Continue</span>
                                </div>
                                {/* <div className="flex items-center">
                                        <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                        <span>Then You Can Navigate to Registraton Page</span>
                                      </div> */}
                                {/* <div className="flex items-center">
                                        <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                        <span>Then You Can Login Using Your UserName & Password</span>
                                      </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* Treatment Management */}
                {activeTab === "Treatment" && (
                  <div>
                    {/*  Medicine */}
                    {/* 1 */}
                    <div className="grid grid-cols-1 md:grid-cols-2 h-screen lg:mb-[-28vh] md:mb-[-50vh] mb-[-20vh]">
                      <div className="order-2 md:order-1 flex items-start justify-start ">
                        <div className="px-4 lg:pr-12 xl:pr-20">
                          <div className="p-4 lg:p-8 xl:p-12">
                            <div className="lg:text-left md:text-left">
                              <h1 data-aos="fade-right" className="text-xl lg:text-2xl xl:text-3xl mb-4 font-semibold">
                                <span className="py-4">Add Medicine</span>
                                <br />
                              </h1>

                              <div className="space-y-4 aboutDetails">
                                {/* <div className="flex items-center">
                                        <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                        <span>If You want to find Some Thing You can search Advance way & normal way </span>
                                      </div> */}
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>
                                    Click on New Medicine To Add New Medicine
                                  </span>
                                </div>
                                {/* <div className="flex items-center">
                                        <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                        <span>Using E-mail You can Change Your Password</span>
                                      </div>
                                      <div className="flex items-center">
                                        <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                        <span>Then You Can Login Using Your UserName & Password</span>
                                      </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div data-aos="fade-left" className="order-1 mb-[-20vh] md:order-2 items-start justify-start px-4 lg:p-0 lg:flex md:flex">
                        <img
                          src={
                            "https://storage.googleapis.com/pet-medi-staging/web-assets/guide/t-medicine.jpg"
                          }
                          alt="Hero"
                          className="object-cover w-full lg:h-auto max-h-80 mt-16 lg:max-h-full drop-shadow-lg"
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                      </div>
                    </div>

                    {/* 2 */}
                    <div data-aos="fade-right" className="grid grid-cols-1 md:grid-cols-2 h-screen lg:mb-[-28vh] md:mb-[-50vh] mb-[-40vh]">
                      <div className="order-1 mb-[-20vh] md:order-1 items-start justify-start px-4 lg:p-0 lg:flex md:flex">
                        <img
                          src={
                            "https://storage.googleapis.com/pet-medi-staging/web-assets/guide/addMedicine.jpg"
                          }
                          alt="Hero"
                          className="object-cover w-full lg:h-auto max-h-80 lg:max-h-full drop-shadow-lg"
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                      </div>

                      <div className="order-2 mt-[-20vh] md:mt-0 lg:mt-[-5vh] md:order-2 flex items-start justify-start ">
                        <div className="px-4 lg:pr-12 xl:pr-20">
                          <div className="p-4 lg:p-8 xl:p-12">
                            <div className="lg:text-left md:text-left">
                              <h1 data-aos="fade-left" className="text-xl lg:text-2xl xl:text-3xl mb-4 font-semibold">
                                <span className="py-4">Add New Medicine</span>
                                <br />
                              </h1>

                              <div className="space-y-4 aboutDetails">
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>Fill The Required Details</span>
                                </div>
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>Click on Save To Continue</span>
                                </div>
                                {/* <div className="flex items-center">
                                        <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                        <span>Then You Can Navigate to Registraton Page</span>
                                      </div> */}
                                {/* <div className="flex items-center">
                                        <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                        <span>Then You Can Login Using Your UserName & Password</span>
                                      </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Surgery */}

                    <div className="grid grid-cols-1 md:grid-cols-2 h-screen lg:mb-[-28vh] md:mb-[-50vh] mb-[-10vh]">
                      <div className="order-2 mt-[-10vh] md:mt-0 lg:mt-0 md:order-1 flex items-start justify-start ">
                        <div className="px-4 lg:pr-12 xl:pr-20">
                          <div className="p-4 lg:p-8 xl:p-12">
                            <div className="lg:text-left md:text-left">
                              <h1 data-aos="fade-right" className="text-xl lg:text-2xl xl:text-3xl mb-4 font-semibold">
                                <span className="py-4">Add Surgery</span>
                                <br />
                              </h1>

                              <div className="space-y-4 aboutDetails">
                                {/* <div className="flex items-center">
                                        <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                        <span>If You want to find Some Thing You can search Advance way & normal way </span>
                                      </div> */}
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>
                                    Click on New Surgery To Add New Surgery
                                  </span>
                                </div>
                                {/* <div className="flex items-center">
                                        <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                        <span>Using E-mail You can Change Your Password</span>
                                      </div>
                                      <div className="flex items-center">
                                        <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                        <span>Then You Can Login Using Your UserName & Password</span>
                                      </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div data-aos="fade-left" className="order-1 md:order-2 items-start justify-start px-4 lg:p-0 lg:flex md:flex">
                        <img
                          src={
                            "https://storage.googleapis.com/pet-medi-staging/web-assets/guide/t-surgery.jpg"
                          }
                          alt="Hero"
                          className="object-cover w-full lg:h-auto max-h-80 mt-16 lg:max-h-full drop-shadow-lg"
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                      </div>
                    </div>

                    {/* 2 */}
                    <div data-aos="fade-right" className="grid grid-cols-1 md:grid-cols-2 h-screen lg:mb-[-28vh] md:mb-[-50vh] mb-[-40vh]">
                      <div className="order-1  md:order-1 items-start justify-start px-4 lg:p-0 lg:flex md:flex">
                        <img
                          src={
                            "https://storage.googleapis.com/pet-medi-staging/web-assets/guide/addSurgery.jpg"
                          }
                          alt="Hero"
                          className="object-cover w-full lg:h-auto max-h-80 lg:max-h-full drop-shadow-lg"
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                      </div>

                      <div className="order-2 mt-[-30vh] md:mt-0 lg:mt-[-5vh] md:order-2 flex items-start justify-start ">
                        <div className="px-4 lg:pr-12 xl:pr-20">
                          <div className="p-4 lg:p-8 xl:p-12">
                            <div className="lg:text-left md:text-left">
                              <h1 data-aos="fade-left" className="text-xl lg:text-2xl xl:text-3xl mb-4 font-semibold">
                                <span className="py-4">Add New Surgery</span>
                                <br />
                              </h1>

                              <div className="space-y-4 aboutDetails">
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>Fill The Required Details</span>
                                </div>
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>Click on Save To Continue</span>
                                </div>
                                {/* <div className="flex items-center">
                                        <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                        <span>Then You Can Navigate to Registraton Page</span>
                                      </div> */}
                                {/* <div className="flex items-center">
                                        <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                        <span>Then You Can Login Using Your UserName & Password</span>
                                      </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Vaccine */}
                    {/* 1 */}
                    <div className="grid grid-cols-1 md:grid-cols-2 h-screen lg:mb-[-28vh] md:mb-[-50vh] mb-[-10vh]">
                      <div className="order-2 mt-[-10vh] md:mt-0 lg:mt-0 md:order-1 flex items-start justify-start ">
                        <div className="px-4 lg:pr-12 xl:pr-20">
                          <div className="p-4 lg:p-8 xl:p-12">
                            <div className="lg:text-left md:text-left">
                              <h1 data-aos="fade-right" className="text-xl lg:text-2xl xl:text-3xl mb-4 font-semibold">
                                <span className="py-4">Add Vaccine</span>
                                <br />
                              </h1>

                              <div className="space-y-4 aboutDetails">
                                {/* <div className="flex items-center">
                                        <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                        <span>If You want to find Some Thing You can search Advance way & normal way </span>
                                      </div> */}
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>
                                    Click on New Vaccine To Add New Vaccine
                                  </span>
                                </div>
                                {/* <div className="flex items-center">
                                        <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                        <span>Using E-mail You can Change Your Password</span>
                                      </div>
                                      <div className="flex items-center">
                                        <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                        <span>Then You Can Login Using Your UserName & Password</span>
                                      </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div data-aos="fade-left" className="order-1 md:order-2 items-start justify-start px-4 lg:p-0 lg:flex md:flex">
                        <img
                          src={
                            "https://storage.googleapis.com/pet-medi-staging/web-assets/guide/t-vaccine.jpg"
                          }
                          alt="Hero"
                          className="object-cover w-full lg:h-auto max-h-80 mt-16 lg:max-h-full drop-shadow-lg"
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                      </div>
                    </div>

                    {/* 2 */}
                    <div data-aos="fade-right" className="grid grid-cols-1 md:grid-cols-2 h-screen lg:mb-[-28vh] md:mb-[-50vh] mb-[-40vh]">
                      <div className="order-1  md:order-1 items-start justify-start px-4 lg:p-0 lg:flex md:flex">
                        <img
                          src={
                            "https://storage.googleapis.com/pet-medi-staging/web-assets/guide/add%20vaccine.jpg"
                          }
                          alt="Hero"
                          className="object-cover w-full lg:h-auto max-h-80 lg:max-h-full drop-shadow-lg"
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                      </div>

                      <div className="order-2 mt-[-30vh] md:mt-0 lg:mt-[-5vh] md:order-2 flex items-start justify-start ">
                        <div className="px-4 lg:pr-12 xl:pr-20">
                          <div className="p-4 lg:p-8 xl:p-12">
                            <div className="lg:text-left md:text-left">
                              <h1 data-aos="fade-left" className="text-xl lg:text-2xl xl:text-3xl mb-4 font-semibold">
                                <span className="py-4">Add New Vaccine</span>
                                <br />
                              </h1>

                              <div className="space-y-4 aboutDetails">
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>Fill The Required Details</span>
                                </div>
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>Click on Save To Continue</span>
                                </div>
                                {/* <div className="flex items-center">
                                        <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                        <span>Then You Can Navigate to Registraton Page</span>
                                      </div> */}
                                {/* <div className="flex items-center">
                                        <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                        <span>Then You Can Login Using Your UserName & Password</span>
                                      </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/*  Lab Test */}
                    {/* 1 */}
                    <div className="grid grid-cols-1 md:grid-cols-2 h-screen lg:mb-[-28vh] md:mb-[-50vh] ">
                      <div className="order-2 md:order-1 flex items-start justify-start ">
                        <div className="px-4 lg:pr-12 xl:pr-20">
                          <div className="p-4 lg:p-8 xl:p-12">
                            <div className=" lg:text-left md:text-left">
                              <h1 data-aos="fade-right" className="text-xl lg:text-2xl xl:text-3xl mb-4 font-semibold">
                                <span className="py-4">Lab Test</span>
                                <br />
                              </h1>

                              <div className="space-y-4 aboutDetails">
                                {/* <div className="flex items-center">
                                        <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                        <span>If You want to find Some Thing You can search Advance way & normal way </span>
                                      </div> */}
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>Click on New LabTest To Add A New Lab Test</span>
                                </div>
                                {/* <div className="flex items-center">
                                        <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                        <span>Using E-mail You can Change Your Password</span>
                                      </div>
                                      <div className="flex items-center">
                                        <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                        <span>Then You Can Login Using Your UserName & Password</span>
                                      </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div data-aos="fade-left" className="order-1 md:order-2 items-start justify-start px-4 lg:p-0 lg:flex md:flex">
                        <img
                          src={
                            "https://storage.googleapis.com/pet-medi-staging/web-assets/guide/t-labTest.jpg"
                          }
                          alt="Hero"
                          className="object-cover w-full lg:h-auto max-h-80 mt-16 lg:max-h-full drop-shadow-lg"
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                      </div>
                    </div>

                    {/* 2 */}
                    <div data-aos="fade-right" className="grid grid-cols-1 md:grid-cols-2 h-screen lg:mb-[-28vh] md:mb-[-50vh] ">
                      <div className="order-1 md:order-1 items-start justify-start px-4 lg:p-0 lg:flex md:flex">
                        <img
                          src={
                            "https://storage.googleapis.com/pet-medi-staging/web-assets/guide/addNew%20labTest.jpg"
                          }
                          alt="Hero"
                          className="object-cover w-full lg:h-auto max-h-80 lg:max-h-full drop-shadow-lg"
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                      </div>

                      <div className="order-2 mt-[-30vh] md:mt-0 lg:mt-[-5vh] md:order-2 flex items-start justify-start ">
                        <div className="px-4 lg:pr-12 xl:pr-20">
                          <div className="p-4 lg:p-8 xl:p-12">
                            <div className="lg:text-left md:text-left">
                              <h1 data-aos="fade-left" className="text-xl lg:text-2xl xl:text-3xl mb-4 font-semibold">
                                <span className="py-4">Add New Lab Test</span>
                                <br />
                              </h1>

                              <div className="space-y-4 aboutDetails">
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>Fill The Required Details</span>
                                </div>
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>Click on Save To Continue</span>
                                </div>
                                {/* <div className="flex items-center">
                                        <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                        <span>Then You Can Navigate to Registraton Page</span>
                                      </div> */}
                                {/* <div className="flex items-center">
                                        <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                        <span>Then You Can Login Using Your UserName & Password</span>
                                      </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* Doctor Management */}

                {activeTab === "Doctor" && (
                  <div>
                    {/*  Schedule */}
                    {/* 1 */}
                    <div className="grid grid-cols-1 md:grid-cols-2 h-screen lg:mb-[-28vh] md:mb-[-50vh] mb-[-10vh]">
                      <div className="order-2 md:order-1 flex items-start justify-start ">
                        <div className="px-4 lg:pr-12 xl:pr-20">
                          <div className="p-4 lg:p-8 xl:p-12">
                            <div className="lg:text-left md:text-left">
                              <h1 data-aos="fade-right" className="text-xl lg:text-2xl xl:text-3xl mb-4 font-semibold">
                                <span className="py-4">Add Schedule</span>
                                <br />
                              </h1>

                              <div className="space-y-4 aboutDetails">
                                {/* <div className="flex items-center">
                                              <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                              <span>If You want to find Some Thing You can search Advance way & normal way </span>
                                            </div> */}
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>Click on New Schedule To Add New Schedule</span>
                                </div>
                                {/* <div className="flex items-center">
                                              <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                              <span>Using E-mail You can Change Your Password</span>
                                            </div>
                                            <div className="flex items-center">
                                              <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                              <span>Then You Can Login Using Your UserName & Password</span>
                                            </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div data-aos="fade-left" className="order-1 mb-[-10vh] md:order-2 items-start justify-start px-4 lg:p-0 lg:flex md:flex">
                        <img
                          src={
                            "https://storage.googleapis.com/pet-medi-staging/web-assets/guide/Doctor-shedule.jpg"
                          }
                          alt="Hero"
                          className="object-cover w-full lg:h-auto max-h-80 mt-16 lg:max-h-full drop-shadow-lg"
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                      </div>
                    </div>

                    {/* 2 */}
                    <div data-aos="fade-right" className="grid grid-cols-1 md:grid-cols-2 h-screen lg:mb-[-28vh] md:mb-[-50vh] mb-[-20vh]">
                      <div className="order-1 mb-[-10vh] md:order-1 items-start justify-start px-4 lg:p-0 lg:flex md:flex">
                        <video
                          src={newSchedule}
                          controls
                          className="object-cover w-full lg:h-auto max-h-80 mt-16 lg:max-h-full drop-shadow-lg"
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        ></video>
                      </div>

                      <div className="order-2 md:order-2 flex items-start justify-start ">
                        <div className="px-4 lg:pr-12 xl:pr-20">
                          <div className="p-4 lg:p-8 xl:p-12">
                            <div className="lg:text-left md:text-left">
                              <h1 data-aos="fade-left" className="text-xl lg:text-2xl xl:text-3xl mb-4 font-semibold">
                                <span className="py-4">Add New Schedule</span>
                                <br />
                              </h1>

                              <div className="space-y-4 aboutDetails">
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>
                                    You Can Search The Doctor's Name Easily.
                                  </span>
                                </div>
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>
                                    Click the new schedule to Add a Doctor
                                    schedule
                                  </span>
                                </div>
                                {/* <div className="flex items-center">
                                              <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                              <span>If You Don't Want It Click Cansel</span>
                                            </div> */}
                                {/* <div className="flex items-center">
                                              <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                              <span>Then You Can Login Using Your UserName & Password</span>
                                            </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Add Doctor */}
                    {/* 1 */}
                    <div className="grid grid-cols-1 md:grid-cols-2 h-screen lg:mb-[-28vh] md:mb-[-50vh]">
                      <div className="order-2 md:order-1 flex items-start justify-start ">
                        <div className="px-4 lg:pr-12 xl:pr-20">
                          <div className="p-4 lg:p-8 xl:p-12">
                            <div className="lg:text-left md:text-left">
                              <h1 data-aos="fade-right" className="text-xl lg:text-2xl xl:text-3xl mb-4 font-semibold">
                                <span className="py-4">Add Doctor</span>
                                <br />
                              </h1>

                              <div className="space-y-4 aboutDetails">
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>Fill The Required Details</span>
                                </div>
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>Click on Next To Continue</span>
                                </div>
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>Select Service & Add Fee</span>
                                </div>
                                {/* <div className="flex items-center">
                                              <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                              <span>Then You Can Login Using Your UserName & Password</span>
                                            </div>  */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div data-aos="fade-left" className="order-1 md:order-2 items-start justify-start px-4 lg:p-0 lg:flex md:flex">
                        <video
                          src={
                            "https://storage.googleapis.com/pet-medi-staging/web-assets/guide/AddDoctorNew.gif"
                          }
                          controls
                          className="object-cover w-full lg:h-auto max-h-80 mt-16 lg:max-h-full drop-shadow-lg"
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        ></video>
                      </div>
                    </div>
                  </div>
                )}

                {/* Appointment Management */}
                {activeTab === "Appoinment" && (
                  <div>
                    {/*  View Appoinment */}
                    {/* 1 */}
                    <div className="grid grid-cols-1 md:grid-cols-2 h-screen lg:mb-[-28vh] md:mb-[-50vh] mb-[-10vh]">
                      <div className="order-2  md:order-1 flex items-start justify-start ">
                        <div className="px-4 lg:pr-12 xl:pr-20">
                          <div className="p-4 lg:p-8 xl:p-12">
                            <div className="lg:text-left md:text-left">
                              <h1 data-aos="fade-right" className="text-xl lg:text-2xl xl:text-3xl mb-4 font-semibold">
                                <span className="py-4">View Appointment</span>
                                <br />
                              </h1>

                              <div className="space-y-4 aboutDetails">
                                {/* <div className="flex items-center">
                                            <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                            <span>If You want to find Some Thing You can search Advance way & normal way </span>
                                          </div> */}
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>
                                    You Can See The Pending, Cancelled & Paid
                                    Appointments
                                  </span>
                                </div>
                                {/* <div className="flex items-center">
                                            <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                            <span>Using E-mail You can Change Your Password</span>
                                          </div>
                                          <div className="flex items-center">
                                            <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                            <span>Then You Can Login Using Your UserName & Password</span>
                                          </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div data-aos="fade-left" className="order-1 mb-[-10vh] md:order-2 items-start justify-start px-4 lg:p-0 lg:flex md:flex">
                        <img
                          src={
                            "https://storage.googleapis.com/pet-medi-staging/web-assets/guide/view%20Appointment.jpg"
                          }
                          alt="Hero"
                          className="object-cover w-full lg:h-auto max-h-80 mt-16 lg:max-h-full drop-shadow-lg"
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                      </div>
                    </div>

                    {/* 2 Appoinment Placement */}
                    <div data-aos="fade-right" className="grid grid-cols-1 md:grid-cols-2 h-screen lg:mb-[-28vh] md:mb-[-50vh]">
                      <div className="order-1 md:order-1 items-start justify-start px-4 lg:p-0 lg:flex md:flex">
                        <video
                          src={AppintmentPlacement}
                          controls
                          className="object-cover w-full lg:h-auto max-h-80 mt-16 lg:max-h-full drop-shadow-lg"
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        ></video>
                      </div>

                      <div className="order-2 md:order-2 flex items-start justify-start ">
                        <div className="px-4 lg:pr-12 xl:pr-20">
                          <div className="p-4 lg:p-8 xl:p-12">
                            <div className="lg:text-left md:text-left">
                              <h1 data-aos="fade-left" className="text-xl lg:text-2xl xl:text-3xl mb-4 font-semibold">
                                <span className="py-4">
                                  Appointment Placement
                                </span>
                                <br />
                              </h1>

                              <div className="space-y-4 aboutDetails">
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>
                                    Click on the SearchBar & Add your Pet Code or
                                   Enter your Mobile number
                                  </span>
                                </div>
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>
                                    Click Enter & Now You can Select Your
                                    Doctor
                                  </span>
                                </div>
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>
                                    If You haven't Add your Pet Details before You can
                                    Click on New Pet & Add Your Pet Details
                                  </span>
                                </div>
                                {/* <div className="flex items-center">
                                            <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                            <span>Then You Can Login Using Your UserName & Password</span>
                                          </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* Pet Management */}
                {activeTab === "Pet" && (
                  <div>
                    {/*  Pet Registration */}
                    {/* 1 */}
                    <div className="grid grid-cols-1 md:grid-cols-2 h-screen lg:mb-[-28vh] md:mb-[-50vh] mb-[-15vh]">
                      <div className="order-2 md:order-1 flex items-start justify-start ">
                        <div className="px-4 lg:pr-12 xl:pr-20">
                          <div className="p-4 lg:p-8 xl:p-12">
                            <div className="lg:text-left md:text-left">
                              <h1 data-aos="fade-right" className="text-xl lg:text-2xl xl:text-3xl mb-4 font-semibold">
                                <span className="py-4">Pet Registration</span>
                                <br />
                              </h1>

                              <div className="space-y-4 aboutDetails">
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>Click on New Pet To Add New Pet</span>
                                </div>
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>
                                    Fill The Required Details & Click on Fill Pet
                                    Details To Continue
                                  </span>
                                </div>
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>Fill Pet Details & Click on Save</span>
                                </div>
                                {/* <div className="flex items-center">
                                            <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                            <span>Then You Can Login Using Your UserName & Password</span>
                                          </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div data-aos="fade-left" className="order-1 mb-[-10vh] md:order-2 items-start justify-start px-4 lg:p-0 lg:flex md:flex">
                        <video
                          src={
                            "https://storage.googleapis.com/pet-medi-staging/web-assets/guide/Pet-Regi.gif"
                          }
                          controls
                          className="object-cover w-full lg:h-auto max-h-80 mt-16 lg:max-h-full drop-shadow-lg"
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        ></video>
                      </div>
                    </div>

                    {/* Breed */}
                    <div data-aos="fade-right" className="grid grid-cols-1 md:grid-cols-2 h-screen lg:mb-[-28vh] md:mb-[-50vh] mb-[-15vh]">
                      <div className="order-1 mb-[-10vh] md:order-1 items-start justify-start px-4 lg:p-0 lg:flex md:flex">
                        <img
                          src={
                            "https://storage.googleapis.com/pet-medi-staging/web-assets/guide/P-Breed.png"
                          }
                          alt="Hero"
                          className="object-cover w-full lg:h-auto max-h-80 mt-16 lg:max-h-full drop-shadow-lg"
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                      </div>

                      <div className="order-2 md:order-2 flex items-start justify-start ">
                        <div className="px-4 lg:pr-12 xl:pr-20">
                          <div className="p-4 lg:p-8 xl:p-12">
                            <div className="lg:text-left md:text-left">
                              <h1 data-aos="fade-left" className="text-xl lg:text-2xl xl:text-3xl mb-4 font-semibold">
                                <span className="py-4">Breed</span>
                                <br />
                              </h1>

                              <div className="space-y-4 aboutDetails">
                                {/* <div className="flex items-center">
                                            <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                            <span>If You want to find Some Thing You can search Advance way & normal way</span>
                                          </div> */}
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>Click on New Breed To Add New Breed</span>
                                </div>
                                {/* <div className="flex items-center">
                                            <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                            <span>Click Save To Save Details</span>
                                          </div> */}
                                {/* <div className="flex items-center">
                                            <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                            <span>Then You Can Login Using Your UserName & Password</span>
                                          </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* New Breed */}
                    <div className="grid grid-cols-1 md:grid-cols-2 h-screen lg:mb-[-25vh] md:mb-[-50vh] mb-[-20vh]">
                      <div className="order-2 md:order-1 flex items-start justify-start ">
                        <div className="px-4 lg:pr-12 xl:pr-20">
                          <div className="p-4 lg:p-8 xl:p-12">
                            <div className="lg:text-left md:text-left">
                              <h1 data-aos="fade-right" className="text-xl lg:text-2xl xl:text-3xl mb-4 font-semibold">
                                <span className="py-4">Add Breed</span>
                                <br />
                              </h1>

                              <div className="space-y-4 aboutDetails">
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>Fill The Required Details</span>
                                </div>
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>Click on Save To Save the New Breed</span>
                                </div>
                                {/* <div className="flex items-center">
                                        <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                        <span>Using E-mail You can Change Your Password</span>
                                      </div>
                                      <div className="flex items-center">
                                        <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                        <span>Then You Can Login Using Your UserName & Password</span>
                                      </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div data-aos="fade-left" className="order-1 mb-[-20vh] md:order-2 items-start justify-start px-4 lg:p-0 lg:flex md:flex">
                        <img
                          src={
                            "https://storage.googleapis.com/pet-medi-staging/web-assets/guide/p-newBreed.png"
                          }
                          alt="Hero"
                          className="object-cover w-full lg:h-auto max-h-80 mt-16 lg:max-h-full drop-shadow-lg"
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                      </div>
                    </div>

                    {/* Species */}
                    <div data-aos="fade-right" className="grid grid-cols-1 md:grid-cols-2 h-screen lg:mb-[-28vh] md:mb-[-50vh] mb-[-20vh]">
                      <div className="order-1  md:order-1 items-start justify-start px-4 lg:p-0 lg:flex md:flex">
                        <img
                          src={
                            "https://storage.googleapis.com/pet-medi-staging/web-assets/guide/P-Spacies.png"
                          }
                          alt="Hero"
                          className="object-cover w-full lg:h-auto max-h-80 lg:max-h-full drop-shadow-lg"
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                      </div>

                      <div className="order-2 mt-[-20vh] md:mt-0 lg:mt-[-5vh] md:order-2 flex items-start justify-start ">
                        <div className="px-4 lg:pr-12 xl:pr-20">
                          <div className="p-4 lg:p-8 xl:p-12">
                            <div className=" lg:text-left md:text-left">
                              <h1 data-aos="fade-left" className="text-xl lg:text-2xl xl:text-3xl mb-4 font-semibold">
                                <span className="py-4">Species</span>
                                <br />
                              </h1>

                              <div className="space-y-4 aboutDetails">
                                {/* <div className="flex items-center">
                                        <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                        <span>If You want to find Some Thing You can search Advance way & normal way</span>
                                      </div> */}
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>
                                    Click on New Species To Add New One
                                  </span>
                                </div>
                                {/* <div className="flex items-center">
                                        <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                        <span>Then You Can Navigate to Registraton Page</span>
                                      </div> */}
                                {/* <div className="flex items-center">
                                        <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                        <span>Then You Can Login Using Your UserName & Password</span>
                                      </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* New Species */}
                    <div className="grid grid-cols-1 md:grid-cols-2 h-screen lg:mb-[-28vh] md:mb-[-50vh] ">
                      <div className="order-2 mt-[-20vh] md:mt-0 lg:mt-0 md:order-1 flex items-start justify-start ">
                        <div className="px-4 lg:pr-12 xl:pr-20">
                          <div className="p-4 lg:p-8 xl:p-12">
                            <div className="lg:text-left md:text-left">
                              <h1 data-aos="fade-right" className="text-xl lg:text-2xl xl:text-3xl mb-4 font-semibold">
                                <span className="py-4">Add Species</span>
                                <br />
                              </h1>

                              <div className="space-y-4 aboutDetails">
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>Fill The Required Details</span>
                                </div>
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2 "></i>
                                  <span>
                                    Click on Save to Save Species
                                  </span>
                                </div>
                                {/* <div className="flex items-center">
                                        <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                        <span>Using E-mail You can Change Your Password</span>
                                      </div>
                                      <div className="flex items-center">
                                        <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                        <span>Then You Can Login Using Your UserName & Password</span>
                                      </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div data-aos="fade-left" className="order-1  md:order-2 items-start justify-start px-4 lg:p-0 lg:flex md:flex">
                        <img
                          src={
                            "https://storage.googleapis.com/pet-medi-staging/web-assets/guide/p-NewSpacies.png"
                          }
                          alt="Hero"
                          className="object-cover w-full lg:h-auto max-h-80 mt-16 lg:max-h-full drop-shadow-lg"
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {/* Invoice Management */}
                {activeTab === "Invoice" && (
                  <div>
                    {/* View Invoice */}
                    <div className="grid grid-cols-1 md:grid-cols-2 h-screen lg:mb-[-28vh] md:mb-[-50vh] mb-[-20vh]">
                      <div className="order-2 mt-[-20vh] md:mt-0 lg:mt-0 md:order-1 flex items-start justify-start ">
                        <div className="px-4 lg:pr-12 xl:pr-20">
                          <div className="p-4 lg:p-8 xl:p-12">
                            <div className="lg:text-left md:text-left">
                              <h1 data-aos="fade-right" className="text-xl lg:text-2xl xl:text-3xl mb-4 font-semibold">
                                <span className="py-4">View Invoice</span>
                                <br />
                              </h1>

                              <div className="space-y-4 aboutDetails">
                                {/* <div className="flex items-center">
                                        <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                        <span>If You want to find Some One You can search Advance way & normal way </span>
                                      </div> */}
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>
                                    You Can Check Pending & Paid Invoices
                                  </span>
                                </div>
                                {/* <div className="flex items-center">
                                        <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                        <span>Using E-mail You can Change Your Password</span>
                                      </div>
                                      <div className="flex items-center">
                                        <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                        <span>Then You Can Login Using Your UserName & Password</span>
                                      </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div data-aos="fade-left" className="order-1 md:order-2 items-start justify-start px-4 lg:p-0 lg:flex md:flex">
                        <img
                          src={
                            "https://storage.googleapis.com/pet-medi-staging/web-assets/guide/VIewInvoice.png"
                          }
                          alt="Hero"
                          className="object-cover w-full lg:h-auto max-h-80 mt-16 lg:max-h-full drop-shadow-lg"
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                      </div>
                    </div>

                    {/* Place Invoice */}
                    <div data-aos="fade-right" className="grid grid-cols-1 md:grid-cols-2 h-screen lg:mb-[-28vh] md:mb-[-30vh]">
                      <div className="order-1 md:order-1 items-start justify-start px-4 lg:p-0 lg:flex md:flex">
                        <video
                          src={placeInvoice}
                          controls
                          className="object-cover w-full lg:h-auto max-h-80 mt-16 lg:max-h-full drop-shadow-lg"
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        ></video>
                      </div>

                      <div className="order-2 md:order-2 flex items-start justify-start ">
                        <div className="px-4 lg:pr-12 xl:pr-20">
                          <div className="p-4 lg:p-8 xl:p-12">
                            <div className="lg:text-left md:text-left">
                              <h1 data-aos="fade-left" className="text-xl lg:text-2xl xl:text-3xl mb-4 font-semibold">
                                <span className="py-4">Place Invoice</span>
                                <br />
                              </h1>

                              <div className="space-y-4 aboutDetails">
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>
                                    Enter Pet Code Or Contact Number To continue
                                  </span>
                                </div>
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>
                                    Select Doctor & Add Other Required Details
                                  </span>
                                </div>
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>
                                    Select Treatment Type & Sub Type & Click
                                    Add To Continue
                                  </span>
                                </div>
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>
                                    Set The Payment Method & Amount & Click
                                    Add, After All Click the Place Invoice To
                                    Pay Bill
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* Stock Management */}
                {activeTab === "Stock" && (
                  <div>
                    {/*  Brand */}
                    {/* 1 */}
                    <div className="grid grid-cols-1 md:grid-cols-2 h-screen lg:mb-[-28vh] md:mb-[-50vh] mb-[-10vh]">
                      <div className="order-2 mt-[-10vh] md:mt-0 lg:mt-0 md:order-1 flex items-start justify-start ">
                        <div className="px-4 lg:pr-12 xl:pr-20">
                          <div className="p-4 lg:p-8 xl:p-12">
                            <div className="lg:text-left md:text-left">
                              <h1 data-aos="fade-right" className="text-xl lg:text-2xl xl:text-3xl mb-4 font-semibold">
                                <span className="py-4">All Brands</span>
                                <br />
                              </h1>

                              <div className="space-y-4 aboutDetails">
                                {/* <div className="flex items-center">
                                                    <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                                    <span>If You want to find Some Thing You can search Advance way & normal way </span>
                                                  </div> */}
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>
                                    Click On New Brand To Add New Brand
                                  </span>
                                </div>
                                {/* <div className="flex items-center">
                                                    <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                                    <span>Using E-mail You can Change Your Password</span>
                                                  </div>
                                                  <div className="flex items-center">
                                                    <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                                    <span>Then You Can Login Using Your UserName & Password</span>
                                                  </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div data-aos="fade-left" className="order-1 md:order-2 items-start justify-start px-4 lg:p-0 lg:flex md:flex">
                        <img
                          src={
                            "https://storage.googleapis.com/pet-medi-staging/web-assets/guide/stockManagement-Branf.jpg"
                          }
                          alt="Hero"
                          className="object-cover w-full lg:h-auto max-h-80 mt-16 lg:max-h-full drop-shadow-lg"
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                      </div>
                    </div>

                    {/* 2 */}
                    <div data-aos="fade-right" className="grid grid-cols-1 md:grid-cols-2 h-screen lg:mb-[-28vh] md:mb-[-50vh] mb-[-40vh]">
                      <div className="order-1 mb-[-30vh] md:order-1 items-start justify-start px-4 lg:p-0 lg:flex md:flex">
                        <img
                          src={
                            "https://storage.googleapis.com/pet-medi-staging/web-assets/guide/sm-newBrand.jpg"
                          }
                          alt="Hero"
                          className="object-cover w-full lg:h-auto max-h-80 lg:max-h-full drop-shadow-lg"
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                      </div>

                      <div className="order-2 mt-[-10vh] md:mt-0 lg:mt-[-5vh] md:order-2 flex items-start justify-start ">
                        <div className="px-4 lg:pr-12 xl:pr-20">
                          <div className="p-4 lg:p-8 xl:p-12">
                            <div className="lg:text-left md:text-left">
                              <h1 data-aos="fade-left" className="text-xl lg:text-2xl xl:text-3xl mb-4 font-semibold">
                                <span className="py-4">Add New Brand</span>
                                <br />
                              </h1>

                              <div className="space-y-4 aboutDetails">
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>Fill The Required Details</span>
                                </div>
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>Click On Save To Continue</span>
                                </div>
                                {/* <div className="flex items-center">
                                                    <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                                    <span>Then You Can Navigate to Registraton Page</span>
                                                  </div> */}
                                {/* <div className="flex items-center">
                                                    <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                                    <span>Then You Can Login Using Your UserName & Password</span>
                                                  </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Spplier */}
                    {/* 1 */}
                    <div className="grid grid-cols-1 md:grid-cols-2 h-screen lg:mb-[-28vh] md:mb-[-50vh] mb-[-10vh]">
                      <div className="order-2 mt-[-10vh] md:mt-0 lg:mt-0 md:order-1 flex items-start justify-start ">
                        <div className="px-4 lg:pr-12 xl:pr-20">
                          <div className="p-4 lg:p-8 xl:p-12">
                            <div className="lg:text-left md:text-left">
                              <h1 data-aos="fade-right" className="text-xl lg:text-2xl xl:text-3xl mb-4 font-semibold">
                                <span className="py-4">Supplier</span>
                                <br />
                              </h1>

                              <div className="space-y-4 aboutDetails">
                                {/* <div className="flex items-center">
                                                    <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                                    <span>If You want to find Some Thing You can search Advance way & normal way </span>
                                                  </div> */}
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>
                                    Click On New Supplier To Add New Supplier
                                  </span>
                                </div>
                                {/* <div className="flex items-center">
                                                    <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                                    <span>Using E-mail You can Change Your Password</span>
                                                  </div>
                                                  <div className="flex items-center">
                                                    <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                                    <span>Then You Can Login Using Your UserName & Password</span>
                                                  </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div data-aos="fade-left" className="order-1 md:order-2 items-start justify-start px-4 lg:p-0 lg:flex md:flex">
                        <img
                          src={
                            "https://storage.googleapis.com/pet-medi-staging/web-assets/guide/stockManagement-supplier.jpg"
                          }
                          alt="Hero"
                          className="object-cover w-full lg:h-auto max-h-80 mt-16 lg:max-h-full drop-shadow-lg"
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                      </div>
                    </div>

                    {/* 2 */}
                    <div data-aos="fade-right" className="grid grid-cols-1 md:grid-cols-2 h-screen lg:mb-[-28vh] md:mb-[-50vh] mb-[-40vh]">
                      <div className="order-1  md:order-1 items-start justify-start px-4 lg:p-0 lg:flex md:flex">
                        <img
                          src={
                            "https://storage.googleapis.com/pet-medi-staging/web-assets/guide/sm-newsupplier.png"
                          }
                          alt="Hero"
                          className="object-cover w-full lg:h-auto max-h-80 lg:max-h-full drop-shadow-lg"
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                      </div>

                      <div className="order-2 mt-[-30vh] md:mt-0 lg:mt-[-5vh] md:order-2 flex items-start justify-start ">
                        <div className="px-4 lg:pr-12 xl:pr-20">
                          <div className="p-4 lg:p-8 xl:p-12">
                            <div className="lg:text-left md:text-left">
                              <h1 data-aos="fade-left" className="text-xl lg:text-2xl xl:text-3xl mb-4 font-semibold">
                                <span className="py-4">Add New Supplier</span>
                                <br />
                              </h1>

                              <div className="space-y-4 aboutDetails">
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>Fill The Required Details</span>
                                </div>
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>Click on Save To Continue</span>
                                </div>
                                {/* <div className="flex items-center">
                                                    <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                                    <span>Then You Can Navigate to Registraton Page</span>
                                                  </div> */}
                                {/* <div className="flex items-center">
                                                    <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                                    <span>Then You Can Login Using Your UserName & Password</span>
                                                  </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Item */}
                    {/* 1 */}
                    <div className="grid grid-cols-1 md:grid-cols-2 h-screen lg:mb-[-28vh] md:mb-[-50vh] mb-[-10vh]">
                      <div className="order-2 mt-[-20vh] md:mt-0 lg:mt-0 md:order-1 flex items-start justify-start ">
                        <div className="px-4 lg:pr-12 xl:pr-20">
                          <div className="p-4 lg:p-8 xl:p-12">
                            <div className="lg:text-left md:text-left">
                              <h1 data-aos="fade-right" className="text-xl lg:text-2xl xl:text-3xl mb-4 font-semibold">
                                <span className="py-4">Item</span>
                                <br />
                              </h1>

                              <div className="space-y-4 aboutDetails">
                                {/* <div className="flex items-center">
                                                    <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                                    <span>If You want to find Some Thing You can search Advance way & normal way </span>
                                                  </div> */}
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>
                                    Click On New Item To add New Item
                                  </span>
                                </div>
                                {/* <div className="flex items-center">
                                                    <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                                    <span>Using E-mail You can Change Your Password</span>
                                                  </div>
                                                  <div className="flex items-center">
                                                    <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                                    <span>Then You Can Login Using Your UserName & Password</span>
                                                  </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div data-aos="fade-left" className="order-1 md:order-2 items-start justify-start px-4 lg:p-0 lg:flex md:flex">
                        <img
                          src={
                            "https://storage.googleapis.com/pet-medi-staging/web-assets/guide/stockManagment-item.jpg"
                          }
                          alt="Hero"
                          className="object-cover w-full lg:h-auto max-h-80 mt-16 lg:max-h-full drop-shadow-lg"
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                      </div>
                    </div>

                    {/* 2 */}
                    <div data-aos="fade-right" className="grid grid-cols-1 md:grid-cols-2 h-screen lg:mb-[-28vh] md:mb-[-50vh] mb-[-45vh]">
                      <div className="order-1 mb-[-30vh] md:order-1 items-start justify-start px-4 lg:p-0 lg:flex md:flex">
                        <img
                          src={
                            "https://storage.googleapis.com/pet-medi-staging/web-assets/guide/sm-newItem.jpg"
                          }
                          alt="Hero"
                          className="object-cover w-full lg:h-auto max-h-80 lg:max-h-full drop-shadow-lg"
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                      </div>

                      <div className="order-2 mt-[-10vh] md:mt-0 lg:mt-[-5vh] md:order-2 flex items-start justify-start ">
                        <div className="px-4 lg:pr-12 xl:pr-20">
                          <div className="p-4 lg:p-8 xl:p-12">
                            <div className="lg:text-left md:text-left">
                              <h1 data-aos="fade-left" className="text-xl lg:text-2xl xl:text-3xl mb-4 font-semibold">
                                <span className="py-4">Add New Item</span>
                                <br />
                              </h1>

                              <div className="space-y-4 aboutDetails">
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>Fill The Required Details</span>
                                </div>
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>Click On Save To Continue</span>
                                </div>
                                {/* <div className="flex items-center">
                                                    <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                                    <span>Then You Can Navigate to Registraton Page</span>
                                                  </div> */}
                                {/* <div className="flex items-center">
                                                    <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                                    <span>Then You Can Login Using Your UserName & Password</span>
                                                  </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/*  Category */}
                    {/* 1 */}
                    <div className="grid grid-cols-1 md:grid-cols-2 h-screen lg:mb-[-28vh] md:mb-[-50vh] mb-[-13vh]">
                      <div className="order-2 mt-[-15vh] md:mt-0 lg:mt-0 md:order-1 flex items-start justify-start ">
                        <div className="px-4 lg:pr-12 xl:pr-20">
                          <div className="p-4 lg:p-8 xl:p-12">
                            <div className="lg:text-left md:text-left">
                              <h1 data-aos="fade-right" className="text-xl lg:text-2xl xl:text-3xl mb-4 font-semibold">
                                <span className="py-4">All Categories</span>
                                <br />
                              </h1>

                              <div className="space-y-4 aboutDetails">
                                {/* <div className="flex items-center">
                                                    <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                                    <span>If You want to find Some Thing You can search Advance way & normal way </span>
                                                  </div> */}
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>
                                    Click On New Category To Add New Category
                                  </span>
                                </div>
                                {/* <div className="flex items-center">
                                                    <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                                    <span>Using E-mail You can Change Your Password</span>
                                                  </div>
                                                  <div className="flex items-center">
                                                    <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                                    <span>Then You Can Login Using Your UserName & Password</span>
                                                  </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div data-aos="fade-left" className="order-1 md:order-2 items-start justify-start px-4 lg:p-0 lg:flex md:flex">
                        <img
                          src={
                            "https://storage.googleapis.com/pet-medi-staging/web-assets/guide/stockManagement-Category.jpg"
                          }
                          alt="Hero"
                          className="object-cover w-full lg:h-auto max-h-80 mt-16 lg:max-h-full drop-shadow-lg"
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                      </div>
                    </div>

                    {/* 2 */}
                    <div data-aos="fade-right" className="grid grid-cols-1 md:grid-cols-2 h-screen lg:mb-[-28vh] md:mb-[-50vh] mb-[-45vh]">
                      <div className="order-1 md:order-1 mb-[-20vh] items-start justify-start px-4 lg:p-0 lg:flex md:flex">
                        <img
                          src={
                            "https://storage.googleapis.com/pet-medi-staging/web-assets/guide/sm-new%20catogory.png"
                          }
                          alt="Hero"
                          className="object-cover w-full lg:h-auto max-h-80 lg:max-h-full drop-shadow-lg"
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                      </div>

                      <div className="order-2 mt-[-20vh] md:mt-0 lg:mt-[-5vh] md:order-2 flex items-start justify-start ">
                        <div className="px-4 lg:pr-12 xl:pr-20">
                          <div className="p-4 lg:p-8 xl:p-12">
                            <div className="lg:text-left md:text-left">
                              <h1 data-aos="fade-left" className="text-xl lg:text-2xl xl:text-3xl mb-4 font-semibold">
                                <span className="py-4">Add New Category</span>
                                <br />
                              </h1>

                              <div className="space-y-4 aboutDetails">
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>Fill The Required Details</span>
                                </div>
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>Click On Save To Continue</span>
                                </div>
                                {/* <div className="flex items-center">
                                                    <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                                    <span>Then You Can Navigate to Registraton Page</span>
                                                  </div> */}
                                {/* <div className="flex items-center">
                                                    <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                                    <span>Then You Can Login Using Your UserName & Password</span>
                                                  </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Batch */}
                    {/* 1 */}
                    <div className="grid grid-cols-1 md:grid-cols-2 h-screen lg:mb-[-28vh] md:mb-[-50vh]">
                      <div className="order-2 md:order-1 flex items-start justify-start ">
                        <div className="px-4 lg:pr-12 xl:pr-20">
                          <div className="p-4 lg:p-8 xl:p-12">
                            <div className="lg:text-left md:text-left">
                              <h1 data-aos="fade-right" className="text-xl lg:text-2xl xl:text-3xl mb-4 font-semibold">
                                <span className="py-4">Batch</span>
                                <br />
                              </h1>

                              <div className="space-y-4 aboutDetails">
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>
                                    Choose The Supplier & Add The Required
                                    Details
                                  </span>
                                </div>
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>
                                    Choose The Item & Add Other Details
                                  </span>
                                </div>
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>Click On Add To Table</span>
                                </div>
                                <div  data-aos="fade-up"className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>Click On Save to Save batch & Continue</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div data-aos="fade-left" className="order-1 md:order-2 items-start justify-start px-4 lg:p-0 lg:flex md:flex">
                        <img
                          src={
                            "https://storage.googleapis.com/pet-medi-staging/web-assets/guide/StockManagement-Batch.png"
                          }
                          alt="Hero"
                          className="object-cover w-full lg:h-auto max-h-80 mt-16 lg:max-h-full drop-shadow-lg"
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {/* Sale */}
                {activeTab === "Sale" && (
                  <div>
                    {/* 1 */}
                    <div className="grid grid-cols-1 md:grid-cols-2 h-screen">
                      <div className="order-2 mt-[-13vh] md:mt-0 lg:mt-0 md:order-1 flex items-start justify-start ">
                        <div className="px-4 lg:pr-12 xl:pr-20">
                          <div className="p-4 lg:p-8 xl:p-12">
                            <div className="lg:text-left md:text-left">
                              <h1 data-aos="fade-right" className="text-xl lg:text-2xl xl:text-3xl mb-4 font-semibold">
                                <span className="py-4">Quick Sale</span>
                                <br />
                              </h1>

                              <div className="space-y-4 aboutDetails">
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>
                                    Choose Item & Click Add To Continue{" "}
                                  </span>
                                </div>
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>
                                    Select The Payment Method Set Amount &
                                    Click Add To Continue
                                  </span>
                                </div>
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>
                                    Click on Place QuickSale To Place The Item
                                  </span>
                                </div>
                                {/*<div className="flex items-center">
                                              <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                              <span>Then You Can Login Using Your UserName & Password</span>
                                            </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div data-aos="fade-left" className="order-1 md:order-2 items-start justify-start px-4 lg:p-0 lg:flex md:flex">
                        <img
                          src={
                            "https://storage.googleapis.com/pet-medi-staging/web-assets/guide/QuickSale.png"
                          }
                          alt="Hero"
                          className="object-cover w-full lg:h-auto max-h-80 mt-16 lg:max-h-full drop-shadow-lg"
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {/* Day-End */}
                {activeTab === "DayEnd" && (
                  <div>
                    {/* 1 */}
                    <div className="grid grid-cols-1 md:grid-cols-2 h-screen">
                      <div className="order-2 mt-[-13vh] md:mt-0 lg:mt-0 md:order-1 flex items-start justify-start ">
                        <div className="px-4 lg:pr-12 xl:pr-20">
                          <div className="p-4 lg:p-8 xl:p-12">
                            <div id="guide" className="lg:text-left md:text-left">
                              <h1 data-aos="fade-right" className="text-xl lg:text-2xl xl:text-3xl mb-4 font-semibold">
                                <span className="py-4">Day-End</span>
                                <br />
                              </h1>

                              <div className="space-y-4 aboutDetails">
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>Click On DayEnd To Continue </span>
                                </div>
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>
                                    Add the current cash amount in the locker to locker cash
                                    amount.
                                  </span>
                                </div>
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>
                                    Check locker cash amount with total sales
                                  </span>
                                </div>
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>
                                    If Equal, Add remaining amount to "Balance
                                    for Next Day" & Click On "End of Day."
                                  </span>
                                </div>
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>
                                    If Not Equal, Add the reason for the
                                    discrepancy To The Reason Field & Click On
                                    "End of Day."
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div data-aos="fade-left" className="order-1 md:order-2 items-start justify-start px-4 lg:p-0 lg:flex md:flex">
                        <video
                          src={DayEnd}
                          controls
                          className="object-cover w-full lg:h-auto max-h-80 mt-16 lg:max-h-full drop-shadow-lg"
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        ></video>
                      </div>
                    </div>
                  </div>
                )}

                {/* Delivery */}
                {activeTab === "Delivery" && (
                  <div>
                    {/* 1 */}
                    <div className="grid grid-cols-1 md:grid-cols-2 h-screen lg:mb-[-28vh] md:mb-[-50vh] mb-[-10vh]">
                      <div className="order-2 md:order-1 flex items-start justify-start  ">
                        <div className="px-4 lg:pr-12 xl:pr-20">
                          <div className="p-4 lg:p-8 xl:p-12">
                            <div className="lg:text-left md:text-left">
                              <h1 data-aos="fade-right" className="text-xl lg:text-2xl xl:text-3xl mb-4 font-semibold">
                                <span className="py-4">Dilevery Options</span>
                                <br />
                              </h1>

                              <div className="space-y-4 aboutDetails">
                                {/* <div className="flex items-center">
                                        <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                        <span>If You want to find Some One You can search Advance way & normal way </span>
                                      </div> */}
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>
                                    Click On New Delivery Option To Save
                                    Delivery Option
                                  </span>
                                </div>
                                {/* <div className="flex items-center">
                                        <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                        <span>Using E-mail You can Change Your Password</span>
                                      </div>
                                      <div className="flex items-center">
                                        <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                        <span>Then You Can Login Using Your UserName & Password</span>
                                      </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div data-aos="fade-left" className="order-1 md:order-2 items-start justify-start px-4 lg:p-0 lg:flex md:flex mb-[-20vh]">
                        <img
                          src={DileveryOption}
                          alt="Hero"
                          className="object-cover w-full lg:h-auto max-h-80 mt-16 lg:max-h-full drop-shadow-lg"
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                      </div>
                    </div>

                    {/* 2 */}
                    <div data-aos="fade-right" className="grid grid-cols-1 md:grid-cols-2 h-screen lg:mb-[-28vh] md:mb-[-50vh]">
                      <div className="order-1  md:order-1 items-start justify-start px-4 lg:p-0 lg:flex md:flex  ">
                        <img
                          src={NewDileveryOption}
                          alt="Hero"
                          className="object-cover w-full lg:h-auto max-h-80 lg:max-h-full drop-shadow-lg "
                          style={{ maxWidth: "100%", maxHeight: "100%" }}
                        />
                      </div>

                      <div className="order-2 mt-[-30vh] md:mt-0 lg:mt-[-5vh] md:order-2 flex items-start justify-start ">
                        <div className="px-4 lg:pr-12 xl:pr-20">
                          <div className="p-4 lg:p-8 xl:p-12">
                            <div className="lg:text-left md:text-left">
                              <h1 data-aos="fade-left" className="text-xl lg:text-2xl xl:text-3xl mb-4 font-semibold">
                                <span className="py-4">
                                  Save Delivery Option
                                </span>
                                <br />
                              </h1>

                              <div className="space-y-4 aboutDetails">
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>Fill The Required Details</span>
                                </div>
                                <div data-aos="fade-up" className="flex items-center">
                                  <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                  <span>Click On Save To Continue</span>
                                </div>
                                {/* <div className="flex items-center">
                                        <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                        <span>Then You Can Navigate to Registraton Page</span>
                                      </div> */}
                                {/* <div className="flex items-center">
                                        <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                                        <span>Then You Can Login Using Your UserName & Password</span>
                                      </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
