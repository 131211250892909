import React, { Component } from "react";
// import centralImage from "../../../assets/centralImg.jpg"
// import animal from "../../../assets/Benifits.png"
// import ImageContainer from './ImageContainer';
// import image1 from '../../../assets/dog-medi.jpg';
// import image2 from '../../../assets/cat-dog.jpg';
// import image3 from '../../../assets/dental.jpg';
// import aboutImg from '../../../assets/portrait-person-kissing-their-pet.jpg'
// import aboutUs from '../../../assets/about-us.png'
import "./About.css";
import { Helmet } from "react-helmet";
//@ts-ignore
import AOS from 'aos';
import 'aos/dist/aos.css';

export default class About extends Component {
  //@ts-ignore
  constructor(props) {
    super(props);
    this.state = {
      openSection: null,
      // centralImage: centralImage,
      // surroundingImages: [
      //   image1,
      //   image2,
      //   image3,
      //   // image4,
      // ],
    };
  }

  // componentDidMount() {
  //   //@ts-ignore
  //   this.interval = setInterval(this.rotateImages, 3000);
  // }

  // componentWillUnmount() {
  //   //@ts-ignore
  //   clearInterval(this.interval);
  // }

  // rotateImages = () => {
  //   this.setState((prevState) => {
  //     //@ts-ignore
  //     const allImages = [prevState.centralImage, ...prevState.surroundingImages];
  //     const newCentralImage = allImages.pop();
  //     const newSurroundingImages = [newCentralImage, ...allImages.slice(1)];
  //     return {
  //       centralImage: newCentralImage,
  //       surroundingImages: newSurroundingImages,
  //     };
  //   });
  // };

  // //@ts-ignore
  // updateImages = (centralImage, surroundingImages) => {
  //   this.setState({ centralImage, surroundingImages });
  // };

  //@ts-ignore
  toggleVisibility = (section) => {
    this.setState((prevState) => ({
      //@ts-ignore
      openSection: prevState.openSection === section ? null : section,
    }));
  };

  componentDidMount(): void {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
    });
  }

  componentDidUpdate() {
    // Reinitialize AOS when the component updates
    AOS.refresh();
  }

  render() {
    //@ts-ignore
    // const {openSection, centralImage, surroundingImages } = this.state;
    const { openSection } = this.state;

    return (
      <>
        {/* <Helmet>
          <title>Pet Medi - About</title>
          <meta
            name="description"
            content="Learn more about Pet Medi, your trusted pet health partner."
          />
          <meta
            name="keywords"
            content="pet, health, care, dogs, cats, veterinary,Pet clinic in Sri Lanka,Veterinary services Sri Lanka,Pet hospital,Affordable pet care Sri Lanka, 
      Veterinarian near me,Animal clinic Sri Lanka,Pet surgery ,Pet vaccinations Sri Lanka,24/7 emergency vet Sri Lanka, Pet health checkup Sri Lanka, Dog clinic Sri Lanka, 
      Cat veterinary care Sri Lanka, Exotic pet care Sri Lanka, Pet dental care Sri Lanka, Pet grooming, Pet boarding services Sri Lanka, Best vet for pet Sri Lanka, 
      Cataract surgery for pets Sri Lanka, Animal rescue services Sri Lanka ,Veterinary clinic , Sri Lanka , Pet Medi, pet, P , Sri Lanka Pets, Pet Care, Animal Clinic, 
      Vet, Pet Health, Pet Health Checkup, veterinary,Pet dental care, Grooming, Best Animal Clinic, Pet health monitoring app, Veterinary telemedicine Sri Lanka,Pet prescription management,
      Pet weight management Sri Lanka,Pet nutrition advice Sri Lanka,Digital veterinary solutions,Pet clinic appointment system,Senior pet care services,Specialized pet care Sri Lanka,
      Online vet consultation Sri Lanka,Pet illness prevention,Remote vet consultations,Pet healthcare software,Preventive vet care Sri Lanka,Pet emergency hotline Sri Lanka,
      Advanced pet diagnostics,Pet care plans Sri Lanka,In-home veterinary services,Pet surgery aftercare Sri Lanka,Pet grooming and spa Sri Lanka,Veterinary lab testing Sri Lanka,
      Personalized pet healthcare plans,Vet treatment packages Sri Lanka,Animal health solutions Sri Lanka,Vet treatment plans Sri Lanka,Pet physiotherapy Sri Lanka, 
      Vets near me, cat clinic near me, dog clinic near me, rabbit consultant doctors, dog consultant doctors, cat consultant doctors, online pet app, pet app online, 
      app for pets, app for animals, animal doctor, pet doctor, pet consultant, Pet wellness programs Sri Lanka, Pet wellness programs, Veterinary nutrition consultation, 
      Pet behavior training Sri Lanka, Pet behavior training, Veterinary dermatology Sri Lanka, Veterinary dermatology, Puppy vaccinations, Puppy vaccinations Sri Lanka, 
      Kitten vaccinations Sri Lanka, Kitten vaccinations, Flea and tick prevention Sri Lanka, Flea and tick prevention, Microchipping services Sri Lanka, Pet allergy treatment Sri Lanka, Pet allergy treatment,
      Veterinary orthopedic surgery Sri Lanka,Veterinary orthopedic surgery, 24-hour vet service Sri Lanka, 24-hour vet service , Emergency pet care Sri Lanka, Emergency pet care,
      Animal eye care Sri Lanka,Animal eye care,Pet dental cleaning Sri Lanka,Pet dental cleaning,Dog vaccinations Sri Lanka, Dog vaccinations,Cat vaccinations Sri Lanka,Cat vaccinations,Animal diagnostic imaging,
      Vet appointment booking online Sri Lanka,Vet appointment booking online,Pet Surgery Sri Lanka, Pet Surgery,Specialist vet services Sri Lanka,Specialist vet services,Pet cardiologist Sri Lanka, Pet cardiologist,
      Best dog vet Sri Lanka,Best dog vet, Best cat vet Sri Lanka, Best cat vet,Pet health checkup Sri Lanka, Pet health checkup, Puppy health checkup Sri Lanka,Puppy health checkup, Kitten health checkup Sri Lanka,Kitten health checkup,
      Emergency pet clinic near me,Pet health and wellness Sri Lanka,Pet health and wellness, pet treatment,Pet Health, Pet Services,Pet Services near me,Pet health diagnosis,Veterinary diagnosis,Common pet diseases,Animal health conditions,
      Dog illness diagnosis,Cat health problem, Dog health problem, Pet health problem, Pet disease symptoms, Veterinary medical conditions, Signs of illness in pets, Pet diagnostic tests,
      Dog allergy diagnosis, Cat urinary tract infection symptoms, Dog urinary tract infection symptoms, Pet urinary tract infection symptoms, Veterinary blood tests for pets, Pet arthritis treatment,
      Dog diabetes diagnosis, Cat diabetes diagnosis, Pet diabetes diagnosis, Pet cancer signs, Common pet skin diseases, Animal ear infections, Dog digestive problems diagnosis,
      Cat digestive problems diagnosis, Pet digestive problems diagnosis, Pet liver disease symptoms, Kidney disease in cats, Kidney disease in dogs, Kidney disease in pets,
      Pet obesity treatment, Dog heart disease symptoms, Cat heart disease symptoms, Pet heart disease symptoms, Cat thyroid disease, Dog thyroid disease, Pet thyroid disease,
      Pet parasite infections, Veterinary checkup for pets, Dog hip dysplasia diagnosis, Cat respiratory infections, Dog respiratory infections, Pet respiratory infections, Pancreatitis in pet symptoms, Dog Food, Cat Food "
          />
        </Helmet> */}

        <div className="container pb-20">
          {/* aboutmain */}
          <div className=" h-screen bg-gray-100">
            <div
              className="absolute inset-0 bg-cover bg-center w-full"
              style={{
                backgroundImage: `url(${"https://storage.googleapis.com/pet-medi-staging/web-assets/portrait-person-kissing-their-pet.jpg"})`,
              }}
            />
            <div className="absolute inset-0 bg-black bg-opacity-50 grid grid-cols-1 lg:grid-cols-2">
              <div className="hidden lg:block"></div>
              <div className="flex items-center justify-center p-4 lg:p-12 xl:p-20 text-white lg:items-end lg:justify-center">
                <div className="p-4 lg:px-8 xl:px-12 border-0 lg:border-r-2 border-[#1868db] h-auto lg:pl-12 text-center lg:text-left">
                  <h3 className="mb-2 text-lg lg:text-xl xl:text-xl text-gray-300 lg:text-end">
                    ABOUT US
                  </h3>
                  <h1 className="text-4xl lg:text-5xl xl:text-5xl mb-4 font-semibold lg:text-end">
                    <span>Unconditional </span>
                    <span className="text-[#1868db] text-[3.5rem] md:text-[4rem] lg:text-[4.5rem]">
                      LOVE,
                    </span>
                    <br />
                    <span className="text-[2.5rem]"> Forever Companions.</span>
                  </h1>
                  <p className="mb-2 text-lg lg:text-xl xl:text-xl text-gray-300 lg:text-end">
                    Pet Medi can give details for you about your nearest clinic
                    and you can know their services by accessing their websites.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="container aboutContainer px-4 md:px-12 lg:px-14  xl:px-2 grid max-w-[1440px] mx-auto xl:ml-16">
            {/* aboutUs */}
            <div className="flex flex-col md:flex-row items-center justify-center bg-white py-6 aboutus px-4">
              <div className="w-full md:w-1/2 ">
                <div data-aos="fade-up" className=" text-4xl font-bold inline-block mb-4 abouttopic">
                  About Us
                </div>
                <h1 data-aos="fade-up" className="text-[#1868db] text-xl md:text-xl font-bold mb-4">
                  We take ensuring the well-being of beloved pets
                </h1>
                <p data-aos="fade-up" className="text-gray-700 mb-6">
                  Pet care is renowned pet care services and solution for the
                  lifetime. We love pets and believe pets make us better people.
                </p>
                <div className="grid grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-4 justify-center items-center">
                  <div className="space-y-2 md:space-y-4 lg:space-y-4 ">
                    <div data-aos="fade-right" className="flex items-center">
                      <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                      <span>Best Doctors</span>
                    </div>
                    <div data-aos="fade-right" className="flex items-center">
                      <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                      <span>Find Services</span>
                    </div>
                    <div data-aos="fade-right" className="flex items-center">
                      <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                      <span>Booking & Registration</span>
                    </div>
                  </div>
                  <div className="space-y-4 aboutDetails">
                    <div data-aos="fade-right" className="flex items-center">
                      <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                      <span>Nearest Clinics</span>
                    </div>
                    <div data-aos="fade-right" className="flex items-center">
                      <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                      <span>Safe Environment</span>
                    </div>
                    <div data-aos="fade-right" className="flex items-center">
                      <i className="bi bi-check-circle-fill text-[#1868db] mr-2"></i>
                      <span>Affordable</span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Image container section */}
              {/* <div className="w-full md:w-1/2 aboutCircle ">
            <ImageContainer centralImage={centralImage} surroundingImages={surroundingImages} />
          </div> */}
              <div className="relative w-full md:w-1/2 aboutusImg lg:top-4 md:top-14 xl:ml-24 ">
                <img
                  src={
                    "https://storage.googleapis.com/pet-medi-staging/web-assets/about-us.png"
                  }
                  alt=""
                />
              </div>
            </div>

            {/* enjoy services */}

            <section className="bg-white py-10 mt-5 overflow-hidden">
              <div className="text-center mb-8">
                <h2 data-aos="fade-left" className="text-3xl font-bold text-[#1868db] mb-4">
                  Enjoy Service
                </h2>
                <p data-aos="fade-right" className="text-xl font-semibold">
                  Book Your Service In 3 Easy Steps
                </p>
              </div>

              <div
               data-aos="zoom-in"
               data-aos-duration="1200"
               className="flex flex-col md:flex-row justify-around items-center space-y-8 md:space-y-0 lg:space-x-4">
                {/* 1 */}
                <div className="flex flex-col items-center w-full md:w-1/3 px-4 text-center">
                  <div className="bg-blue-100 p-4 rounded-full mb-4">
                    <i className="bi bi-check2-square text-[#1868db] text-6xl"></i>
                  </div>
                  <h3 className="text-xl font-bold text-[#1868db] mb-2">
                    Choose Your Service
                  </h3>
                  <p className="text-gray-600">
                    Tell Us What want to be needed, and we’ll offer you the best
                    price available.
                  </p>
                </div>
                {/* 2 */}
                <div className="flex flex-col items-center w-full md:w-1/3 px-4 text-center">
                  <div className="bg-blue-100 p-4 rounded-full mb-4">
                    <i className="bi bi-calendar-check text-[#1868db] text-6xl"></i>
                  </div>
                  <h3 className="text-xl font-bold text-[#1868db] mb-2">
                    Find The nearest clinic
                  </h3>
                  <p className="text-gray-600">
                    Tell Us What want to be needed, and we’ll offer you the best
                    price available.
                  </p>
                </div>
                {/* 3 */}
                <div className="flex flex-col items-center w-full md:w-1/3 px-4 text-center">
                  <div className="bg-blue-100 p-4 rounded-full mb-4">
                    <i className="bi bi-arrow-down-left-square  text-[#1868db] text-6xl"></i>
                  </div>
                  <h3 className="text-xl font-bold text-[#1868db] mb-2">
                    Registration and Booking
                  </h3>
                  <p className="text-gray-600">
                    Tell Us What want to be needed, and we’ll offer you the best
                    price available.
                  </p>
                </div>
              </div>
            </section>

            {/* benifits */}

            <div className="px-4">
              <div className="text-center mt-10">
                <div data-aos="fade-left" className="text-4xl font-bold py-2 px-4 rounded-full inline-block">
                  Benefits
                </div>
                <h2 data-aos="fade-right" className="text-2xl md:text-3xl font-bold mb-4 text-[#1868db]">
                  Why Choose Pet-Medi
                </h2>
                <p data-aos="fade-left" className="text-gray-700 max-w-2xl mx-auto">
                  Pet-Medi is a renowned pet care service and solution for a
                  lifetime. We love pets and believe pets make us better people.
                </p>
              </div>

              <div className="md:flex md:space-x-4">
                <div className="md:w-6/12 justify-center lg:justify-start xl:justify-start hidden lg:flex md:flex">
                  <img
                    src={
                      "https://storage.googleapis.com/pet-medi-staging/web-assets/Benifits.png"
                    }
                    alt="Pets"
                    className="max-w-full h-auto"
                  />
                </div>

                <div className="md:w-6/12 mt-8 md:mt-0 flex flex-col justify-center ">
                  {/* 1 */}
                  <div data-aos="fade-up" className="flex items-center">
                    <button
                      onClick={() => this.toggleVisibility("findDoctors")}
                    >
                      <i
                        className={`bi ${openSection === "findDoctors"
                            ? "bi-dash-circle-fill"
                            : "bi-plus-circle-fill"
                          } text-blue-500 text-xl mr-2`}
                      ></i>
                    </button>
                    <span className="font-medium">
                      Easily Find Doctors and Nearest Places
                    </span>
                  </div>
                  {openSection === "findDoctors" && (
                    <p data-aos="zoom-in" className="text-gray-600">
                      We provide a comprehensive directory of pet doctors and
                      nearby places to ensure your pet receives the best care.
                    </p>
                  )}
                  <div data-aos="fade-up" className="h-0.5 w-auto bg-blue-300 mt-2 mx-auto md:mx-0"></div>

                  {/* 2 */}
                  <div data-aos="fade-up" className="flex items-center mt-4">
                    <button
                      onClick={() =>
                        this.toggleVisibility("bookingRegistration")
                      }
                    >
                      <i
                        className={`bi ${openSection === "bookingRegistration"
                            ? "bi-dash-circle-fill"
                            : "bi-plus-circle-fill"
                          } text-blue-500 text-xl mr-2`}
                      ></i>
                    </button>
                    <span className="font-medium">Booking & Registration</span>
                  </div>
                  {openSection === "bookingRegistration" && (
                    <p data-aos="zoom-in" className="text-gray-600">
                      Easily book appointments and register your pets through
                      our user-friendly platform.
                    </p>
                  )}
                  <div data-aos="fade-up" className="h-0.5 w-auto bg-blue-300 mt-2 mx-auto md:mx-0"></div>

                  {/* 3 */}
                  <div data-aos="fade-up" className="flex items-center mt-4">
                    <button
                      onClick={() => this.toggleVisibility("accessWebsites")}
                    >
                      <i
                        className={`bi ${openSection === "accessWebsites"
                            ? "bi-dash-circle-fill"
                            : "bi-plus-circle-fill"
                          } text-blue-500 text-xl mr-2`}
                      ></i>
                    </button>
                    <span className="font-medium">
                      Access to Their Websites
                    </span>
                  </div>
                  {openSection === "accessWebsites" && (
                    <p data-aos="zoom-in" className="text-gray-600">
                      Get direct access to various pet care providers' websites
                      for more information and services.
                    </p>
                  )}
                  <div data-aos="fade-up" className="h-0.5 w-auto bg-blue-300 mt-2 mx-auto md:mx-0"></div>

                  {/* 4 */}
                  <div data-aos="fade-up" className="flex items-center mt-4">
                    <button
                      onClick={() =>
                        this.toggleVisibility("additionalServices")
                      }
                    >
                      <i
                        className={`bi ${openSection === "additionalServices"
                            ? "bi-dash-circle-fill"
                            : "bi-plus-circle-fill"
                          } text-blue-500 text-xl mr-2`}
                      ></i>
                    </button>
                    <span className="font-medium">
                      Find Their Additional Services
                    </span>
                  </div>
                  {openSection === "additionalServices" && (
                    <p data-aos="zoom-in" className="text-gray-600">
                      Discover additional services offered by pet care
                      providers, tailored to meet all your pet's needs.
                    </p>
                  )}
                  <div data-aos="fade-up" className="h-0.5 w-auto bg-blue-300 mt-2 mx-auto md:mx-0"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
