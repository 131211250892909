import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import main from "../../../Assets/handshak.jpg";
import { Pagination } from "@mui/material";
import { Helmet } from "react-helmet";

const baseUrl = process.env.REACT_APP_BASE_URL;
const defaultLogoUrl =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAO0AAADVCAMAAACMuod9AAAAVFBMVEXu7u5mZmbz8/NZWVlcXFy8vLzU1NSXl5eioqKysrL19fV/f3/x8fFjY2NeXl7Nzc3j4+NqamrKysqMjIx3d3eGhobp6ene3t5TU1Nubm6wsLCPj49E04CSAAACxUlEQVR4nO3b63KiQBRFYWwIJoSbYnQyef/3HPCCIt1OMraTOjtr/VSk+vMolypNkh+U++4F/NfQ6oZWN7S6odUNrW5odUOrG1rd0OqGVje0uqHVDa1uaHVDqxta3dDqhlY3tLqh1Q2tbmh1Q6sbWt3Q6oZWN7S6odUNrW5odUOrG1rd0OqGVje0uqHVDa1uaHVDq9sDtKWLVfSlxd9j2TzFqo29tge8f89plsao/pWXsdcWeX+Dtn5dxug9M6FNX4oYvaZGtDH2ivbe0N65R7T/ENp7+25tf5kZIOlpS5e//F4nXpScttxt0rrOVo1vezVt2dbVoq9KGw9LTVu87bE9d+F5gZq2zRbHsvXcJaYtm1Fbe16hps1/kjbZjlqfy7bWtdePFsv6eJTaFPPtTWtdk65nD7/vuVW1EzsD9dgqW1+NsEyWWZalb61PZVjr9sff+XSLbdO0/itlu9p+ssfz6uwLWoZEZrVuPLN6vruhrGrPWO90x6bHKqPaS2zP9d7wDJt11eRoZVM7xQan67r+huiSa1J7jQ1Mt8cO90IXXIvaOXbgzqa7x065BrU+rGe6RXe6hjxz7Wn92BnXnbA9d3XimtOGsFfc4oy9mK41rWvqAHbCdd1ks9N0jWnDk51wi+7qPTlybWlvTfaC6z5mmx24prS3JztyZ5MduZa0Lr892QO38Ez2xDWkdfnfJnvg+rF7rh1t8YnJDtVV6JlqlVjRPn1qsrerNhsb2vojOLKvcBc2fkEU/nx+LSPaOFi0964t8v7QokVrU5tmcbLw++Rkl8dqG3tpj/gXRRmr6CvjPyO6odUNrW5odUOrG1rd0OqGVje0uqHVDa1uaHVDqxta3dDqhlY3tLqh1Q2tbmh1Q6sbWt3Q6oZWN7S6odUNrW5odUOrG1rd0OqGVje0uqHVDa1uaHVDqxta3dDqhlY3tLqh1Q2tbmh1Q6sbWt3Q6oZWN7S6odUNrW5odUOrG1rd0OqGVje0uqHVDa1uaHVDqxta3dDqhlY39wcAlyz4wLPLJwAAAABJRU5ErkJggg==";

interface Company {
  id: number;
  name: string;
  logo?: string;
  contact: string;
  address: string;
  subscriptionId: number;
}

const Customer: React.FC = () => {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [rowCount, setRowCount] = useState(0);
  const [filteredCompanies, setFilteredCompanies] = useState<Company[]>([]);
  const [filterValue, setFilterValue] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  console.log(
    "companies : ",
    companies.map((subscriptionId: any) => subscriptionId === subscriptionId)
  );

  useEffect(() => {
    console.log("CAllll");

    fetchCompanies();
  }, [page, rowsPerPage]);

  useEffect(() => {
    applyFilter();
  }, [filterValue]);

  const fetchCompanies = async () => {
    console.log(page - 1);
    try {
      const data = {
        offset: page - 1,
        limit: rowsPerPage,
        columnName: "id",
      };
      axios
        .post(`${baseUrl}/web-company-save/getAll`, data)
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            setCompanies(response.data.data.companies);
            setRowCount(response.data.data.rowCount || 0);
            // setTotalCount(response.data.data.totalCount);
            // console.log("Set Companies",companies);
            // console.log("Set response",response.data.data.companies);
          } else {
            console.error("No companies found in response");
          }
        });
    } catch (error) {
      console.error("There was an error fetching the companies!", error);
    }
  };

  const fetchFilteredCompanies = async () => {
    try {
      const data = {
        offset: page - 1,
        limit: rowsPerPage,
        columnName: "id",
        filter: filterValue,
      };
      axios
        .post(`${baseUrl}/web-company-save/filter`, data)
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            // setCompanies(response.data.data.companies);
            setRowCount(response.data.data.rowCount || 0);
            // setTotalCount(response.data.data.totalCount);
            // console.log("Set Companies",companies);
            // console.log("Set response",response.data.data.companies);
            setFilteredCompanies(response.data.data.companies);
          } else {
            console.error("No companies found in response");
          }
        });
    } catch (error) {
      console.error("There was an error filtering the companies!", error);
    }
  };
  //check sub plane id is not equal 1
  const subscriptionId = companies.map(
    (subscriptionId: any) => subscriptionId != 1
  );

  const applyFilter = () => {
    if (filterValue === 'filter2' || subscriptionId) {
      fetchFilteredCompanies();
    } else {
      setFilteredCompanies([]);
      fetchCompanies();
      console.log("Calll 2");

    }
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setFilterValue(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const displayedCompanies = filterValue === "" ? companies : filteredCompanies;

  return (
    <>
      {/* <Helmet>
        <title>Pet Medi Web - Customers</title>
        <meta
          name="description"
          content="Learn more about Pet Medi, your trusted pet health partner."
        />
        <meta
          name="keywords"
          content="pet, health, care, dogs, cats, veterinary,Pet clinic in Sri Lanka,Veterinary services Sri Lanka,Pet hospital,Affordable pet care Sri Lanka, 
      Veterinarian near me,Animal clinic Sri Lanka,Pet surgery ,Pet vaccinations Sri Lanka,24/7 emergency vet Sri Lanka, Pet health checkup Sri Lanka, Dog clinic Sri Lanka, 
      Cat veterinary care Sri Lanka, Exotic pet care Sri Lanka, Pet dental care Sri Lanka, Pet grooming, Pet boarding services Sri Lanka, Best vet for pet Sri Lanka, 
      Cataract surgery for pets Sri Lanka, Animal rescue services Sri Lanka ,Veterinary clinic , Sri Lanka , Pet Medi, pet, P , Sri Lanka Pets, Pet Care, Animal Clinic, 
      Vet, Pet Health, Pet Health Checkup, veterinary,Pet dental care, Grooming, Best Animal Clinic, Pet health monitoring app, Veterinary telemedicine Sri Lanka,Pet prescription management,
      Pet weight management Sri Lanka,Pet nutrition advice Sri Lanka,Digital veterinary solutions,Pet clinic appointment system,Senior pet care services,Specialized pet care Sri Lanka,
      Online vet consultation Sri Lanka,Pet illness prevention,Remote vet consultations,Pet healthcare software,Preventive vet care Sri Lanka,Pet emergency hotline Sri Lanka,
      Advanced pet diagnostics,Pet care plans Sri Lanka,In-home veterinary services,Pet surgery aftercare Sri Lanka,Pet grooming and spa Sri Lanka,Veterinary lab testing Sri Lanka,
      Personalized pet healthcare plans,Vet treatment packages Sri Lanka,Animal health solutions Sri Lanka,Vet treatment plans Sri Lanka,Pet physiotherapy Sri Lanka, 
      Vets near me, cat clinic near me, dog clinic near me, rabbit consultant doctors, dog consultant doctors, cat consultant doctors, online pet app, pet app online, 
      app for pets, app for animals, animal doctor, pet doctor, pet consultant, Pet wellness programs Sri Lanka, Pet wellness programs, Veterinary nutrition consultation, 
      Pet behavior training Sri Lanka, Pet behavior training, Veterinary dermatology Sri Lanka, Veterinary dermatology, Puppy vaccinations, Puppy vaccinations Sri Lanka, 
      Kitten vaccinations Sri Lanka, Kitten vaccinations, Flea and tick prevention Sri Lanka, Flea and tick prevention, Microchipping services Sri Lanka, Pet allergy treatment Sri Lanka, Pet allergy treatment,
      Veterinary orthopedic surgery Sri Lanka,Veterinary orthopedic surgery, 24-hour vet service Sri Lanka, 24-hour vet service , Emergency pet care Sri Lanka, Emergency pet care,
      Animal eye care Sri Lanka,Animal eye care,Pet dental cleaning Sri Lanka,Pet dental cleaning,Dog vaccinations Sri Lanka, Dog vaccinations,Cat vaccinations Sri Lanka,Cat vaccinations,Animal diagnostic imaging,
      Vet appointment booking online Sri Lanka,Vet appointment booking online,Pet Surgery Sri Lanka, Pet Surgery,Specialist vet services Sri Lanka,Specialist vet services,Pet cardiologist Sri Lanka, Pet cardiologist,
      Best dog vet Sri Lanka,Best dog vet, Best cat vet Sri Lanka, Best cat vet,Pet health checkup Sri Lanka, Pet health checkup, Puppy health checkup Sri Lanka,Puppy health checkup, Kitten health checkup Sri Lanka,Kitten health checkup,
      Emergency pet clinic near me,Pet health and wellness Sri Lanka,Pet health and wellness , pet treatment,Pet Health, Pet Services,Pet Services near me,Pet health diagnosis,Veterinary diagnosis,Common pet diseases,Animal health conditions,
      Dog illness diagnosis,Cat health problem, Dog health problem, Pet health problem, Pet disease symptoms, Veterinary medical conditions, Signs of illness in pets, Pet diagnostic tests,
      Dog allergy diagnosis, Cat urinary tract infection symptoms, Dog urinary tract infection symptoms, Pet urinary tract infection symptoms, Veterinary blood tests for pets, Pet arthritis treatment,
      Dog diabetes diagnosis, Cat diabetes diagnosis, Pet diabetes diagnosis, Pet cancer signs, Common pet skin diseases, Animal ear infections, Dog digestive problems diagnosis,
      Cat digestive problems diagnosis, Pet digestive problems diagnosis, Pet liver disease symptoms, Kidney disease in cats, Kidney disease in dogs, Kidney disease in pets,
      Pet obesity treatment, Dog heart disease symptoms, Cat heart disease symptoms, Pet heart disease symptoms, Cat thyroid disease, Dog thyroid disease, Pet thyroid disease,
      Pet parasite infections, Veterinary checkup for pets, Dog hip dysplasia diagnosis, Cat respiratory infections, Dog respiratory infections, Pet respiratory infections, Pancreatitis in pet symptoms, Dog Food, Cat Food"
    />
      </Helmet> */}

      <div className="pb-20">
        <div className="h-screen bg-gray-100 ">
          <div
            className="absolute inset-0 w-full bg-center bg-cover"
            style={{ backgroundImage: `url(${main})` }}
          />
          <div className="absolute inset-0 grid grid-cols-1 bg-black bg-opacity-50 lg:grid-cols-2">
            <div className="hidden lg:block"></div>
            <div className="flex items-center justify-center p-4 text-white lg:p-12 xl:p-20 lg:items-end lg:justify-center">
              <div className="p-4 lg:px-8 xl:px-12 border-0 lg:border-r-2 border-[#1868db] h-auto lg:pl-12 text-center lg:text-left">
                <h3 className="mb-2 text-lg text-gray-300 lg:text-xl xl:text-xl lg:text-end">
                  Our Customers
                </h3>
                <h1 className="mb-4 text-4xl font-semibold lg:text-5xl xl:text-5xl lg:text-end">
                  <span>Unconditional </span>
                  <span className="text-[#1868db] ext-[3.5rem] md:text-[4rem] lg:text-[4.5rem]">
                    LOVE,
                  </span>
                  <br />
                  <span className="text-[2.5rem]"> Forever Companions.</span>
                </h1>
                <p className="mb-2 text-lg text-gray-300 lg:text-xl xl:text-xl lg:text-end">
                  Welcome to Pet Medi's customer page. Here, you can find
                  detailed information about your company and the veterinary
                  services you offer.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container mx-auto mt-10 px-6 xl:px-16 md:px-12 lg:px-14">
          <div className="flex justify-end mb-4">
            <select
              className="p-2 border rounded border-[#1868db] text-[#1868db] text-[12px] md:text-[14px] lg:text-[16px]"
              value={filterValue}
              onChange={handleFilterChange}
            >
              <option value="">All Companies</option>
              <option value="filter2">Permitted Shops</option>
            </select>
          </div>

          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 ">
            {displayedCompanies.map((companyItem: any, index: any) => (
              <div
                key={index}
                className="relative w-full overflow-hidden border-2 group h-96 rounded-3xl hover:border-blue-500"
              >
                <div className="w-full h-1/3">
                  <img
                    src={companyItem.logo || defaultLogoUrl}
                    alt={`Card ${index + 1}`}
                    className="object-contain w-full h-full items-center justify-center mt-2"
                    onError={(e) => {
                      e.currentTarget.src = defaultLogoUrl;
                    }}
                  />
                </div>
                <div className="flex flex-col justify-center w-full p-4 text-center h-2/3">
                  <div className="mb-2 font-semibold ">
                    <i className="bi bi-building text-blue-600"></i>{" "}
                    {companyItem.name}
                  </div>
                  <div className="mb-2 font-semibold ">
                    <i className="bi bi-telephone text-blue-600"></i>{" "}
                    {companyItem.contact}
                  </div>
                  <div className="mb-2 font-semibold ">
                    <i className="bi bi-geo-alt text-blue-600"></i>{" "}
                    {companyItem.address}
                  </div>
                </div>

                {filteredCompanies.some(
                  (filteredCompany: any) =>
                    filteredCompany.id === companyItem.id
                ) && (
                    <div className="absolute inset-0 flex flex-col items-center justify-center transition-all duration-300 transform translate-y-full bg-[#0055CC] opacity-0 bg-opacity-100 group-hover:translate-y-0 group-hover:opacity-100">
                      <a
                        href={`#/shoppingCartItem/${companyItem.id}`}
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        <button className="border border-white text-white hover:bg-white hover:text-blue-700 w-full font-bold py-2 mt-4 mb-4 rounded mx-auto justify-center items-center p-5">
                          <i className="font-semibold bi bi-cart-plus-fill px-2"></i>{" "}
                          Shop Now
                        </button>
                      </a>
                    </div>
                  )}
              </div>
            ))}
          </div>

          <div className="flex flex-wrap justify-center items-center pb-2 md:pb-4 mt-2 md:mt-4 space-y-2 md:space-y-0">
            <span className="text-blue-600 text-sm md:text-lg mr-0 md:mr-4">
              Page: {page}
            </span>
            <div className="flex flex-col md:flex-row items-center space-y-2  md:space-y-0 md:space-x-4">
              <Pagination
                count={Math.ceil(rowCount / rowsPerPage)}
                page={page}
                onChange={handleChangePage}
                color="primary"
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Customer;
