import React, { Component } from 'react';
import { HashRouter, Link } from 'react-router-dom';
// import logo from "../../../assets/logo/petmedi-tran.png";
import "./Navbar.css"
import Dropdown from './Dropdown';

interface NavbarProps {
  hideNavbar: boolean;
}

interface NavbarState {
  isScrolled: boolean;
  isMobileMenuOpen: boolean;
  isScrolledPastFirstDiv: boolean;
  isNavbarVisible: boolean;
}

export default class Navbar extends Component <NavbarProps, NavbarState> {

  private lastScrollTop: number;

  // @ts-ignore
  constructor(props: NavbarProps) {
    super(props);
    this.state = {
      isScrolled: false,
      isMobileMenuOpen: false,
      isScrolledPastFirstDiv: false,
      isNavbarVisible: true,
    };
    // @ts-ignore
    this.lastScrollTop = 0;
    this.handleScroll = this.handleScroll.bind(this);
    this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll() {
    const currentScrollPos = window.pageYOffset;
    const isScrolled = currentScrollPos > 50;
    const firstDiv = document.querySelector('div');
    // @ts-ignore
    const isScrolledPastFirstDiv = firstDiv ? currentScrollPos > firstDiv.offsetHeight : false;
    this.setState({
      isScrolled,
      isScrolledPastFirstDiv,
      // @ts-ignore
      isNavbarVisible: this.lastScrollTop > currentScrollPos || currentScrollPos < 10,
    });
    // @ts-ignore
    this.lastScrollTop = currentScrollPos;
  }

  toggleMobileMenu() {
    this.setState(prevState => ({
      // @ts-ignore
      isMobileMenuOpen: !prevState.isMobileMenuOpen
    }));
  }

  render() {
    // @ts-ignore
    const { isScrolled, isMobileMenuOpen, isNavbarVisible, isScrolledPastFirstDiv } = this.state;
    const { hideNavbar } = this.props;

    if (hideNavbar) {
      return null;
    }

    const isAboutPage = window.location.pathname === '/about';
    const isContactPage = window.location.pathname === '/contact';
    const isGuidePage = window.location.pathname === '/quickGuide';
    const isCustomerPage = window.location.pathname === '/customer';
    const IsTermsPage = window.location.pathname === '/terms';



    return (
      <div className={`p-4 px-6 md:px-12 flex justify-between items-center fixed w-full z-50 transition-all lg:px-16 duration-300 ${isScrolled ? 'navbar-scrolled top-0 shadow-md' : 'bg-gray-100'} ${isNavbarVisible ? 'visible' : 'invisible'} ${isAboutPage ? 'glass-navbar text-white' : 'text-black'} ${isContactPage ? 'bg-white ' : ''} ${isGuidePage ? 'bg-white ' : ''} ${isScrolledPastFirstDiv && isAboutPage ? 'bg-white' : ''} ${isCustomerPage ? 'glass-navbar text-white' : 'text-black'}`}>
        <div className="flex items-center w-4/12">
          <img src={"https://storage.googleapis.com/pet-medi-staging/web-assets/logo/petmedi-tran.png"} alt="logo" className="h-auto w-28 sm:w-24 md:w-32 lg:w-40" />
        </div>

        <div className="items-center justify-center hidden w-4/12 lg:flex">
          <ul className="flex space-x-4 list-none font-poppins">
            <li className="text-lg">
              <Link to="/" className={` ${isAboutPage && isScrolled && isCustomerPage ? 'text-black' : ''}`}>Home</Link>
            </li>
            <li className="text-lg">
              <Link to="/pricing" className={` ${isAboutPage && isScrolled && isCustomerPage ? 'text-black' : ''}`}>Pricing</Link>
            </li>
            <li className="text-lg">
              <Link to="/about" className={` ${isAboutPage && isScrolled && isCustomerPage ? 'text-black' : ''}`}>About</Link>
            </li>
            <li className="text-lg">
              <Link to="/contact" className={` ${isAboutPage && isScrolled && isCustomerPage ? 'text-black' : ''}`}>Contact</Link>
            </li>
            <li className="text-lg">
              <Link to="/quickGuide" className={` ${isAboutPage && isScrolled && isCustomerPage ? 'text-black' : ''}`}>Guide</Link>
            </li>
           
            {/* <li className="text-lg">
              <Link to="/customer" className={` ${isAboutPage && isScrolled && isCustomerPage ? 'text-black' : ''}`}>Our-Customers</Link>
            </li>
            <li className="text-lg">
              <Link to="/policy" className={` ${isAboutPage && isScrolled && isCustomerPage ? 'text-black' : ''}`}>Privacy-Policy</Link>
            </li> */}
            <li className="text-lg">
              <Dropdown isAboutPage={isAboutPage} isScrolled={isScrolled} isCustomerPage={isCustomerPage} isTermsPage={IsTermsPage} />
            </li>
          </ul>
        </div>

        <div className="items-center justify-end hidden w-4/12 space-x-5 lg:flex">
          <Link to="https://cms.petmedi.lk" target='_blank'>
            <button className={`text-lg font-semibold px-4 py-2 rounded-lg border border-blue-600 hover:bg-blue-100 shadow-sm signIn ${isScrolled ? 'bg-white text-blue-600' : 'bg-gray-100 text-blue-600'} ${isContactPage ? 'bg-white' : ''}`}>
              Sign In
            </button>
          </Link>
          <Link to="/signUp" >
            <button className="px-4 py-2 text-lg font-semibold text-white rounded-lg shadow-lg bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800">
              Registration
            </button>
          </Link>
        </div>

        <div className="flex items-center justify-end w-4/12 lg:hidden">
          <button className="text-2xl" onClick={this.toggleMobileMenu}>
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              {isMobileMenuOpen ? (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              ) : (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
              )}
            </svg>
          </button>
        </div>

        {isMobileMenuOpen && (
          <div
            className={`absolute top-14 right-0 bg-gray-100 text-black w-full lg:hidden ${isScrolled ? 'navbar-scrolled top-0 shadow-md' : 'bg-gray-100'} ${isNavbarVisible ? 'visible' : 'invisible'} ${isAboutPage ? '' : 'text-black'} ${isContactPage ? 'bg-white' : ''} ${isGuidePage ? 'bg-white' : ''}`}
          >

            <ul className="flex flex-col items-center py-4 space-y-4 list-none">
              <li className="w-full text-lg">
                <Link to="/" className="hover:text-gray-600" onClick={this.toggleMobileMenu}>
                  <div className="w-full h-full text-center">Home</div>
                </Link>
              </li>
              <li className="text-lg">
              <Link to="/pricing" className="hover:text-gray-600" onClick={this.toggleMobileMenu}>
                Pricing
              </Link>
            </li>
              <li className="w-full text-lg">
                <Link to="/about" className="hover:text-gray-600" onClick={this.toggleMobileMenu}>
                  <div className="w-full h-full text-center">About</div>
                </Link>
              </li>
              <li className="w-full text-lg">
                <Link to="/contact" className="hover:text-gray-600" onClick={this.toggleMobileMenu}>
                  <div className="w-full h-full text-center">Contact</div>
                </Link>
              </li>
              <li className="w-full text-lg">
                <Link to="/quickGuide" className={`hover:text-gray-600 ${isAboutPage && isScrolled ? 'text-black' : ''}`} onClick={this.toggleMobileMenu}>
                  <div className="w-full h-full text-center">Guide</div>
                </Link>
              </li>
              
              <li className="w-full text-lg">
                <Link to="/customer" className={`hover:text-gray-600 ${isAboutPage && isScrolled ? 'text-black' : ''}`} onClick={this.toggleMobileMenu}>
                  <div className="w-full h-full text-center">Our-Customer</div>
                </Link>
              </li>
              {/* <li className="w-full text-lg">
                <Link to="/policy" className={`hover:text-gray-600 ${isAboutPage && isScrolled ? 'text-black' : ''}`} onClick={this.toggleMobileMenu}>
                  <div className="w-full h-full text-center">Privacy-Policy</div>
                </Link>
              </li>
              <li className="w-full text-lg">
                <Link to="/terms" className={`hover:text-gray-600 ${isAboutPage && isScrolled ? 'text-black' : ''}`} onClick={this.toggleMobileMenu}>
                  <div className="w-full h-full text-center">Terms & Conditions</div>
                </Link>
              </li> */}
              <li className="w-full text-lg">
                <Link to="/blog" className={`hover:text-gray-600 ${isAboutPage && isScrolled ? 'text-black' : ''}`} onClick={this.toggleMobileMenu}>
                  <div className="w-full h-full text-center">Blog</div>
                </Link>
              </li>
              <li className="flex justify-center w-full px-4 space-x-4 text-lg">
                <Link
                  to="https://cms.petmedi.lk"
                  target="_blank"
                  className="px-4 py-2 text-lg font-semibold text-blue-600 bg-white border border-blue-600 rounded-lg shadow-sm hover:bg-blue-100"
                  onClick={this.toggleMobileMenu}
                >
                  Sign In
                </Link>
                <Link
                  to="/signUp"
                  className="px-4 py-2 text-lg font-semibold text-white rounded-lg shadow-lg bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800"
                  onClick={this.toggleMobileMenu}
                >
                  Registration
                </Link>
              </li>
            </ul>

          </div>
        )}

      </div>
    );
  }
}
