import React, { Component } from "react";
// import hero from "https://storage.googleapis.com/pet-medi-staging/web-assets/dog-hero.png";
import "bootstrap-icons/font/bootstrap-icons.css";
// import dogEat from "../../../assets/clinic.gif";
// import dogMedi from "../../../assets/book-doctor.gif";
// import dental from "../../../assets/services.gif";
// import pampering from "../../../assets/website.gif";
import axios from "axios";
// import catImage from "../../../assets/cute-cat-relaxing-indoors.jpg"
// import dogImage from "../../../assets/dog.jpg"
// import rabbitImage from "../../../assets/rabbit.jpg"
// import loder from "../../../assets/loderC.gif";
import "./home.css";
import Carousel from "./Carousel";
import { Helmet } from "react-helmet";
// import Testimonials from "./Testimonials";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPaw } from '@fortawesome/free-solid-svg-icons';
// import Typewriter from 'typewriter-effect';
import 'aos/dist/aos.css';
// @ts-ignore
import AOS from 'aos';
const baseUrl = process.env.REACT_APP_BASE_URL;

export default class Home extends Component {
  //@ts-ignore
  constructor(props) {
    super(props);
    this.state = {
      plans: [],
      loading: true,
      error: null,
      selectedTab: "SRI-LANKA", // Initial tab set to "SRILANKA"
    };
  }

  componentDidMount() {

   this.fetchPlans();
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
    });

  }

  componentDidUpdate() {
    // Reinitialize AOS when the component updates
    AOS.refresh();

  }

  fetchPlans = async () => {
    try {
      // Check if plans exist in local storage
      const storedSriLankanPlans = sessionStorage.getItem("sriLankanPlans");
      const storedInternationalPlans = sessionStorage.getItem("internationalPlans");
  
      if (storedSriLankanPlans && storedInternationalPlans) {
        // Parse and set state with stored data if available
        this.setState({
          sriLankanPlans: JSON.parse(storedSriLankanPlans),
          internationalPlans: JSON.parse(storedInternationalPlans),
          loading: false
        });
      } else {
        // Fetch from backend if data is not in local storage
        const response = await axios.get(`${baseUrl}/webSubPlan`);
  
        // Extract and map Sri Lankan plans
        const sriLankanPlans = response.data.data["sri-lankan"].map((plan: any) => ({
          ...plan,
          features: plan.selectedFeaturesNameId.map((feature: any) => feature.name),
        }));
  
        // Extract and map International plans
        const internationalPlans = response.data.data["international"].map((plan: any) => ({
          ...plan,
          features: plan.selectedFeaturesNameId.map((feature: any) => feature.name),
        }));
  
        // Store the plans in local storage
        sessionStorage.setItem("sriLankanPlans", JSON.stringify(sriLankanPlans));
        sessionStorage.setItem("internationalPlans", JSON.stringify(internationalPlans));
  
        // Update state
        this.setState({ sriLankanPlans, internationalPlans, loading: false });
      }
  
      // console.log(this.state.sriLankanPlans);
      // console.log(this.state.internationalPlans);
  
    } catch (error) {
      //@ts-ignore
      this.setState({ error: error.message, loading: false });
    }
  };

  // Handler for tab selection
  handleTabClick = (country: string) => {
    this.setState({ selectedTab: country, loading: true }, () => {
      this.fetchPlans();
    });
  };
  

  render() {
    //@ts-ignore
    const { sriLankanPlans, internationalPlans, loading, error, selectedTab } = this.state;

        // Filter plans based on selected tab for now. Replace with API data when available.
        // const filteredPlans = plans.filter((plan : any) =>
        //   selectedTab === "SRI-LANKA" ? plan.region === "SRI-LANKA" : plan.region === "OTHERS"
        // );

    return (
      <>
        {/* <Helmet>
          <title>Pet Medi Web - Home</title>
          <meta
            name="description"
            content="Learn more about Pet Medi, your trusted pet health partner."
          />

          <meta
            name="keywords"
            content="pet, health, care, dogs, cats, veterinary,Pet clinic in Sri Lanka,Veterinary services Sri Lanka,Pet hospital,Affordable pet care Sri Lanka, 
      Veterinarian near me,Animal clinic Sri Lanka,Pet surgery ,Pet vaccinations Sri Lanka,24/7 emergency vet Sri Lanka, Pet health checkup Sri Lanka, Dog clinic Sri Lanka, 
      Cat veterinary care Sri Lanka, Exotic pet care Sri Lanka, Pet dental care Sri Lanka, Pet grooming, Pet boarding services Sri Lanka, Best vet for pet Sri Lanka, 
      Cataract surgery for pets Sri Lanka, Animal rescue services Sri Lanka ,Veterinary clinic , Sri Lanka , Pet Medi, pet, P , Sri Lanka Pets, Pet Care, Animal Clinic, 
      Vet, Pet Health, Pet Health Checkup, veterinary,Pet dental care, Grooming, Best Animal Clinic, Pet health monitoring app, Veterinary telemedicine Sri Lanka,Pet prescription management,
      Pet weight management Sri Lanka,Pet nutrition advice Sri Lanka,Digital veterinary solutions,Pet clinic appointment system,Senior pet care services,Specialized pet care Sri Lanka,
      Online vet consultation Sri Lanka,Pet illness prevention,Remote vet consultations,Pet healthcare software,Preventive vet care Sri Lanka,Pet emergency hotline Sri Lanka,
      Advanced pet diagnostics,Pet care plans Sri Lanka,In-home veterinary services,Pet surgery aftercare Sri Lanka,Pet grooming and spa Sri Lanka,Veterinary lab testing Sri Lanka,
      Personalized pet healthcare plans,Vet treatment packages Sri Lanka,Animal health solutions Sri Lanka,Vet treatment plans Sri Lanka,Pet physiotherapy Sri Lanka, 
      Vets near me, cat clinic near me, dog clinic near me, rabbit consultant doctors, dog consultant doctors, cat consultant doctors, online pet app, pet app online, 
      app for pets, app for animals, animal doctor, pet doctor, pet consultant, Pet wellness programs Sri Lanka, Pet wellness programs, Veterinary nutrition consultation, 
      Pet behavior training Sri Lanka, Pet behavior training, Veterinary dermatology Sri Lanka, Veterinary dermatology, Puppy vaccinations, Puppy vaccinations Sri Lanka, 
      Kitten vaccinations Sri Lanka, Kitten vaccinations, Flea and tick prevention Sri Lanka, Flea and tick prevention, Microchipping services Sri Lanka, Pet allergy treatment Sri Lanka, Pet allergy treatment,
      Veterinary orthopedic surgery Sri Lanka,Veterinary orthopedic surgery, 24-hour vet service Sri Lanka, 24-hour vet service , Emergency pet care Sri Lanka, Emergency pet care,
      Animal eye care Sri Lanka,Animal eye care,Pet dental cleaning Sri Lanka,Pet dental cleaning,Dog vaccinations Sri Lanka, Dog vaccinations,Cat vaccinations Sri Lanka,Cat vaccinations,Animal diagnostic imaging,
      Vet appointment booking online Sri Lanka,Vet appointment booking online,Pet Surgery Sri Lanka, Pet Surgery,Specialist vet services Sri Lanka,Specialist vet services,Pet cardiologist Sri Lanka, Pet cardiologist,
      Best dog vet Sri Lanka,Best dog vet, Best cat vet Sri Lanka, Best cat vet,Pet health checkup Sri Lanka, Pet health checkup, Puppy health checkup Sri Lanka,Puppy health checkup, Kitten health checkup Sri Lanka,Kitten health checkup,
      Emergency pet clinic near me,Pet health and wellness Sri Lanka,Pet health and wellness , pet treatment,Pet Health, Pet Services,Pet Services near me,Pet health diagnosis,Veterinary diagnosis,Common pet diseases,Animal health conditions,
      Dog illness diagnosis,Cat health problem, Dog health problem, Pet health problem, Pet disease symptoms, Veterinary medical conditions, Signs of illness in pets, Pet diagnostic tests,
      Dog allergy diagnosis, Cat urinary tract infection symptoms, Dog urinary tract infection symptoms, Pet urinary tract infection symptoms, Veterinary blood tests for pets, Pet arthritis treatment,
      Dog diabetes diagnosis, Cat diabetes diagnosis, Pet diabetes diagnosis, Pet cancer signs, Common pet skin diseases, Animal ear infections, Dog digestive problems diagnosis,
      Cat digestive problems diagnosis, Pet digestive problems diagnosis, Pet liver disease symptoms, Kidney disease in cats, Kidney disease in dogs, Kidney disease in pets,
      Pet obesity treatment, Dog heart disease symptoms, Cat heart disease symptoms, Pet heart disease symptoms, Cat thyroid disease, Dog thyroid disease, Pet thyroid disease,
      Pet parasite infections, Veterinary checkup for pets, Dog hip dysplasia diagnosis, Cat respiratory infections, Dog respiratory infections, Pet respiratory infections, Pancreatitis in pet symptoms, Dog Food, Cat Food"
          />
          <link rel="apple-touch-icon" href="%PUBLIC_URL%/favicon.ico" />
        </Helmet> */}

        <div className="grid pb-20">
          {/* Main Image */}
          <div className="flex flex-col mt-10 md:flex-row lg:flex-row h-auto md:h-[70vh] md:mt-10 lg:h-screen bg-gray-100 heroSection">
            <div className="flex items-center justify-start flex-1 p-4 lg:p-5">
              <div className="pr-4 lg:pr-12 xl:pr-20">
                <div className="p-4 lg:p-8 xl:p-12">
                  <div className="text-center border-0 border-blue-600 lg:border-l-2 lg:pl-14 lg:text-left">
                    <div className="mx-auto lg:w-[400px]  lg:mx-0">
                      <h1 className="mb-4 text-2xl font-semibold lg:text-5xl xl:text-5xl">
                        <span>Committed To </span>
                        <span className="text-blue-600 lg:text-[4.2rem] md:text-[3.2rem] text-[2.2rem]">
                          Excellence
                        </span>
                        <br />
                        <span> In Pet Care</span>
                      </h1>
                      <p className="mb-4 text-md lg:-[1rem] xl:text-xl text-white md:text-gray-500 lg:text-gray-500">
                        Manage your pet clinic effortlessly with PetMedi’s
                        advanced platform. Streamline operations, enhance
                        efficiency, and expand your reach. Clients can easily
                        find your clinic, explore services, and shop
                        conveniently. Deliver exceptional pet care with PetMedi.
                      </p>
                      <button
                        className="px-6 py-3 font-bold text-white transition duration-200 ease-in-out bg-blue-600 rounded-lg hover:bg-blue-700"
                        onClick={() => (window.location.href = "#/signUp")}
                      >
                        Get Started
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="items-center justify-center flex-1 lg:p-0 lg:flex md:flex">
              <img
                src={
                  "https://storage.googleapis.com/pet-medi-staging/web-assets/dog-hero.png"
                }
                alt="Hero"
                className="object-cover w-full lg:h-auto max-h-80 lg:max-h-full"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </div>
          </div>

          <div className="container px-6 xl:px-16 md:px-12 lg:px-14 grid homeContend mx-auto mt-[5vh] md:mt-0 lg:mt-0 max-w-[1440px]">
            {/* Home/Services */}
            <div className="py-5 mt-8 home-services xl:ml-4">
              {/* Topic */}
              <div className="row home-services-topic">
                <div className="mb-12 text-center">
                  <h4 data-aos="fade-up" className="text-2xl font-bold text-[#1868db]">
                    What Happens Here
                  </h4>
                </div>
              </div>
              <div className="container text-start">
                <div className="grid justify-between grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
                  {/* Card 1 */}
                  <div data-aos="fade-down" className="flex flex-col items-center w-full h-full p-2 bg-white border-b choose-card lg:p-2 lg:border-r">
                    <i className="bi bi-hospital text-6xl text-gray-400 hover:text-[#1868db]"></i>
                    <div className="mt-4 text-center choose-card-body">
                      <h5 className="text-xl font-semibold choose-card-title">
                        Comprehensive Clinic Management
                      </h5>
                      <p className="mt-2 text-gray-600 choose-card-text">
                        How PetMedi helps streamline daily clinic operations.
                        Features like appointment scheduling, patient records,
                        and billing management.
                      </p>
                    </div>
                  </div>

                  {/* Card 2 */}
                  <div data-aos="fade-down" className="flex flex-col items-center w-full h-full p-2 bg-white border-b choose-card lg:p-2">
                    <i className="bi bi-file-medical text-6xl text-gray-400 hover:text-[#1868db]"></i>
                    <div className="mt-4 text-center choose-card-body">
                      <h5 className="text-xl font-semibold choose-card-title">
                        Instant Vet Booking
                      </h5>
                      <p className="mt-2 text-gray-600 choose-card-text">
                        Get your furry friend the care they need by booking a
                        vet in just a few clicks.
                      </p>
                    </div>
                  </div>

                  {/* Card 3 */}
                  <div data-aos="fade-down" className="flex flex-col items-center w-full h-full p-2 bg-white border-b choose-card lg:p-2 lg:border-l">
                    <i className="bi bi-clipboard2-heart text-6xl text-gray-400 hover:text-[#1868db]"></i>
                    <div className="mt-4 text-center choose-card-body">
                      <h5 className="text-xl font-semibold choose-card-title">
                        Easy Clinic Discovery
                      </h5>
                      <p className="mt-2 text-gray-600 choose-card-text">
                        Enable clients to locate your clinic easily through a
                        user-friendly search function based on location and
                        services offered.
                      </p>
                    </div>
                  </div>

                  {/* Card 4 */}
                  <div data-aos="fade-up" className="flex flex-col items-center w-full h-full p-2 bg-white border-b choose-card lg:p-2 lg:border-b-0 lg:border-t lg:border-r">
                    <i className="bi bi-cart-plus-fill text-6xl text-gray-400 hover:text-[#1868db] mt-4"></i>
                    <div className="mt-4 text-center choose-card-body">
                      <h5 className="text-xl font-semibold choose-card-title">
                        Integrated Online Shopping
                      </h5>
                      <p className="mt-2 text-gray-600 choose-card-text">
                        Offer a convenient way for clients to purchase pet care
                        products directly through your clinic’s online store on
                        PetMedi.
                      </p>
                    </div>
                  </div>

                  {/* Card 5 */}
                  <div data-aos="fade-up" className="flex flex-col items-center w-full h-full p-2 bg-white border-b choose-card lg:p-2 lg:border-b-0 lg:border-t">
                    <i className="bi bi-browser-edge text-6xl text-gray-400 hover:text-[#1868db] mt-4"></i>
                    <div className="mt-4 text-center choose-card-body">
                      <h5 className="text-xl font-semibold choose-card-title">
                        Visit Clinic Websites
                      </h5>
                      <p className="mt-2 text-gray-600 choose-card-text">
                        Access detailed information about clinics and their
                        facilities directly through our platform.
                      </p>
                    </div>
                  </div>

                  {/* Card 6 */}
                  <div data-aos="fade-up" className="flex flex-col items-center w-full h-full p-2 bg-white border-b choose-card lg:p-2 lg:border-b-0 lg:border-t lg:border-l">
                    <i className="bi bi-basket-fill text-6xl text-gray-400 hover:text-[#1868db] mt-4"></i>
                    <div className="mt-4 text-center choose-card-body">
                      <h5 className="text-xl font-semibold choose-card-title">
                        Shop Pet Essentials
                      </h5>
                      <p className="mt-2 text-gray-600 choose-card-text">
                        Browse and purchase pet care products, medications, and
                        accessories conveniently from clinic stores.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Pricing */}
            {loading ? (
              <div className="flex items-center justify-center">
                <img
                  src={
                    "https://storage.googleapis.com/pet-medi-staging/web-assets/loderC.gif"
                  }
                  alt="Loder"
                />
              </div>
            ) : error ? (
              <div>Error: {error}</div>
            ) : (
              <div className="mt-12 ">
                <h2 data-aos="fade-up" className="text-2xl font-bold text-center mb-4 text-[#1868db]">
                  Pet-Medi Starts In Free
                </h2>
                <div className="flex items-center justify-center mt-1 underline-container"></div>
                <p data-aos="fade-up" className="mb-10 text-center">
                  See what's included in PetMedi's pricing packages
                </p>


              {/* tabs */}
              <div data-aos="fade-up" className="flex justify-center mb-4 gap-4">
             <div className="">
             <button
                onClick={() => this.handleTabClick("SRI-LANKA")}
                className={`p-2 font-bold  ${
                  selectedTab === "SRI-LANKA" ? "text-blue-600 border-b-2 border-blue-600" : ""
                }`}
              >
                SRI LANKA
              </button>
             </div>
             <div className="border-r-2 border-gray-300 px-2">

             </div>
              <div>
              <button
                onClick={() => this.handleTabClick("OTHERS")}
                className={`p-2 font-bold ${
                  selectedTab === "OTHERS" ? "text-blue-600 border-b-2 border-blue-600" : ""
                }`}
              >
                OTHER - REGIONS
              </button>
              </div>
            </div>
                  

                {/* subs plans */}
                <div className="grid justify-center grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-4 lg:gap-8 ">
                  {(selectedTab === "SRI-LANKA" ? sriLankanPlans : internationalPlans).length === 0 ? (
                <div className="text-center text-gray-500 mt-4">No subscription plans available</div>
              ) : (
                    
                (selectedTab === "SRI-LANKA" ? sriLankanPlans : internationalPlans).map((plan: any, index: any) => (
                    <div
                     data-aos="zoom-in"
                    data-aos-duration="1200"
                      key={index}
                      className="flex flex-col justify-between w-full h-full p-4 text-center bg-white border-2 border-gray-200 rounded-3xl gap-y-2 hover:border-blue-500"
                    >
                      <div>
                        <div className="flex items-center justify-center mb-4 ">
                          <i
                            className={`bi bi-${plan.icon} text-[#1868db] text-2xl`}
                          ></i>
                        </div>
                        <h3 className="text-xl font-semibold mb-2 hover:text-[#1868db] text-start">
                          {plan.name}
                        </h3>
                        <p className="mb-2 text-gray-500 text-start line-clamp-3 h-[4.5rem] overflow-hidden">
                          {plan.description}
                        </p>
                        <div className="mb-4 text-gray-600 text-start">
                          <span className="text-3xl font-bold text-gray-800 ">
                            {plan.price} {plan.currency}
                          </span>
                        </div>
                        <a
                            href="https://petmedi.lk/#/signUp"
                            target="_blank"
                            rel="noreferrer"
                          >
                        <button className="border border-[#0B66E4] text-[#0B66E4] hover:bg-blue-700 hover:text-white w-full font-bold py-2  mt-4 mb-4 rounded mx-auto justify-center items-center">
                         
                            {plan.name}
                         
                        </button>
                        </a>
                        <ul className="space-y-2 list-disc text-start md:items-center md:justify-center">
                          {plan.features.map((feature: any, i: any) => (
                            <li
                              key={i}
                              className="flex items-center  text-[12px] lg:text-[14px]"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5 text-[#1868db] mr-4"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L9 10.586l7.293-7.293a1 1 0 011.414 0z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              {feature}
                            </li>
                          ))}
                           <h5 className="text-left text-[#1868db] mt-5 cursor-pointer">
                        <a href={`#/pricing/${plan.id}`}>See More..</a>
                        </h5>
                        </ul>
                      </div>
                     
                    </div>
                  )
                  ))}
                </div>
              </div>
            )}

            <div className="right-0 items-center justify-center overflow-hidden position-relative">
              <Carousel />
            </div>

            {/* <div>
            <Testimonials />
          </div> */}
          </div>
        </div>
      </>
    );
  }
}
