import React, { Component } from 'react';
// import img from "../../../assets/login_logo.svg";
import axios from 'axios';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import loadingAnimation from '../../../Assets/regi_Loader.gif'
import FormControl from '@mui/material/FormControl/FormControl';
import { RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { FormLabel } from 'react-bootstrap';
import Branches from './Branches';
const baseUrl = process.env.REACT_APP_BASE_URL;



export default class SignOut extends Component {
  constructor() {
    //@ts-ignore
    super();
    this.state = {
      name: '',
      address: '',
      contactNumber: '',
      email: '',
      successMessage: '',
      errorMessage: {},
      showPopup: false,
      agree: false,
      showTermsPopup: false,
      checkboxError: '',
      isLoading: false,
      selectedValue: 'one',
      isPopupOpen: false,
      branchCount: 0,
      manyBranch: false,
      errors: {
        name: '',
        address: '',
        contactNumber: '',
        email: '',
        agree: '',
      },
      phoneIsValid: false,
    };
  }

  componentDidMount() {
    // Dynamically load Bootstrap CSS
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = 'https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css';
    link.id = 'bootstrap-css';
    document.head.appendChild(link);
  }

  componentWillUnmount() {
    // Remove Bootstrap CSS when the component is unmounted
    const link = document.getElementById('bootstrap-css');
    if (link) {
      document.head.removeChild(link);
    }
  }

  // validateForm = () => {
  //   //@ts-ignore
  //   const { name, address, email, agree, phoneIsValid } = this.state;
  //   const errors: Record<string, string> = {};
  
  //   if (name.length < 2) {
  //     //@ts-ignore
  //     errors.name = 'Name must be at least 2 characters long';
  //   }
  
  //   if (address.length < 5) {
  //     //@ts-ignore
  //     errors.address = 'Address must be at least 5 characters long';
  //   }
  
  //   const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  //   if (!emailPattern.test(email)) {
  //     //@ts-ignore
  //     errors.email = 'Invalid email format';
  //   }
  
  //   if (!phoneIsValid) {
  //     //@ts-ignore
  //     errors.contactNumber = 'Invalid phone number';
  //   }
  
  //   if (!agree) {
  //     //@ts-ignore
  //     errors.agree = 'You must agree to the terms and conditions';
  //   }
  
  //   this.setState({ errors });
  
  //   return Object.keys(errors).length === 0;
  // }

  //@ts-ignore
 //@ts-ignore
 handleChange = (e: any) => {
  const { id, value } = e.target;
  //@ts-ignore
  const { errorMessage } = this.state;

  // Validate fields and update errorMessage state
  switch (id) {
    case 'name':
      errorMessage.name = value.length >= 2 ? '' : 'Name must be at least 2 characters long';
      break;
    case 'address':
      errorMessage.address = value.length >= 5 ? '' : 'Address must be at least 5 characters long';
      break;
    case 'email':
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      errorMessage.email = emailPattern.test(value) ? '' : 'Invalid email format';
      break;
    case 'contactNumber':
      //@ts-ignore
      this.handlePhoneChange(value, this.state.selectedCountry);
      //@ts-ignore
      errorMessage.contactNumber = this.state.phoneIsValid ? '' : 'Invalid phone number';
      break;
    default:
      break;
  }

  this.setState({
    [id]: value,
    errorMessage,
  });
};


// Helper function for phone number validation
validatePhoneNumber = (number: string) => {
  // Implement your phone number validation logic here
  return /^[0-9]{10}$/.test(number); // Example for a 10-digit phone number format
};


  //@ts-ignore
   handlePhoneChange = (value, country) => {
    const isValid = country === 'lk' ? value.replace(/\D/g, '').length === 9 : value.length <= 15;
    this.setState({ contactNumber: value, phoneIsValid: isValid });
  };

  handleCheckboxChange = (e : any) => {
    this.setState({ agree: e.target.checked });
  }

  //submit company
  //@ts-ignore
  handleSubmit = async (e) => {
    e.preventDefault();
    //@ts-ignore
    const { name, address, contactNumber, email, agree, phoneIsValid, branchCount, manyBranch, selectedValue } = this.state;
   
    if (!agree) {
      this.setState({ checkboxError: 'You must agree to the terms and conditions.' });
      return;
    }

    if (!phoneIsValid) {
      this.setState({
        successMessage: '',
        errorMessage: 'Invalid phone number. Please enter a valid phone number.',
        showPopup: true
      });
      return;
    }
    console.log({ 
      name, 
      address, 
      contactNumber, 
      email,
      agree,
      branchCount,
      manyBranch: selectedValue === 'multiple' ? true : false 
    })
    // return
    this.setState({ isLoading: true });
    try {
      console.log(branchCount);
      const response = await axios.post(`${baseUrl}/web-company-save`, { 
        name, 
        address, 
        contactNumber, 
        email,
        agree,
        branchCount,
        manyBranch: selectedValue === 'multiple' ? true : false 
      });

      console.log('Response:', response.data);

      if (response.data.status === 200 && response.data.message === 'Company saved successfully') {
        this.setState({
          name: '',
          address: '',
          contactNumber: '',
          email: '',
          successMessage: 'Successfully registered. Chack Your Mail to Continue.',
          errorMessage: '',
          showPopup: true,
          checkboxError: '',
          selectedValue: 'one',
          isLoading: false
        }, () => console.log('State after success:', this.state));
      } else {
        this.setState({
          successMessage: '',
          errorMessage: response.data.message || 'Registration failed. Please try again.',
          showPopup: true
        }, () => console.log('State after failure:', this.state));
      }
    } catch (error) {
      console.error('Error:', error);
      
      this.setState({
        successMessage: '',
        errorMessage: 'Registration failed. Please try again.',
        showPopup: true
      }, () => console.log('State after error:', this.state));
    }
  }

  closePopup = () => {
    this.setState({ 
      showPopup: false,
      name: '',
      // code: '',
      address: '',
      contactNumber: '',
      email: '',
      successMessage: '',
      errorMessage: ''
    });
    //@ts-ignore
    if (this.state.successMessage) {
      window.location.href = '/';
    }
  }

  handleAgreeChange = () => {
    //@ts-ignore
    this.setState({ agree: !this.state.agree, checkboxError: '' });
  }

  openTermsPopup = () => {
    this.setState({ showTermsPopup: true });
  }

  closeTermsPopup = () => {
    this.setState({ showTermsPopup: false });
  }
  handleAgreeTerms = () => {
    this.setState({ agree: true, showTermsPopup: false });
  }


//radiobutton selected
  handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState({
      selectedValue: value,
      isPopupOpen: value === 'multiple' // Open popup if 'multiple' is selected
    });
  };

  //branches popup close
  handleClosePopup = () => {
    this.setState({ isPopupOpen: false });
  };
  
//get count
  handleAddBranchCount = (count: number) => {
    this.setState({ branchCount: count }); // Update the count value when the event is emitted
    console.log("Api Count :", count);
  };

  render() {
    //@ts-ignore
    const { name, address, contactNumber, email, successMessage, errorMessage, showPopup, showTermsPopup, agree, phoneIsValid, checkboxError,isLoading, selectedValue, isPopupOpen  } = this.state;

    return (
      <div>
       {isLoading ? (
         <div className="flex justify-center items-center h-screen">
         <img src={loadingAnimation} alt="" />
       </div>
        ) : ( 

        
    <div className="flex flex-col md:flex-row h-screen bg-[#0d6efd]">

{/* left */}
<div className="md:w-1/2 flex items-center justify-center relative bg-transparent">
  <div className="text-white text-center">
    <img src={"https://storage.googleapis.com/pet-medi-staging/web-assets/login_logo.svg"} alt="PetMedi" className="mx-auto mb-4 p-5 w-3/4 h-1/2 md:w-auto" />
  </div>
  <div className="absolute bottom-[-12px] lg:bottom-0 md:bottom-0 left-0 lg:w-[52vw] md:w-[52vw] w-full ">
    <img src={"https://storage.googleapis.com/pet-medi-staging/web-assets/login_wave_1.svg"} alt="Wave 1" className="w-full" style={{position: 'absolute', bottom: '0', left: '0', width: '100%'}} />
    <img src={"https://storage.googleapis.com/pet-medi-staging/web-assets/login_wave_2.svg"} alt="Wave 2" className="w-full" style={{position: 'absolute', bottom: '0', left: '0', width: '100%'}} />
  </div>
      </div>

{/* form/right */}
        <div className="md:w-1/2 z-10 bg-white p-8 rounded-t-[32px] md:rounded-none md:rounded-l-[32px] lg:rounded-l-[32px] shadow-lg flex items-center justify-center">
          <div className="w-full max-w-lg ">
            <h2 className="text-3xl md:text-4xl lg:text-[2.7rem] font-bold text-center text-[#0052d5]">Join Us Today!</h2>
            <p className="font-bold mb-6 py-4 text-center text-black md:text-xl lg:text-xl">Create Your Account</p>
            <form className="space-y-6 pb-6" onSubmit={this.handleSubmit}>
              <div className="form-floating mb-3">
                {/* <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label> */}
                <input 
                  type="text" 
                  id="name" 
                  placeholder='Enter Your Company Name'
                  value={name} 
                  onChange={this.handleChange} 
                  className="form-control mt-1 block w-full px-3 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" 
                  required
                  // style={{ height:'8vh' }}
                  autoFocus                  
                />
              {errorMessage.name && <span className="text-red-500 text-sm">{errorMessage.name}</span>}

              </div>
              
              <div className="form-floating mb-3">
                {/* <label htmlFor="address" className="block text-sm font-medium text-gray-700">Address</label> */}
                <input 
                  type="text" 
                  id="address" 
                  placeholder='Enter Your Company Address'
                  value={address} 
                  onChange={this.handleChange} 
                  className="form-control mt-1 block w-full px-3 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" 
                  required
                  // style={{ height:'8vh' }}
                />
            {errorMessage.address && <span className="text-red-500 text-sm">{errorMessage.address}</span>}
              </div>

              <div className="form-floating mb-3">
                {/* <label htmlFor="contactNumber" className="block text-sm font-medium text-gray-700">Contact Number</label> */}
                <PhoneInput
                  country={'lk'}
                  value={contactNumber}
                  placeholder="Contact Number"
                  onChange={this.handlePhoneChange}
                  inputProps={{
                  name: 'contactNumber',
                  required: true,
                  // autoFocus: true,
                  
            }}
            containerStyle={{
              width: '100%',
            }}
            inputClass="block w-full  py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            inputStyle={{
              // height: '8vh',
              width: '100%',
              paddingLeft: '3rem', // Adjust padding to accommodate flags
              paddingRight: '1rem',
              borderRadius: '0.375rem', // rounded-md
              fontSize: '0.875rem', // sm:text-sm
            }}
            buttonStyle={{
              // height: '8vh',
              background:'white'
            }}
            // isValid={phoneIsValid}
          />
          {errorMessage.contactNumber && <span className="text-red-500 text-sm">{errorMessage.contactNumber}</span>}
              </div>

              <div className="form-floating mb-3">
                {/* <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label> */}
                <input 
                  type="email" 
                  id="email" 
                  placeholder='Enter Your Company Email'
                  value={email} 
                  onChange={this.handleChange} 
                  className="form-control mt-1 block w-full px-3 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" 
                  required
                  // style={{ height:'8vh', }}
                />
                {errorMessage.email && <span className="text-red-500 text-sm">{errorMessage.email}</span>}

              </div>
              
          <div>
            <FormControl className='top-1'>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={selectedValue}
                onChange={this.handleRadioChange}
              >
                <FormControlLabel value="one" control={<Radio />} label="One Branch" className="text-[12px]" />
                <FormControlLabel value="multiple" control={<Radio />} label="Multiple Branches" className="text-[12px]" />
              </RadioGroup>
            </FormControl>

            {/* Popup Component */}
            <Branches open={isPopupOpen} handleClose={this.handleClosePopup} onAdd={this.handleAddBranchCount}/>
          </div>

        {/* tip */}
        <div>
          <div className="cursor-pointer relative group">
            <span className="rounded-full bg-blue-100 font-semibold px-2">!</span> Subscription Plan Details
          <span className="absolute left-0 bottom-full mt-2 w-auto p-2 bg-[#0052d5] text-white text-sm rounded-md opacity-0 group-hover:opacity-100 transition-opacity duration-200">
              Sign up now for a free plan! After logging in, you can easily choose the plan that works best for you under 'My Plan.'
          </span>
          </div>
        </div>

     

          {/* terms and conditions */}
          <div className="flex items-center mb-3">
                <input 
                  type="checkbox" 
                  id="agree" 
                  checked={agree}
                  onChange={this.handleAgreeChange}
                  className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                  // required
                />
                <label htmlFor="agree" className="ml-2 block text-sm leading-5 text-gray-900">
                  I agree to the 
                  <button 
                    type="button" 
                    className="text-blue-600 hover:text-blue-800 underline ml-1"
                    onClick={this.openTermsPopup}
                  >
                    terms and conditions
                  </button>
                </label>
              </div>
              
              
              <div className="flex items-center justify-center">
                <button 
                  type="submit" 
                  className="w-full items-center justify-center inline-flex px-7 py-[0.7rem] border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  // disabled={!agree}
                >
                  Register
                </button>

              </div>

              {checkboxError &&(
                <p className="text-red-500 text-sm mb-2 text-center">{checkboxError}</p>
              )}


            </form>
            {/* success */}
            {showPopup && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
            <div className="bg-white p-6 rounded-lg shadow-lg text-center max-w-sm mx-auto">
              <div className={`w-16 h-16 rounded-full ${successMessage ? 'border-green-100' : 'border-red-100'} flex items-center justify-center mx-auto mb-4`}>
                {successMessage ? (
                  <svg className="w-8 h-8 text-green-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                ) : (
                  <svg className="w-8 h-8 text-red-600" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                  </svg>
                )}
              </div>
              <p className={`text-2xl font-semibold ${successMessage ? '' : ''} mb-2`}>
                {successMessage ? 'Successfully registered.' : 'Registration failed.'}
              </p>
              <p className="text-sm text-gray-600">
                {successMessage ? 'Check Your Mail to Continue.' : 'Please try again.'}
              </p>
              <button 
                onClick={this.closePopup} 
                className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-md"
              >
                OK
              </button>
            </div>
          </div>
        )}

            {/* tearms */}
      {showTermsPopup && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <div className="bg-white p-6 rounded shadow-md z-10 max-w-lg mx-auto overflow-y-auto max-h-[80vh]">
            <h2 className="text-xl font-bold mb-4">Terms and Conditions</h2>

            {/* 1 */}
            <p>
            <span className='font-semibold'>1. Acceptance of Terms</span><br />
              By registering and using the PetMedi application, you agree to be bound by 
              these Terms and Conditions. PetMedi reserves the right to update or modify these 
              Terms at any time by posting the revised Terms on the Application and notifying you 
              via email. Your continued use of the Application after such revisions constitutes your 
              acceptance of the updated Terms.
            </p><br />

            {/* 2 */}
            <p>
            <span className='font-semibold'>2. User Obligations</span><br />
            <span>Account Registration: PetMedi is intended for use by authorized representatives of registered pet clinics and channeling centers. Only authorized 
            representatives with the necessary permissions can access and use the application.</span><br /><br />
            
            <span>Accurate Information: You agree to provide accurate and complete information during registration, including company name, address, email address, contactNumber number, and any additional details requested for customization.</span><br /><br />
            
            <span>Account Security: You are responsible for maintaining the confidentiality of your login credentials and for all activity that occurs under your account.</span><br /><br />
            
            <span>Admin and User Management: A designated administrator ("Admin") of the registered company can manage user accounts and system access within the company. 
            If the Admin's email address differs from the company's main email address, both email addresses must be provided during registration since All important 
            communication regarding the system will be sent to the Admin email address and the Admin serves as the primary contactNumber person and is responsible for the 
            system's use within the company.</span><br /><br />
            
            <span>Acceptable Use: You agree to use the Application in a lawful and ethical manner, and not for any purpose that violates 
            these Terms, applicable laws, or the rights of others.</span>  <br /><br />
            
            <span>Prohibited activities include: Transmit any illegal, harmful or ethnically offensive material.Interfere with or disrupt the servers or networks connected to the Application.
            Attempt to gain unauthorized access to the Application or any other accounts.Violate any applicable laws or regulations.
            Use the application in a way that harms animal welfare.</span><br />

            </p><br />

            {/* 3 */}            
            <p>
            <span className='font-semibold'>3. Privacy Policy
            </span><br />
            PetMedi respects your privacy. Please refer to our separate Privacy Policy for details 
            on how we collect, use, and protect your personal data. The Privacy Policy is 
            incorporated by reference into these Terms.
            </p><br />

            {/* 4 */}            
            <p>
            <span className='font-semibold'>4. Subscription Plans and Payment</span><br />
            <span>Free Trial: We offer a 30-day free trial of the Application to allow you to experience its features. No prior approval is needed to access the free trial. However, providing valid Visa/MasterCard details during registration is required for verification purposes.</span><br /><br />
            <span>Paid Subscriptions: We offer various subscription plans with different features and pricing. You can select a paid plan during registration or upgrade your plan at any time.</span><br /><br />
            <span>Payment Methods: We accept payments of subscription fees via Visa and MasterCard or or other approved payment method. Your card will be charged immediately upon upgrading to a paid plan. You will receive a payment confirmation email.</span><br /><br />
            <span>Subscription Activation: After the expiration of your free subscription plan, you can choose another paid subscription plan. Upgrading to a paid plan will result in immediate charges, and your billing cycle will start on the day of upgrade. You will receive a payment receipt via email.</span><br /><br />
            <span>Plan Changes: Companies have the option to choose a paid subscription plan during registration. However, the chosen paid plan will only become active after the free trial period has ended. You can change your plan after the expiration of your current plan period.</span><br /><br />
            <span>Approval Process: We may implement an approval process for paid subscriptions. After your free trial ends, PetMedi will review your company details before activating a paid plan. Based on this review, your company may be either approved or rejected. You will be notified of the decision (approval or rejection) via email. Only approved companies will be able to proceed with using the system under a paid plan.</span><br /><br />
            <span>Refunds: We process refunds according to our Fair Refund Policy, which is available upon request. Generally, refunds are not offered for unused portions of your subscription.</span>

            </p><br />

             {/* 5 */}            
             <p>
            <span className='font-semibold'>5. Customization</span><br />
            We offer customization options to tailor the Application to your company's needs. You can provide metadata during registration, 
            such as your company logo, to personalize the user interface.
            </p><br />

            {/* 6 */}            
            <p>
            <span className='font-semibold'>6. Intellectual Property</span><br />
            The Application, its content, and all intellectual property rights associated with them (including trademarks, copyrights, and patents) 
            belong to PetMedi or its licensors. You are granted a non-exclusive, non-transferable license to use the Application for your internal business purposes only, in accordance with these Terms.
            </p><br />

            {/* 7 */}            
            <p>
            <span className='font-semibold'>7. Termination</span><br />
            <span>User Termination: You may terminate your account and subscription at any time by contacting us. Your account will be closed, and you will no longer have access to the Application. However, you remain responsible for any outstanding subscription fees.
            </span><br /><br />
            <span>PetMedi Termination: PetMedi may terminate your account and access to the Application for any reason, including, but not limited to, a violation of these Terms. We will use commercially reasonable efforts to notify you by email before terminating your account, except in urgent circumstances.
            </span><br /><br />
            <span>Inactive Accounts: We will send you reminder emails before your subscription expires and again on the expiration date itself. However, If your subscription expires and you haven't chosen a new plan, we'll grant you a 15-day grace period to activate a new one. During this time, your account will be frozen and access will be restricted. Accounts that remain frozen for an extended period may be subject to auto-cancellation as per our Cancellation Policy.
            </span><br />
            </p><br />

          {/* 8 */}            
          <p>
            <span className='font-semibold'>8. Disclaimer</span><br />
            The Application is provided "as is" and without warranties of any kind, express or implied. PetMedi disclaims all warranties, including, but not limited to, 
            warranties of merchantability, fitness for a particular purpose, and non-infringement. PetMedi does not warrant that the Application will be uninterrupted 
            or error-free, that defects will be corrected, or that the Application or the server that makes it available are free of viruses or other harmful components.
            </p><br />

             {/* 9 */}            
          <p>
            <span className='font-semibold'>9. Modifications to the Terms</span><br />
            We reserve the right to update or modify these Terms at any time. We will notify you of any material changes by email or by posting a notice on the Application. 
            Your continued use of the Application after any such changes constitutes your acceptance of the new Terms.
            </p><br />

          {/* 10 */}            
          <p>
            <span className='font-semibold'>10. Internet delays</span><br />
            Our app is subject to limitations, delays, and other problems inherent in the use of the internet and electronic communications. We are not responsible for any delays, service disruptions, 
            or other damage resulting from such problems.
            </p><br />


            <button 
              onClick={this.closeTermsPopup} 
              className="mt-4 px-4 mx-1 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
            >
              Close
            </button>
            <button 
                className="px-4 mx-1 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                onClick={this.handleAgreeTerms}
            >
               Agree
            </button>
          </div>
        </div>
      )}



            <div className='mt-3 md:mt-[-26px] text-center lg:mt-5 text-[11px] md:text-[13px] lg:text-[15px] flex justify-center items-center'>
                  <small className="text-gray-900 opacity-95 mt-4 absolute">
                    Copyright &copy; 2024 HCode Solutions Pvt. Ltd. All rights reserved.
                  </small>
              </div>
          </div>
          
          
        </div>
        
      </div>
     )}
      </div>
    )
  }
}
